<template>
<div class="fixed inset-0 z-upperModal flex justify-center items-baseline bg-black bg-opacity-90" @click.prevent="zavriModal()">
    <div class="bg-gradient-to-b from-black to-gray-dark w-11/12 max-w-lg mt-24 mx-auto text-justify shadow-glowGray">
        <div class="relative p-5">
        <span class="button-close-x" @click.prevent="zavriModal()" title="Close Modal"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>

        <h3 class="text-center text-orange-our mb-4 text-2xl">Battle rules</h3>
        
        <ul class="mx-auto mt-5 mb-2 w-full box-border">
            <li class="border-dashed border-b border-orange-our p-1" v-for="(battleRule,index) in battleData.rules" :key="index" v-html="battleRule"></li>
            <li class="border-dashed border border-orange-our p-1" v-if="battleData.rules.length==0">No rules yet</li>
        </ul>
        </div>
    <img class="block h-auto max-h-60 object-center object-cover w-full" :src="`${$store.getters.URLSITE}/photos/battles/${battleData.battleImg}_m.jpg`" :alt="battleData.battleName">
    </div>
</div>
</template>

<script>
export default {
    methods:{
        zavriModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
    },
    computed:{
        battleData(){
            return this.$store.getters.getUpperModal.data;
        },
    },
}
</script>