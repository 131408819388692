<template>
  <div class="z-upperModal fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 p-3">
      <template v-if="showImage">
        <img class="w-full h-full object-contain" :class="{'opacity-0':isAdult}" :src="imgUrl" @load="onImageLoad" title="Uploaded photo" :style="imgStyle" @error="onImageError">
        <span class="button-close-x opacity-50 hover:opacity-100" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        <div v-if="zobrazCelyPanelInfo" class="absolute top-3 left-3 opacity-50 hover:opacity-100 bg-black bg-opacity-50 p-2">
            <img :src="$store.getters.URLPICTURES+'/show.svg'" class="w-10 h-10 block cursor-pointer" v-if="!this.zobrazInfo" @click="hideInfoPanel()">
            <img :src="$store.getters.URLPICTURES+'/hide.svg'" class="w-10 h-10 block cursor-pointer" v-if="this.zobrazInfo" @click="hideInfoPanel()">
            <div v-if="zobrazInfo">
                <span v-if="contestName!==false" class="">Contest: <span class="text-green-our">{{contestName}}</span></span><br>
                <span v-if="place!==false" class="text-green-our"><strong>Final position: {{place}}</strong></span><br>
                <span v-if="userTwoBestPhoto!==false" class="">SCORED PHOTO</span>
                <template v-if="exifData">
                    <hr>
                    <template v-if="exifData.Make.length>0">
                        <strong>Make: </strong>{{exifData.Make}}<br>
                    </template>
                    <template v-if="exifData.Model.length>0">
                        <strong>Model: </strong>{{exifData.Model}}<br>
                    </template>
                    <template v-if="exifData.ExposureTime.length>0">
                        <strong>Exposure Time: </strong>{{exifData.ExposureTime}}<br>
                    </template>
                    <template v-if="exifData.ApertureFNumber.length>0">
                        <strong>Aperture: </strong>{{exifData.ApertureFNumber}}<br>
                    </template>
                    <template v-if="exifData.ISOSpeedRatings.length>0">
                        <strong>ISO: </strong>{{exifData.ISOSpeedRatings}}<br>
                    </template>
                </template>
                <template v-if="userData">
                    <hr>
                    <div class="flex justify-start items-center cursor-pointer p-2" @click="linkToUser">
                        <img :src="getPhotoData.user.photoPath" class="w-10 h-10 object-cover rounded-full">
                        <div class="flex-grow">
                            <div class="overflow-ellipsis overflow-hidden whitespace-nowrap font-bold" :title="getPhotoData.user.nick">{{getPhotoData.user.nick}}</div>
                            <img v-if="getPhotoData.user.countryName!=='' && getPhotoData.user.countryName!==null" :src="flagLink(getPhotoData.user.countryCode)" :alt="getPhotoData.user.countryName" class="block h-5 w-auto">
                        </div>
                    </div>
                </template>
                <template v-if="getAchievements.length>0">
                    <hr>
                    <div class="flex justify-start py-2">
                        <template v-for="achiev in getAchievements" :key="achiev.id">
                            <img :src="achiev.icon" class="h-16 w-16 block" :alt="achiev.name" />
                        </template>
                    </div>
                </template>
            </div>
        </div>
        <div class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2 transform" v-if="isAdult">
            <span class="bg-gray-600 block cursor-pointer mb-1 noWrap px-3 py-1 text-center text-sm" title="Show this adult photo" @click="showThisPhoto()">SHOW THIS ADULT PHOTO</span>
            <span class="bg-orange-our block cursor-pointer mb-1 mt-4 noWrap px-3 py-1 text-center text-sm" title="Show all adults photos" @click="showAllPhotos()">SHOW ALL ADULT PHOTOS</span>
        </div>

        <div v-if="deleteAllowed && !confirmDeletePanel" class="absolute bottom-0 left-1/2 transform -translate-x-1/2 p-2 bg-red-our opacity-50 hover:opacity-100 cursor-pointer rounded-tr-sm rounded-tl-sm" @click="confirmDeletePanelMethod()">DELETE PHOTO</div>
        <div v-if="deleteAllowed && confirmDeletePanel" class="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex justify-start">
            <div class="p-2 bg-red-our opacity-50 hover:opacity-100 cursor-pointer rounded-tr-sm rounded-tl-sm mx-1" @click="confirmDelete()">YES</div>
            <div class="p-2 bg-green-our opacity-50 hover:opacity-100 cursor-pointer rounded-tr-sm rounded-tl-sm mx-1" @click="cancelDelete()">NO</div>
        </div>
    </template>
    <template v-else>
        <span class="button-close-x opacity-50 hover:opacity-100" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        <img :src="`${$store.getters.URLPICTURES}/noImage.svg`" class="block w-48 h-48 cursor-pointer" @click="closeModal()">
    </template>
  </div>
</template>

<script>
import functions from '../functions.vue'
import Axios from 'axios'
export default {
    mixins:[functions],
    beforeMount(){
        this.$store.commit('setModalLoading',true);
    },
    data(){
        return{
            zobrazInfo:true,
            confirmDeletePanel:false,
            showImage:true,
        }
    },
    computed:{
        deleteAllowed(){
            if('deletePhoto' in this.getPhotoData){
                if('deletePhoto' in this.getPhotoData.deletePhoto && 'battleId' in this.getPhotoData.deletePhoto){
                    if(this.getPhotoData.deletePhoto.deletePhoto){
                        return true;
                    }
                }
            }
            return false;
        },
        zobrazCelyPanelInfo(){
            console.log(this.place,this.exifData,this.getAchievements.length);
            if(!this.place && !this.exifData && this.getAchievements.length==0){
                return false;
            }
            return true;
        },
        getSeeAdultPhotos(){
            return this.$store.getters.getSeeAdultPhotos;
        },
        getPhotoData(){
            console.log('getPhotoData',this.$store.getters.getUpperModal.data);
            return this.$store.getters.getUpperModal.data;
        },
        imgUrl(){
            var velkostFotky=this.vypisVelkostFotkyPreRozlisenie(this.getPhotoData.size);
            return this.getPhotoData.photoPath+'_'+velkostFotky.size+'.jpg';
        },
        imgStyle(){
            var velkostFotky=this.vypisVelkostFotkyPreRozlisenie(this.getPhotoData.size);
            return {"max-width":velkostFotky.w+"px","max-height":velkostFotky.h+"px"}
        },
        exifData(){
            if(this.getPhotoData.exif!==null && this.getPhotoData.exif!==false){
                var hodnotaExif=0;
                var vystup={};
                if('Make' in this.getPhotoData.exif && this.getPhotoData.exif.Make!==""){
                    vystup.Make=this.getPhotoData.exif.Make;
                    hodnotaExif++;
                }else{
                    vystup.Make="";
                }
                if('Model' in this.getPhotoData.exif && this.getPhotoData.exif.Model!==""){
                    vystup.Model=this.getPhotoData.exif.Model;
                    hodnotaExif++;
                }else{
                    vystup.Model="";
                }
                if('ExposureTime' in this.getPhotoData.exif && this.getPhotoData.exif.ExposureTime!==""){
                    vystup.ExposureTime=this.getPhotoData.exif.ExposureTime;
                    hodnotaExif++;
                }else{
                    vystup.ExposureTime="";
                }
                if('ApertureFNumber' in this.getPhotoData.exif && this.getPhotoData.exif.ApertureFNumber!==""){
                    vystup.ApertureFNumber=this.getPhotoData.exif.ApertureFNumber;
                    hodnotaExif++;
                }else{
                    vystup.ApertureFNumber="";
                }
                if('ISOSpeedRatings' in this.getPhotoData.exif && this.getPhotoData.exif.ISOSpeedRatings!==""){
                    vystup.ISOSpeedRatings=this.getPhotoData.exif.ISOSpeedRatings;
                    hodnotaExif++;
                }else{
                    vystup.ISOSpeedRatings="";
                }
                if(hodnotaExif>0){
                    return vystup;
                }
            }
            return false;
        },
        isAdult(){
            if(!this.getSeeAdultPhotos && parseInt(this.getPhotoData.isAdult)==1){
                return true
            }
            return false
        },
        userData(){
            if('user' in this.getPhotoData){
                if(this.getPhotoData.user!==undefined){
                    return true
                }
            }
            return false;
        },
        place(){
            if(!('place' in this.getPhotoData)){
                return false;
            }else if(this.getPhotoData.place<3){
                return this.getPhotoData.place+1;
            }else if(this.getPhotoData.place<10){
                return "TOP 10";
            }else if(this.getPhotoData.place<=110){
                return "TOP 10%";
            }else if(this.getPhotoData.place<=120){
                return "TOP 20%";
            }else if(this.getPhotoData.place<=130){
                return "TOP 30%";
            }else if(this.getPhotoData.place1<=140){
                return "TOP 40%";
            }else if(this.getPhotoData.place<=150){
                return "TOP 50%";
            }else{
                return "50%+";
            }
        },
        contestName(){
            if('battleName' in this.getPhotoData){
                return this.getPhotoData.battleName;
            }
            return false;
        },
        userTwoBestPhoto(){
            if('userTwoBestPhoto' in this.getPhotoData){
                return this.getPhotoData.userTwoBestPhoto;
            }
            return false;
        },
        getAchievements(){
            var vystup=[];
            if('achievements' in this.getPhotoData){
                this.getPhotoData.achievements.forEach((el)=>{
                    vystup.push(this.$store.getters.getAchievement(el));
                });
            }
            return vystup;
        }
    },
    methods:{
        flagLink(flag){
            return this.$store.getters.URLSITE+'/photos/flags/'+flag+'_s.jpg'
        },
        linkToUser(){
            if('userUrl' in this.getPhotoData.user){
                this.$router.push({path:'/user-profile/'+this.getPhotoData.user.userUrl});
                this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
            }
        },
        showThisPhoto(){
            if('battleSeo' in this.getPhotoData){
                this.getPhotoData.isAdult=0;
                this.$store.commit('setTop100PhotosAdult',{idPhoto:this.getPhotoData.idPhoto,battleSeo:this.getPhotoData.battleSeo});
            }else if('userUrl' in this.getPhotoData){
                this.getPhotoData.isAdult=0;
                this.$store.commit('setBestUserPhotosAdult',{idPhoto:this.getPhotoData.idPhoto,userUrl:this.getPhotoData.userUrl});
            }
        },
        showAllPhotos(){
            this.$store.commit('setUserData',{'seeAdultPhotos':true});
        },
        onImageError(){
            this.showImage=false;
            this.$store.commit('setModalLoading',false);
        },
        confirmDeletePanelMethod(){
            this.confirmDeletePanel=true;
        },
        cancelDelete(){
            this.confirmDeletePanel=false;
        },
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
        onImageLoad () {
            this.$store.commit('setModalLoading',false);
        },
        hideInfoPanel(){
            this.zobrazInfo=!this.zobrazInfo;
        },
        confirmDelete(){
            const battleData=this.$store.getters.getBattle(this.getPhotoData.deletePhoto.battleId);
            var udaje={'battle':battleData.battleSettings.nameSeo,'file':this.getPhotoData.photoFileName};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=delete-photo", udaje)
            .then((response)=>{
                var res=response.data;
                if('code' in res && res.code==1){//-----------odlogovany uzivatel
                    this.$store.commit('deleteLoadedData');
                    this.$router.push({path:'/active-contests'});
                }else{

                    if(res.return===true){
                        var data={'battleId':this.getPhotoData.deletePhoto.battleId,'photoFileName':this.getPhotoData.photoFileName}
                        this.$store.commit('deleteBattleListphotosUploaded',data);
                        this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    }else{
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }
                }
            })
            .catch((e)=> {
                this.$store.commit('setErrorModal',{visible:true,data:"#44 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        }
    },
}
</script>
