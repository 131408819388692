<template>
    <template v-if="mozeSaMazat">
        <figure class="relative w-12 h-12 bg-black m-1 block cursor-pointer border-b-2 border-solid box-content" :class="uploadedPhotoBorder" @click="modalZoomInImage()">
            <img :src="uploadedPhotoUrl" class="block w-12 h-12 object-contain" :alt="uploadedPhotoTitle">
        </figure>
    </template>
    <template v-else>
        <figure class="relative w-12 h-12 bg-black m-1 block cursor-pointer border-b-2 border-solid box-content" :class="uploadedPhotoBorder">
            <img :src="uploadedPhotoUrl"  class="block w-12 h-12 object-contain" :alt="uploadedPhotoTitle">
        </figure>
    </template>
</template>
<script>
// import Axios from 'axios'
export default {
    props:['photoFileName','battleId'],
    beforeMount(){
    },
    data(){
        return {
            definitivneZmazanieFotky:false
        }
    },
    computed:{
        getBattle(){
            return this.$store.getters.getBattle(this.battleId);
        },
        getUploadedPhoto(){
            return this.getBattle.photosUploaded.find((el)=>el.photoFileName==this.photoFileName);
        },
        mozeSaMazat(){
            let vyzden=this.$store.getters.getTypBattle(this.battleId);
            if(vyzden=="greenBox"){ 
                return true;
            }else{
                return false;
            }
        },
        uploadedPhotoUrl(){
            return this.getUploadedPhoto.photoPath+"_t.jpg";
        },
        uploadedPhotoTitle(){
            if(this.getUploadedPhoto.freePhoto==0){
                return "Uploaded paid photo";
            }else{
                return "Uploaded free photo";
            }
        },
        uploadedPhotoBorder(){
            if(this.getUploadedPhoto.freePhoto==0){
                return {'border-orange-our':true}
            }
            return {'border-green-our':true}
        }
    },
    methods:{
        modalZoomInImage(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{photoPath:this.getUploadedPhoto.photoPath,
            photoFileName:this.getUploadedPhoto.photoFileName,
            exif:this.getUploadedPhoto.exif,
            size:this.getUploadedPhoto.size,
            isAdult:this.getUploadedPhoto.isAdult,
            idPhoto:this.getUploadedPhoto.idPhoto,
            deletePhoto:{'battleId':this.battleId,'deletePhoto':this.mozeSaMazat}
            }}); 
        },
    }
}
</script>