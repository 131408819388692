<template>
<h1 class="bg-gray-700 bg-opacity-50 border-b border-gray-500 py-2 text-2xl text-center text-white">Terms and Conditions</h1>
    <div class="container mx-auto" ref="cont">
        <div v-html="getTerms" class="mt-10 mb-5"></div>
    </div>
</template>

<script>
import Axios from 'axios'
export default {
    created(){
        //-------pozadie stranky-------
        document.getElementsByTagName('body')[0].classList.add('bgImageBlue');

        if(this.getTerms===''){
            if('firstLoad' in window){
                if('terms' in window.firstLoad){
                    if(window.firstLoad.terms!==''){
                        this.loadTermsWindow();
                        window.firstLoad={};
                    }else{
                        this.loadTerms();
                    }
                }else{
                   this.loadTerms(); 
                }
            }else{
                this.loadTerms();
            }
        }
    },
    computed:{
        getTerms(){
            return this.$store.getters.getTerms;
        },
    },
    methods:{
        loadTerms(){
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=terms-and-condition", {})
            .then((response)=>{
                var res=response.data;
                if(res.return===true){
                    this.$store.commit('setTerms',{'terms':res.terms});
                }
            })
            .catch((e)=> {
                this.$store.commit('setErrorModal',{visible:true,data:"#122 Error during loading Terms and Condition"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        },
        loadTermsWindow(){
            var res=window.firstLoad;
            if(res.return===true){
                this.$store.commit('setTerms',{'terms':res.terms});
            }
        }
    }

}
</script>