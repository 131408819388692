<template>
<div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
    <div class="w-11/12 max-w-sm relative p-5 border-solid border-b-4 border-orange-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        <h3 class="mb-5 text-center text-orange-our text-2xl">Reset my votes in this competition</h3>
        <div class="text-left my-4">
            <div class="mb-4">Resetnut vsetky fotografie za <span class="text-orange-our font-bold">{{getBattle.generalSettings.resetVotesPrice}} creditov</span>.</div>
        </div>
        <div v-if="showSuccessReset" class="mb-4 text-center text-green-our text-xl">
            Reset bol uspesny
        </div>
        <div v-else>
            <button v-if="showUnlock" class="bg-orange-our text-lg py-2 px-5 block mx-auto" @click="unlock()">RESET THIS PHOTO</button>
            <button v-else class="bg-orange-our text-lg py-2 px-5 block mx-auto" @click="resetVotes()">YES USE {{getBattle.generalSettings.resetVotesPrice}} CREDITS NOW</button>
        </div>
    </div>

</div>
</template>

<script>
import functions from '../functions.vue'
import Axios from 'axios';
export default {
    mixins:[functions],
    // beforeMount(){
    //     this.$store.getters.upperModal.data.battleId;
    // },
    data(){
        return {
            showUnlock:true,
            showSuccessReset:false
        }
    },
    computed:{
        getBattleId(){
            return this.$store.getters.getUpperModal.data.battleId;
        },
        getBattle(){
            return this.$store.getters.getBattle(this.getBattleId);
        },
    },
    methods:{
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,WhatToShow:'',data:{}});
        },
        unlock(){
            this.showUnlock=false
        },
        resetVotes(){
            var getUserData=this.$store.getters.getUserData;
            if(this.getBattle.generalSettings.resetVotesPrice>getUserData.actualCredits){
                this.$store.commit('setErrorModal',{visible:true,data:"You have "+getUserData.actualCredits+" credits left"});
                return;
            }


            var udaje={'battle':this.getBattle.battleSettings.nameSeo};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=reset-votes-purchase", udaje)
            .then((response)=>{
                var res=response.data;
                if('code' in res && res.code==1){//-----------odlogovany uzivatel
                    this.$store.commit('deleteLoadedData');
                    this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                    this.$router.push({path:'/active-contests'});
                }else{
                    if(res.return===false){
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }else if(res.return===true){
                        //----------- upravime hodnotu voteLeft
                        this.$store.commit('setResetVotes',{'battleId':this.getBattleId,'voteLeft':res.voteLeft});
                        this.showSuccessReset=true;
                    }
                }
                if('actualCredits' in res){
                    this.$store.commit('setUserData',{'actualCredits':parseInt(res.actualCredits)});
                }
            })
            .catch((e)=> {
                this.$store.commit('setErrorModal',{visible:true,data:"#28 Network error"+e+""});
            })
            .finally(()=>{

                this.$store.commit('setModalLoading',false);
            });
        }
    },
}
</script>