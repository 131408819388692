<template>
  <div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
      <div class="w-11/12 max-w-sm relative p-5 border-solid border-b-4 border-orange-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
          <h3 class="mb-5 text-center text-white-our text-2xl">Daily games</h3>
          <p class="mb-5">Hľa daily games - bla bla bla co to vlastne je</p>
          <button class="bg-gray-dark text-center text-lg text-white py-2 w-full my-3" @click.prevent="closeModal()">close</button>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
    }
}
</script>

<style>

</style>