<template>
<div class="fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
  <div class="w-11/12 max-w-xs relative p-5 border-solid border-b-4 border-blue-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
    <div class="font-bold text-blue-our text-lg">SIGN UP CONFIRM<span class="button-close-x" title="Close Modal" @click="close()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span></div>
    <form>
      <div v-if="errorText.length>0" class="my-2 text-red-our text-center">{{errorText}}</div>
      <div v-if="signUpText.length>0" class="my-2 text-green-our text-center" v-html="signUpText"></div>
        <template v-if="showButtons">
          <div class="py-2" v-if="!confirmed">
            <button type="submit" class="bg-blue-our text-white text-center py-3 px-5 w-full mt-4" @click.prevent="ConfirmSignUp()">CONFIRM SIGN UP</button>
          </div>
          <div class="pt-4 flex justify-center items-center" v-else>
            <button type="button" class="bg-green-our text-sm py-3 px-10 cursor-pointer" @click="prepniNa('LoginPanel')">LOG IN</button>
          </div>
        </template>
    </form>
  </div>
</div>
</template>

<script>
import Axios from 'axios'
import functions from '../../functions'

export default {
  mixins:[functions],
  data(){
    return{
      errorText:"",
      signUpText:"",
      confirmed:false,
    }
  },
  mounted(){
    if(!this.showButtons){
      this.errorText="Confirm code not match, please check link from email.";
    }
  },
  computed:{
    getResetCode(){
        return this.$store.getters.getUpperModal.data.code;
    },
    showButtons(){
      if(this.getResetCode.length>10){
        return true;
      }
      return false;
    },

  },
  methods:{
    close(){
      this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
    },
    prepniNa(naCoMaPrepnut){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:naCoMaPrepnut,data:{}});
    },
    ConfirmSignUp(){
      this.signUpText="";
      this.errorText="";

      if(this.getResetCode.length<10){
        this.errorText="Confirm code not match, please check link from email."
        return;
      }
      this.$store.commit('setModalLoading',true);
      Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=confirm-sign-up',{'code':this.getResetCode})
      .then((response) => {
          if(response.data.return){
            this.confirmed=true;
            this.signUpText=response.data.returnMsg;
          }else{
            this.errorText=response.data.returnMsg;
          }
        })
      .catch((e) => {
        this.$store.commit('setErrorModal',{visible:true,data:"#138 Network error"+e+""});
      })
      .finally(()=>{
        this.$store.commit('setModalLoading',false);
      });
    }
  }
}
</script>

