<template>
    <div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
        <div class="w-11/12 max-w-md relative p-5 border-solid border-b-4 border-red-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
            <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>

            <div v-if="getPhotoData.reported==1">
                <h3 class="text-center mb-5 text-red-our text-2xl">Reported photo</h3>
                <div class="text-center text-green-our text-xl">THANK YOU FOR HELPING US :)</div>
            </div>
            <div v-else>
                <h3 class="text-center mb-5 text-red-our text-2xl">Report photo</h3>
                <div class="menuReportList">
                    <label class="menuReportOption"><input type="radio" v-model="radioButton" value="0"><span>NO REPORT</span></label>
                    <label class="menuReportOption"><input type="radio" v-model="radioButton" value="1"><span>Adult</span></label>
                    <label class="menuReportOption"><input type="radio" v-model="radioButton" value="2"><span>Offensive</span></label>
                    <label class="menuReportOption"><input type="radio" v-model="radioButton" value="3"><span>Out of topic</span></label>
                    <label class="menuReportOption"><input type="radio" v-model="radioButton" value="4"><span>Copyright claim</span></label>
                    <button class="w-full  my-2 text-xl text-white py-2 text-center" :class="buttonSubmitColor" @click.prevent="reportPhoto()">SEND REPORT</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import functions from '../functions.vue'
export default {
    //onclick="reportPhoto(0)"
    mixins:[functions],
    data(){
        return{
            radioButton:0
        }
    },
    computed:{
        getPhotoData(){
            return this.$store.getters.getUpperModal.data;
        },
        getBattle(){
            return this.$store.getters.getBattle(this.getPhotoData.battleId);
        },
        buttonSubmitColor(){
            return this.radioButton>0?{'bg-red-our':true,'cursor-pointer':true}:{'bg-gray-600':true,"cursor-not-allowed":true};
        }
    },
    methods:{
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
        reportPhoto(){

            // posiela sa report len ak je zvolena niektore hodnota
            if(this.radioButton!==0){
                var udaje={'battle':this.getBattle.battleSettings.nameSeo,'photo':this.getPhotoData.photoId,'photoChecksum':this.getPhotoData.idChecksum,'reportValue':this.radioButton};
                this.$store.commit('setModalLoading',true);
                Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=report-photo", udaje)
                .then((response)=>{
                    var res=response.data;

                    if('code' in res && res.code==1){//-----------odlogovany uzivatel
                        this.$store.commit('deleteLoadedData');
                        this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                        this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                        this.$router.push({path:'/active-contests'});
                    }else{
                        if(res.return===false){
                            this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                        }else if(res.return===true){
                            this.$store.commit('setReportToPhotoForSelect',{'battleId':this.getBattle.battleSettings.id,'photoId':this.getPhotoData.photoId});
                            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ReportPhoto',data:{'battleId':this.getBattle.battleSettings.id,'photoId':this.getPhotoData.photoId,'reported':1}});
                        }
                    }
                })
                .catch((e)=>{
                    this.$store.commit('setErrorModal',{visible:true,data:"#13 Network error"+e+""});
                })
                .finally(()=>{
                    this.$store.commit('setModalLoading',false);
                });
            }
        },
    },
}
</script>