<template>
    <div class="rounded-sm  py-1 pr-1 max-w-2xl my-2 mx-auto shadow-xl bg-gradient-to-tr from-gray-dark to-gray-dark leaderBoard" :class="background">
        <div class="flex justify-start items-center">
            <div class="text-xl text-center w-14 self-center">#{{ScoreBoard.position}}<img v-if="ScoreBoard.countryCode!==''" :src="flagLink(ScoreBoard.countryCode)" :alt="ScoreBoard.countryName" class="block h-5 w-auto mx-auto"></div>
            <div class="w-14 cursor-pointer self-center" @click="linkToUser(ScoreBoard.userUrl)"><img :src="ScoreBoard.photoPath" class="w-14 h-14 object-cover rounded-full"></div>
            <div class="px-2 font-bold nameAchieve cursor-pointer" @click="linkToUser(ScoreBoard.userUrl)">
                <div class="name overflow-ellipsis overflow-hidden whitespace-nowrap" :title="ScoreBoard.nick">{{ScoreBoard.nick}}</div>
            </div>
            <template v-if="category=='total'">
                <div class="flex flex-col graph1 justify-center text-xs w-12 mr-1">
                    <div class="border border-solid border-orange-our pl-1 text-orange-our">TOTAL</div>
                    <div class="border border-green-our border-green-our border-solid pl-1 my-0.5 text-green-our">PHOTO</div>
                    <div class="border border-blue-our border-blue-our border-solid pl-1" style="color: #4a8ff5;">VOTE</div>
                </div>
                <div class="flex flex-col flex-grow graph2 justify-between text-sm">
                    <div class="border-black border-solid text-right leading-none total" ref="total">{{ScoreBoard.scoreTotal}}</div>
                    <div class="border-black border-solid text-right leading-none photo my-1.5" ref="photo">{{ScoreBoard.scorePhoto}}</div>
                    <div class="border-black border-solid text-right leading-none vote" ref="vote">{{ScoreBoard.scoreVote}}</div>
                </div>
                <div class="score f24 text-center ml-auto w-16 font-bold text-orange-our">{{ScoreBoard.scoreTotal}}<span class="block text-xs font-normal">POINTS</span></div>
            </template>
            <template v-else-if="category=='photo'">
                <div class="flex flex-col graph1 justify-center text-xs w-12 mr-1">
                    <div class="border border-green-our border-green-our border-solid pl-1 text-green-our">PHOTO</div>
                    <div class="border border-blue-our border-blue-our border-solid pl-1 my-0.5" style="color: #4a8ff5;">VOTE</div>
                    <div class="border border-solid border-orange-our pl-1 text-orange-our">TOTAL</div>
                </div>
                <div class="flex flex-col flex-grow graph2 justify-between text-sm">
                    <div class="border-black border-solid text-right leading-none photo" ref="photo">{{ScoreBoard.scorePhoto}}</div>
                    <div class="border-black border-solid text-right leading-none vote my-1.5" ref="vote">{{ScoreBoard.scoreVote}}</div>
                    <div class="border-black border-solid text-right leading-none total" ref="total">{{ScoreBoard.scoreTotal}}</div>
                </div>
                <div class="score f24 text-center ml-auto w-16 font-bold text-green-our">{{ScoreBoard.scoreTotal}}<span class="block text-xs font-normal">POINTS</span></div>
            </template>
            <template v-else>
                <div class="flex flex-col graph1 justify-center text-xs w-12 mr-1">
                    <div class="border border-blue-our border-blue-our border-solid pl-1" style="color: #4a8ff5;">VOTE</div>
                    <div class="border border-green-our border-green-our border-solid pl-1 my-0.5 text-green-our">PHOTO</div>
                    <div class="border border-solid border-orange-our pl-1 text-orange-our">TOTAL</div>
                </div>
                <div class="flex flex-col flex-grow graph2 justify-between text-sm">
                    <div class="border-black border-solid text-right leading-none vote" ref="vote">{{ScoreBoard.scoreVote}}</div>
                    <div class="border-black border-solid text-right leading-none photo my-1.5" ref="photo">{{ScoreBoard.scorePhoto}}</div>
                    <div class="border-black border-solid text-right leading-none total" ref="total">{{ScoreBoard.scoreTotal}}</div>
                </div>
                <div class="score f24 text-center ml-auto w-16 font-bold text-blue-our">{{ScoreBoard.scoreTotal}}<span class="block text-xs font-normal">POINTS</span></div>
            </template>
        </div>
        <div v-if="orderedAchievements()" class="my-1 flex justify-start flex-wrap">
            <template v-for="(achiev,index) in orderedAchievements()" :key="index+1">
            <img :src="`${achiev.icon}`" @click="zobrazModal(achiev)" class="w-10 h-10 cursor-pointer" :alt="`${achiev.description}`">
            </template>
        </div>
    </div>
</template>
<script>
import gsap from 'gsap'
export default {
    mounted(){
        gsap.fromTo(this.$refs.total, 1, {width:"0%"}, {width:this.getWidthNumber(this.maxScore,this.ScoreBoard.scoreTotal)})
        gsap.fromTo(this.$refs.photo, 1, {width:"0%"}, {width:this.getWidthNumber(this.maxScore,this.ScoreBoard.scorePhoto)})
        gsap.fromTo(this.$refs.vote, 1, {width:"0%"}, {width:this.getWidthNumber(this.maxScore,this.ScoreBoard.scoreVote)})
        this.orderAchievements;
    },
    props:['ScoreBoard','maxScore','category','background'],
    methods:{
        orderedAchievements(){
            const achievements=[];
            if(this.ScoreBoard.achievements.length>0){
                this.ScoreBoard.achievements.forEach((el)=>{
                    achievements.push(this.getAchievement(el));
                });
                // return achievements;
                return achievements.sort((a, b) => (a.order > b.order) ? 1 : -1);
            }
            return false;
        },
        getAchievement(id){
            return this.$store.getters.getAchievement(id);
        },
        zobrazModal(achievObj){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInAchieve',data:{achievObj}}); 
        },
        flagLink(flag){
            return this.$store.getters.URLSITE+'/photos/flags/'+flag+'_s.jpg'
        },
        linkToUser(userUrl){
                this.$router.push({path:'/user-profile/'+userUrl});
                this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
        },
        getWidthNumber(max,actual){
            return ((parseInt(actual)/parseInt(max))*100)+'%';
        },
    }
}
</script>