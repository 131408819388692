<template>
    <div class="w-full">
    <div class="mt-2">
        <img :src="`${getURLsite}/pictures/captainFocusLogo.svg`" alt="Captain Focus" class="2xl:w-6/12 block cursor-pointer mx-auto w-10/12 xl:w-8/12">
        <div class="md:text-3xl px-10 text-center text-xl mt-2">Worldwide photography competition</div>
    </div>
    <div class="bg-center bg-cover bg-no-repeat h-64 md:h-screen relative w-full" :style="getBgImage('captainFocusHomePage1.jpg?v=2')">
        <h3 class="absolute bottom-2 inset-x-0 text-2xl md:text-3xl text-center text-white">UPCOMING <strong>photo</strong> CONTESTS2</h3>
    </div>
    <!-- blog je hidnuty -->
    <!-- <aside v-if="getBlog.length>0" class="hidden auto-rows-fr container gap-10 grid grid-cols-1 lg:grid-cols-3 max-w-6xl mt-16 mx-auto sm:grid-cols-2">
        <div v-for="(blog,index) in getBlog" :key="index" class="p-4" :class="blogClass[index+1]">
            <img :src="blog.foto" class="block w-full object-cover">
            <h3 class="text-xl text-center mt-4 mb-2">{{blog.title}}</h3>
            <p class="text-justify">{{blog.content}}</p>
            <div class="text-center mt-4"><a :href="blog.url" target="_blank" class="inline-block border-2px-4 py-2 mt-2 rounded-sm text-xl">Read More</a></div>
        </div>
    </aside> -->

    <section class="w-full pb-10 bg-no-repeat bg-top bg-cover" :style="getBgImage('bg_stars_flip.jpg')" style="background-size:100% 100%">
        <div class="w-full" v-if="getScrollTextA!==''">
            <div class="mx-auto pb-20 w-full max-w-md overflow-hidden h-12 relative">
                <div class="absolute h-full text-xl md:text-3xl text-center text-white textSlider w-full whitespace-nowrap" v-html="getScrollText"></div>
            </div>
        </div>
        <div class="container flex justify-around mt-5 mx-auto space-x-1">
            <div class="bg-blue-our bg-opacity-30 bg-white p-2 rounded-md w-1/3">
                <img :src="`${getURLsite}/pictures/join.png`" alt="JOIN TODAY" class="mx-auto w-full">
                <h3 class="font-bold text-xs text-center">JOIN TODAY</h3>
                <div class="text-center hidden">and test your photographic skills in various challenges every week.</div>
            </div>
            <div class="bg-red-our bg-opacity-30 bg-white p-2 rounded-md w-1/3">
                <img :src="`${getURLsite}/pictures/earn.png`" alt="POINTS, CREDITS, REWARDS & PRIZES" class="mx-auto w-full">
                <h3 class="font-bold text-xs text-center">POINTS, CREDITS, REWARDS & PRIZES</h3>
                <div class="text-center hidden">not only for being a good photographer <br>but also a good judge.</div>
            </div>
            <div class="bg-green-our bg-opacity-30 bg-white p-2 rounded-md w-1/3">
                <img :src="`${getURLsite}/pictures/board.png`" alt="ALL TIME CHAMPIONS SCOREBOARD" class="mx-auto w-full">
                <h3 class="font-bold text-xs text-center">ALL TIME CHAMPIONS SCOREBOARD</h3>
                <div class="text-center hidden">the ultimate place to put your name on. <br>Do you have what it takes?</div>
            </div>
        </div>
    </section>

    <div class="relative" id="video">
        <video width="100%" height="auto" loop autoplay muted="muted" volume="0" class="w-full h-auto" style="z-index:-1000">
            <source :src="`${getURLsite}/pictures/captain_video.mp4`" type="video/mp4">
            <source :src="`${getURLsite}/pictures/captain_video.webm`" type="video/webm">
        </video>
        <div id="videoText" class="absolute bottom-12 font-bold inset-x-0 lg:bottom-36 lg:text-8xl md:bottom-24 md:text-6xl sm:bottom-16 sm:text-5xl text-4xl text-black text-center text-sha xl:bottom-48 xl:text-9xl textShadowFrontPage textTransformFrontPage duration-1000">It's FUN and&nbsp;&nbsp;it's FREE!</div>
    </div>

    <section class="w-full pt-5 bg-no-repeat bg-center bg-cover" :style="getBgImage('bg_stars.jpg')" style="background-size:100% 100%">
        <template v-if="getTimeToEndClosestActiveBattle>0">
            <h2 class="font-bold text-2xl text-center">CURENT CONTESTS END IN</h2>
            <div class="flex-none justify-center max-w-4xl md:flex mx-auto sm:justify-around">
                <div class="flex justify-center mb-10 sm:mb-0 w-100">
                    <div class="afterText flex items-center justify-start relative" data-text="Day(s)">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[0]}.png`" class="h-auto w-6 sm:w-10">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[1]}.png`" class="h-auto w-6 sm:w-10">
                    </div>
                    <img :src="`${getURLsite}/pictures/numbers/colon.png`" class="h-auto w-6 sm:w-10">
                    <div class="afterText flex items-center justify-start relative" data-text="Hour(s)">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[3]}.png`" class="h-auto w-6 sm:w-10">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[4]}.png`" class="h-auto w-6 sm:w-10">
                    </div>
                    <img :src="`${getURLsite}/pictures/numbers/colon.png`" class="h-auto w-6 sm:w-10">
                    <div class="afterText flex items-center justify-start relative" data-text="Minute(s)">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[6]}.png`" class="h-auto w-6 sm:w-10">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[7]}.png`" class="h-auto w-6 sm:w-10">
                    </div>
                    <img :src="`${getURLsite}/pictures/numbers/colon.png`" class="h-auto w-6 sm:w-10">
                    <div class="afterText flex items-center justify-start relative" data-text="Second(s)">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[9]}.png`" class="h-auto w-6 sm:w-10">
                        <img :src="`${getURLsite}/pictures/numbers/${getFormatedTime[10]}.png`" class="h-auto w-6 sm:w-10">
                    </div>
                </div>
                <router-link to="/active-contests"><img :src="`${getURLsite}/pictures/animated_enter1234.gif`" alt="Enter active contests" class="block cursor-pointer h-24 md:mt-0 mt-10 mx-auto self-center"></router-link>
            </div>
        </template>
    
    <aside class="container my-10 mx-auto">
        <h2 class="font-bold text-3xl text-center">GAME TIMELINE</h2>
        <p class="text-center">EACH CONTEST LASTS 2 WEEKS</p>
        <div class="mx-2 hidden sm:block"><img :src="`${getURLsite}/pictures/timeline.svg`" alt="CAPTAIN FOCUS GAME TIMELINE" class="mx-auto w-full block max-w-5xl mx-2"></div>
        <div class="mx-2 block sm:hidden"><img :src="`${getURLsite}/pictures/timelineClock.svg`" alt="CAPTAIN FOCUS GAME TIMELINE" class="mx-auto w-11/12 block mx-2"></div>
    </aside>

    <aside class="container my-10 md:my-20 mx-auto px-2">
        <h2 class="font-bold text-3xl text-center mb-2">HOW TO PLAY</h2>
            <ul class="flex justify-start w-full flex-wrap sm:flex-nowrap space-y-0.5">
                <li class="px-4 py-2 w-full sm:w-auto from-gray-500 bg-gradient-to-r hover:bg-gray-500" :class="howToPlayTabGetClass(1)" @click="howToPlayTabChange(1)">START HERE</li>
                <li class="bg-gradient-to-l bg-opacity-60 cursor-pointer from-green-our px-4 py-2 sm:w-auto w-full hover:bg-green-our" :class="howToPlayTabGetClass(2)" @click="howToPlayTabChange(2)">UPLOAD</li>
                <li class="px-4 py-2 w-full sm:w-auto bg-gradient-to-r from-green-select hover:bg-green-select" :class="howToPlayTabGetClass(3)" @click="howToPlayTabChange(3)">SELECT</li>
                <li class="px-4 py-2 w-full sm:w-auto bg-gradient-to-l from-blue-our hover:bg-blue-our" :class="howToPlayTabGetClass(4)" @click="howToPlayTabChange(4)">VOTE/BET</li>
                <li class="px-4 py-2 w-full sm:w-auto bg-gradient-to-r from-red-results hover:bg-red-our" :class="howToPlayTabGetClass(5)" @click="howToPlayTabChange(5)">RESULTS</li>
                <li class="flex-grow mobileHidden"></li>
            </ul>
            <div class="p-5 mt-2" :class="howToPlayTabBorder(howToPlayTabActive)">
                <ul class="list-disc ml-5" :class="howToPlayTabHidden(1)">
                    <li class="py-1">there are several new photography contests / battles every week</li>                    
                    <li class="py-1">choose the ones that you'd like to participate in and prepare your best shots for each battle</li>
                    <li class="py-1">take a part in as many battles as you want, considering you have suitable pictures matching the topics of the battles</li>
                    <li class="py-1">your photos must meet the criteria of the battle, otherwise you won't get votes and might be reported by other participants</li>
                    <li class="py-1">unless otherwise stated, entry to the battle is FREE</li>
                </ul>
                <ul class="list-disc ml-5" :class="howToPlayTabHidden(2)">
                    <li class="py-1">upload 2 FREE photos per each battle or use your available credits to upload extra  photos to increase the chance for higher score</li>
                    <li class="py-1">out of your pictures, only the one with highest score will be calculated for overall standings and eligible for credit rewards </li>
                    <li class="py-1">reported photos will be examined and might be excluded from competition</li>
                    <li class="py-1">uploading new photos is only possible during UPLOAD WEEK (typically Mon-Sun prior VOTING WEEK)</li>
                    <li class="py-1">changing already uploaded photo for another one is only available for credits</li>
                </ul>
                <ul class="list-disc ml-5" :class="howToPlayTabHidden(3)">
                    <li class="py-1">when selecting photos for later voting, try to browse through as many pictures as you can to find those really awesome shots</li>
                    <li class="py-1">no need for blind voting in order to keep your exposure meter full because we don't have such thing</li>
                    <li class="py-1">fair and responsible voting matters</li>
                    <li class="py-1 font-bold">you can give/bet either 1,2,3,4 or 5 votes</li>
                    <li class="py-1" style="visibility:hidden;"></li>
                </ul>
                <ul class="list-disc ml-5" :class="howToPlayTabHidden(4)">
                    <li class="py-1">to achieve the highest possible combined score you need to be a good photographer and a good voter</li>
                    <li class="py-1">VOTING WEEK is typically Mon-Fri right after the UPLOAD WEEK</li>
                    <li class="py-1">when voting, take your time and bet more votes on the best pictures in your select in order to earn more voting points or credits</li>
                    <li class="py-1">the higher the photos you voted for end up, more points/credits you earn  (SEE THE VOTING CHART)</li>
                    <li class="py-1">you can still vote in battles where you don't have any of your own pictures, giving you the extra chance to earn more points</li>
                </ul>
                <ul class="list-disc ml-5" :class="howToPlayTabHidden(5)">
                    <li class="py-1">every Sunday the results for the actuall battles come up and the high score board for each battle is ready</li>
                    <li class="py-1">to become a great champion you need to get very high score for your shots plus for voting in at least 10 different battles</li>
                    <li class="py-1">on your PERSONAL SCORE BOARD you will see your updated 10 best battles score, 10 best votings score and what's your standing in general classification</li>
                    <li class="py-1">ALL TIME CHAMPIONS SCORE BOARD will be updated</li>
                    <li class="py-1" style="visibility:hidden;"></li>
                </ul>
                <ul class="list-disc ml-5" :class="howToPlayTabHidden(6)">
                </ul>
            </div>
    </aside>
    </section>

    <template v-if="getLastWinnersImages.length>0">
        <section class="bg-black">
                <h2 class="font-bold text-3xl text-center mb-2">LATEST WINNERS</h2>
                <p class="pb-8 text-center text-lg">Best photos in last 3 contests.</p>
                <div class="relative" :style="getObjPreFotky">
                    <template v-for="(photo,idx) in getLastWinnersImages" :key="photo.id">
                            <div class="absolute ease-in-out transition-transform duration-300" :style="getPozicieFoto[idx]">
                                <div class="flip-card bg-transparent" :style="getVelkostiFoto[idx]">
                                    <div class="flip-card-inner w-full h-full relative text-center">
                                        <div class="flip-card-front absolute w-full h-full">
                                        <img :src="photo.photoPath+'_t.jpg'" alt="Avatar" :style="getVelkostiFoto[idx]">
                                        </div>
                                        <div class="flip-card-back flex absolute w-full h-full flex-col justify-center bg-pink-our text-white">
                                            <img :src="photo.user.photoPath" :alt="photo.user.nick" class="w-20 block mb-2 mx-auto">
                                        <strong>{{photo.user.nick}}</strong>
                                        <p class="text-xl text-center">{{vypisMiesto(photo.place)}}<br><br>
                                        <span class="bg-blue-our rounded-lg shadow-md py-2 px-4 cursor-pointer" @click="$router.push({path:'/user-profile/'+photo.user.userUrl})">user profile</span>
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </template>
                </div>
        </section>
    </template>

    </div>
</template>

<script>
    import functions,{checkVisible} from '../components/functions';
    export default {
        mixins:[functions],
        beforeMount(){
            this.vyratajPozicieFoto();
        },
        mounted(){

            // ['resize','orientationchange'].forEach( evt => 
            //     window.addEventListener(evt, debounce(this.vyratajPozicieFoto, 500))
            // );

            // window.addEventListener('resize', debounce(this.vyratajPozicieFoto, 500));
            window.addEventListener('resize', this.vyratajPozicieFoto);

            //-----------otocenie textu pred videom
            let video=document.getElementById('video');
            let videoText=document.getElementById('videoText');
            window.onscroll = function() {
                if(checkVisible(video)){
                    videoText.style.transform="rotate3d(0, 0, 1, 0deg)";
                }
            };


        },
        beforeUnmount: function () {
            // ['resize','orientationchange'].forEach( evt => 
            //     window.addEventListener(evt,this.vyratajPozicieFoto)
            // );
            window.removeEventListener('resize', this.vyratajPozicieFoto)
        },
        created(){
            //-------pozadie stranky-------
            document.getElementsByTagName('body')[0].classList.remove('bgImageBlue');

            if(this.$route.fullPath.includes('/forgotpassword')){
                if('code' in this.$route.params){
                    if(this.$route.params.code!==""){
                        this.$store.commit('setUpperModal',{visible:true,whatToShow:'ForgotenPasswordEnterNew',data:{code:this.$route.params.code}});
                    }
                }
            }else if(this.$route.fullPath.includes('/signup')){
                this.$store.commit('setUpperModal',{visible:true,whatToShow:'SignupPanelConfirm',data:{code:this.getCodeFromSignUp}});
            }
            if('id' in this.$route.query){
                this.loadPaymentStatus(this.$route.query.id);
            }
            //--------casovac na odpocet aktualneho ActiveBattle
            this.getActiveBattleTimeToEnd();
            if(this.getTimeToEndClosestActiveBattle>0){
                let sett=setInterval(()=>{this.timeToEndClosestActiveBattle--;if(this.getTimeToEndClosestActiveBattle==0){clearInterval(sett);}},1000);
            }

        },
        data(){
            return{
                blogClass:{1:'bg-brown-blog',2:'bg-blue-blog',3:'bg-green-blog'},
                howToPlayTabActive:1,
                timeToEndClosestActiveBattle:0,
                pozicieFoto:[],//----najlepsie fotky za posledne sutaze
                velkostiFoto:[],//----najlepsie fotky za posledne sutaze
                objPreFotky:{width:0,height:0},//----najlepsie fotky za posledne sutaze
            }
        },
        computed:{
            getTimeToEndClosestActiveBattle(){
                return this.timeToEndClosestActiveBattle;
            },
            getURLsite(){
                return this.$store.getters.URLSITE;
            },
            getCodeFromSignUp(){
                if('code' in this.$route.params){
                    return this.$route.params.code;
                }
                return "";
            },
            getBlog(){
                return this.$store.getters.getBlog;
            },
            getLastWinnersImages(){
                return this.$store.getters.getLastWinnersImages;
            },
            getScrollText(){
                return this.$store.getters.getScrollText;
            },
            getActiveBattle(){
                return this.$store.getters.getAvailableBattles({'paid':false});
            },
            getFormatedTime(){
                let sec_num = parseInt(this.timeToEndClosestActiveBattle);
                let days   = Math.floor(sec_num / 86400);
                let hours   = Math.floor((sec_num - (days * 86400)) / 3600);
                let minutes = Math.floor((sec_num - ((days * 86400)+(hours * 3600))) / 60);
                let seconds = sec_num - ((days * 86400) + (hours * 3600) + (minutes * 60));

                if (days   < 10) {days   = "0"+days;}
                if (hours   < 10) {hours   = "00"+hours;}
                if (minutes < 10) {minutes = "0"+minutes;}
                if (seconds < 10) {seconds = "0"+seconds;}
                return days+':'+hours+':'+minutes+':'+seconds;
            },
            getObjPreFotky(){
                return `width:100%;height:${this.objPreFotky.height}px;`;
            },
           
            getPozicieFoto(){
                return this.pozicieFoto;
            },
            getVelkostiFoto(){
                return this.velkostiFoto;
            }
        },
        methods:{
            routerActiveBattle(){
                this.$router.push({path:'/active-contests'});
            },
            getBgImage(image){
                return {'background-image':'url('+this.$store.getters.URLPICTURES+'/'+image+')'};
            },
            loadPaymentStatus(id){
                this.$store.commit('setUpperModal',{visible:true,whatToShow:'PaymentStatus',data:{'id':id}});
            },
            howToPlayTabChange(id){
                this.howToPlayTabActive=id;
            },
            howToPlayTabGetClass(idTab){
                if(idTab===this.howToPlayTabActive){
                    if(idTab==1){
                        return {"cursor-pointer":true,"bg-gray-500":true}
                    }else if(idTab==2){
                        return {"cursor-pointer":true,"bg-green-our":true}
                    }else if(idTab==3){
                        return {"cursor-pointer":true,"bg-green-select":true}
                    }else if(idTab==4){
                        return {"cursor-pointer":true,"bg-blue-our":true}
                    }else if(idTab==5){
                        return {"cursor-pointer":true,"bg-red-our":true}
                    }
                }else{
                    return {"cursor-pointer":false}
                }
            },
            howToPlayTabBorder(idTab){
                if(idTab==1){
                    return {"howToPlayBorderStart":true}
                }else if(idTab==2){
                    return {"howToPlayBorderUpload":true}
                }else if(idTab==3){
                    return {"howToPlayBorderSelect":true}
                }else if(idTab==4){
                    return {"howToPlayBorderVote":true}
                }else if(idTab==5){
                    return {"howToPlayBorderResult":true}
                }
            },
            howToPlayTabHidden(idTab){
                if(idTab===this.howToPlayTabActive){
                    return {"hidden":false}
                }else{
                    return {"hidden":true}
                }
            },
            getActiveBattleTimeToEnd(){
                if(this.getActiveBattle.length>0){
                    let timeToEndBattle=this.getActiveBattle.reduce((a,b)=>a.battleSettings.toBattleEnd<=b.battleSettings.toBattleEnd?a:b);
                    this.timeToEndClosestActiveBattle=timeToEndBattle.battleSettings.toBattleEnd;
                }else{
                    this.timeToEndClosestActiveBattle=0;
                }
            },
            vyratajPozicieFoto(){
                let screenWidth=document.documentElement.clientWidth<window.innerWidth?document.documentElement.clientWidth:window.innerWidth;
                // console.log(document.documentElement.clientWidth,window.innerWidth,screenWidth,window.devicePixelRatio);
                // let screenWidth = (Math.abs(window.orientation) == 90 || Math.abs(window.orientation) == -90) ? window.innerHeight : window.innerWidth;
                // console.log('w',screenWidth);
                // (Math.abs(window.orientation) == 90 || Math.abs(window.orientation) == -90) ? window.innerWidth : window.innerHeight;
                // // let screenWidth=screen.availWidth;
                // console.log(Math.abs(window.orientation), window.innerHeight,window.innerWidth);
                // brekakpoint 
                // 320 - 1
                // 600 - 2
                // 900 - 3
                // 1200- 4
                let BASE_SIZE={gapSize:10,columns:8,columnWidth:300,maxImageSize:450,positionY:[],marginLeft:0};
                let objWidth=screenWidth;
                if(objWidth<600){
                    BASE_SIZE.columns=1;
                }else if(objWidth<900){
                    BASE_SIZE.columns=2;
                }else if(objWidth>=900){
                    BASE_SIZE.columns=Math.trunc(objWidth/300);
                }
                
                BASE_SIZE.positionY=Array(BASE_SIZE.columns).fill(0);
                BASE_SIZE.columnWidth=objWidth>(BASE_SIZE.maxImageSize*BASE_SIZE.columns)?BASE_SIZE.maxImageSize:((objWidth/BASE_SIZE.columns)-(BASE_SIZE.columns-1)*BASE_SIZE.gapSize);
                BASE_SIZE.marginLeft=Math.floor((objWidth-(((BASE_SIZE.columns-1)*BASE_SIZE.gapSize)+(BASE_SIZE.columns*BASE_SIZE.columnWidth)))/2);
                    // BASE_SIZE.positionY=Array(BASE_SIZE.columns).fill(0);
                    // BASE_SIZE.columnWidth=objWidth>(BASE_SIZE.maxImageSize*BASE_SIZE.columns)?BASE_SIZE.maxImageSize:((objWidth/BASE_SIZE.columns)-(columns-1)*BASE_SIZE.gapSize);
                    // BASE_SIZE.marginLeft=Math.floor(objWidth-(((columns-1)*BASE_SIZE.gapSize)+(columns*columnWidth))/2);
                    // BASE_SIZE.positionY=Array(BASE_SIZE.columns).fill(0);
                    // BASE_SIZE.columnWidth=objWidth>(BASE_SIZE.maxImageSize*BASE_SIZE.columns)?BASE_SIZE.maxImageSize:((objWidth/BASE_SIZE.columns)-(columns-1)*BASE_SIZE.gapSize);
                    // BASE_SIZE.marginLeft=Math.floor(objWidth-(((columns-1)*BASE_SIZE.gapSize)+(columns*columnWidth))/2);
                this.getLastWinnersImages.forEach((el,idx)=>{
                    let poziciaX,poziciaY=0;
                    let poradie=BASE_SIZE.positionY.indexOf(Math.min(...BASE_SIZE.positionY));
                    poziciaX=(poradie*BASE_SIZE.columnWidth)+(poradie*BASE_SIZE.gapSize)+BASE_SIZE.marginLeft;
                    poziciaY=BASE_SIZE.positionY[poradie];

                    BASE_SIZE.positionY[poradie]+=Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))+BASE_SIZE.gapSize;
                    this.pozicieFoto[idx]=`position:absolute;left:${((poziciaX/objWidth)*100).toFixed(5)}%;top:${poziciaY}px;width:${BASE_SIZE.columnWidth}px;height:${Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))-BASE_SIZE.gapSize}px;`;
                    this.velkostiFoto[idx]=`width:${BASE_SIZE.columnWidth}px;height:${Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))-BASE_SIZE.gapSize}px;`;
                });

                // let photoBoxWidth=objWidth;
                // //--------vyratame pocet columnov
                // BASE_SIZE=preratajBASE_SIZE(BASE_SIZE,photoBoxWidth)
                // this.getLastWinnersImages.forEach((el,idx)=>{
                //     let poziciaX,poziciaY=0;
                //     let poradie=BASE_SIZE.positionY.indexOf(Math.min(...BASE_SIZE.positionY));
                //     poziciaX=(poradie*BASE_SIZE.columnWidth)+(poradie*BASE_SIZE.gapSize);
                //     poziciaY=BASE_SIZE.positionY[poradie];
                //     BASE_SIZE.positionY[poradie]+=Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))+BASE_SIZE.gapSize;
                //     this.pozicieFoto[idx]=`transform:translate(${poziciaX}px, ${poziciaY}px);width:${BASE_SIZE.columnWidth}px;height:${Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))-BASE_SIZE.gapSize}px;`;
                //     this.velkostiFoto[idx]=`width:${BASE_SIZE.columnWidth}px;height:${Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))-BASE_SIZE.gapSize}px;`;
                // });

                // this.objPreFotky.width=photoBoxWidth;
                this.objPreFotky.height=Math.max(...BASE_SIZE.positionY.map((el)=>parseInt(el)));
            },
            vypisMiesto(miesto){
                if(miesto==0){
                    return "1st place";
                }else if(miesto==1){
                    return "2nd place";
                }else if(miesto==2){
                    return "3rd place";
                }else if(miesto==3){
                    return "4th place";
                }else{
                    return (miesto+1)+"th place";
                }
            }
        }, 
        watch: {
            $route(){
                if('id' in this.$route.query){
                    this.loadPaymentStatus(this.$route.query.id);
                }
            }
        }
    }
</script>