<template>
<div class="fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80 ">
  <div class="w-11/12 max-w-xs relative p-5 border-solid border-b-4 border-red-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
    <div class="font-bold text-red-our text-lg">FORGOTEN PASSWORD<span class="button-close-x" title="Close Modal" @click="close()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span></div>
    <form>
      <div v-if="errorText.length>0" class="my-2 text-red-our text-center">{{errorText}}</div>
      <div v-if="okText.length>0" class="my-2 text-green-our text-center" v-html="okText"></div>
      <div class="py-2" v-if="!success">
        <input type="email" class="bg-black border border-gray-400 border-solid inline-block mx-0 my-1 px-5 py-3 text-white w-full box-border" placeholder="Enter your email" name="umail" autocomplete="username" ref="umail" required>
        <button type="submit" class="bg-red-our text-white text-center py-3 px-5 w-full mt-4" @click.prevent="sendForgotenPassword">RESET PASSWORD</button>
      </div>
      <div class="pt-4 flex justify-between items-center">
          <button type="button" class="bg-blue-our text-sm py-3 px-5 cursor-pointer" @click.prevent="prepniNa('SignupPanel')">SIGN UP</button>
          <button type="button" class="bg-green-our text-sm py-3 px-5 cursor-pointer" @click.prevent="prepniNa('LoginPanel')">LOG IN</button>
        </div>
    </form>
  </div>
</div>
</template>

<script>
import Axios from 'axios'
export default {
  emits:['closeModal'],
  data(){
    return{
      errorText:"",
      okText:"",
      success:false
    }
  },
  methods:{
    close(){
      this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
    },
    prepniNa(naCoMaPrepnut){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:naCoMaPrepnut,data:{}});
    },
    sendForgotenPassword(){
      this.okText="";
      this.errorText="";
      var umail=this.$refs.umail;
      umail.value.trim();
      if(umail.value.length==0){
        this.errorText="Please enter Email";
      }else{
        this.$store.commit('setModalLoading',true);
        Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=forget-password',{'umail':umail.value})
        .then((response) => {
            if(response.data.return){
                this.okText=response.data.returnMsg;
                this.success=true;
            }else{
              this.errorText=response.data.returnMsg;
            }
          })
        .catch((e) => {
          this.$store.commit('setErrorModal',{visible:true,data:"#63 Network error"+e+""});
        })
        .finally(()=>{
          this.$store.commit('setModalLoading',false);
        });
      }
    }
  }
}
</script>

