<template>
<div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
    <div class="w-11/12 max-w-sm relative p-5 border-solid border-b-4 border-green-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <span class="button-close-x" title="Close Modal" @click="zavriModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        <h3 class="mb-5 text-center text-green-our text-2xl">Expand your select slots</h3>
            <div class="mb-5">Mate pouzite vsetky body na select, je este {{getBattle.activeBattle.unseenPhotos}} neselectnutych fotiek mozete si zakupit dalsie sloty ({{getBattle.generalSettings.extraSelectPurchase}})</div>
        <div v-if="showSuccessfullPurchaseHodnota" class="text-center mb-5 text-green-our">
            Nakup bol uspesny mate dalsich {{getBattle.generalSettings.extraSelectPurchase}} select left
        </div>
        <button v-if="showUnlock" class="bg-orange-our text-center text-lg text-white py-2 w-full my-3" @click="unlock()">UNLOCK</button>
        <button v-else-if="!showSuccessfullPurchaseHodnota" class="bg-orange-our text-center text-lg text-white py-2 w-full my-3" @click="purchaseSelectSlot()">YES USE {{getBattle.generalSettings.extraSelectPurchasePrice}} CREDITS NOW</button>
    </div>

</div>
</template>

<script>
import functions from '../functions.vue'
import Axios from 'axios';
export default {
    mixins:[functions],
    // beforeMount(){
    //     this.$store.getters.upperModal.data.battleId;
    // },
    data(){
        return {
            showUnlock:true,
            showSuccessfullPurchase:false
        }
    },
    methods:{
        zavriModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
        unlock(){
            this.showUnlock=false
        },
        purchaseSelectSlot(){
            var udaje={'battle':this.getBattle.battleSettings.nameSeo};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=select-slot-purchase", udaje)
            .then((response)=>{
                var res=response.data;
                if('code' in res && res.code==1){//-----------odlogovany uzivatel
                    this.$store.commit('deleteLoadedData');
                    this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                    this.$router.push({path:'/active-contests'});
                }else{
                    if(res.return===false){
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }else if(res.return===true){
                        //------musim vratit hodnoty
                        let nastavSelectLeft=parseInt(this.getBattle.generalSettings.extraSelectPurchase)+parseInt(this.getBattle.activeBattle.selectLeft);
                        let nastavSelectLeftBeforeSubmit=parseInt(this.getBattle.generalSettings.extraSelectPurchase)+parseInt(this.getBattle.activeBattle.selectLeftBeforeSubmit);
                        this.$store.commit('setBuyExtraSelect',{'battleId':this.getBattleId,'data':{'selectLeft':nastavSelectLeft,'selectLeftBeforeSubmit':nastavSelectLeftBeforeSubmit,'extraSelect':res.extraSelect}});
                        this.showSuccessfullPurchase=true;
                    }
                }
            })
            .catch((e)=>{
                this.$store.commit('setErrorModal',{visible:true,data:"#28 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        }
    },
    computed:{
        getBattleId(){
            return this.$store.getters.getUpperModal.data.battleId;
        },
        getBattle(){
            return this.$store.getters.getBattle(this.getBattleId);
        },
        showSuccessfullPurchaseHodnota(){
            return this.showSuccessfullPurchase;
        }
    },
}
</script>

<style>

</style>