<template>
    <div></div>
</template>
<script>

export default {
    created(){
        this.$store.dispatch('logoutUser');
        this.$router.push('/active-contests');
    },
}

</script>>