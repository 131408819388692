<template>
  <div class="fixed left-0 top-0 w-full overflow-auto h-full flex justify-center items-center bg-black z-lowerModal">
    <div class="w-full h-full relative overflow-y-auto">
        <div class="flex justify-center fixed z-lowerModalPlus w-full h-12 items-center bg-black bg-opacity-80" >
            <span class="text-white py-2 px-4 block max-w-xs self-start border-b-4 border-l-4 border-r-4 border-white rounded-bl-lg rounded-br-lg bgGradientBlueGreen text-center bg-opacity-80 border-solid">VOTE LEFT <strong>{{tempVoteLeft}}</strong> OF <strong>{{MaxVotes}}</strong></span>
            <span class="button-close-x1" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        </div>
        <div class="bgGradientBlueGreen text-center border-4 border-r-0 border-solid cursor-pointer fixed lg:px-8 lg:py-5 lg:text-2xl md:px-4 md:py-2 px-2 py-1 right-0 rounded-bl-xl rounded-tl-xl top-1/2 z-lowerModalPlus" v-if="getTempVotes.length>0" title="Send votes" @click.prevent="submitVotes()"><div class="pb-1">SEND</div><div class="border-t-2 border-white border-solid">VOTES</div></div>
        <div class="columns-8-350px py-12 px-2 w-full mx-auto">
            <template v-for="photo in getPhotosForVote" :key="photo.idPhoto">
                <div class="relative mb-4 votePhotoItem">
                    <figure class="relative shadow1" :title="photo.title">
                        <img :src="photo.photoPath+'_t.jpg'" class=" w-full h-auto">
                        <div class="absolute inset-0"></div>
                    </figure>

                    <img :src="$store.getters.URLPICTURES+'/zoom-in.svg'" class="mobileHidden absolute top-3 right-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.stop="modalZoomInImage(photo)">
                    <div class="absolute bottom-2 left-2 flex justify-start">
                        <template v-if="photo.voted==0 && getTempVote(photo.idPhoto)==0">
                            <template v-for="n in 5" :key="n+1">
                                <span class="w-10 h-10 bg-black bg-opacity-50 hover:opacity-100 hover:bg-blue-our cursor-pointer bg-no-repeat bg-center bg-75% rounded-full flex justify-center items-center text-2xl font-bold" :class="{'animate-pulse':photo.votedLast==n}" title="Click to vote for this photo" v-if="tempVoteLeft>=n" @mousedown.stop="setTempVote(photo.idPhoto,photo.idChecksum,n)">{{n}}</span>
                            </template>
                        </template>
                        <template v-else-if="photo.voted>0">
                                <span class="w-10 h-10 bg-black block opacity-50 hover:opacity-100 hover:bg-blue-our cursor-pointer bg-no-repeat bg-center bg-75% rounded-full iconReload" title="Click to reset this vote" @click="resetOneVote(photo.idPhoto)"></span>
                        </template>
                    </div>
                    <span v-if="photo.voted>0" class="-translate-x-1/2 -translate-y-1/2 absolute bg-cover select-none h-32 iconVoteBig left-1/2 top-1/2 transform w-32 flex justify-center items-center text-5xl font-bold">{{photo.voted}}</span>
                    <span v-if="getTempVote(photo.idPhoto)>0" class="-translate-x-1/2 -translate-y-1/2 absolute bg-cover select-none h-32 iconVoteBig left-1/2 top-1/2 transform w-32 flex justify-center items-center text-5xl font-bold cursor-pointer" @click="setTempVote(photo.idPhoto,photo.idChecksum,-1)">{{getTempVote(photo.idPhoto)}}</span>
                </div>
            </template>
        </div>
         <div class="bgGradientBlueGreen text-center border-4 border-solid cursor-pointer md:hidden text-lg px-4 py-2 rounded-xl z-lowerModalPlus max-w-sm mx-auto mb-6" v-if="getTempVotes.length>0" title="Send votes" @click.prevent="submitVotes()">SEND VOTES</div>
    </div>
</div>
</template>

<script>
//shareX
import Axios from 'axios'
import functions from '../functions.vue';
import gsap from 'gsap'
export default {
    mixins:[functions],
    beforeMount(){
        if(this.getBattle.photosForVote.length==0){
            this.nacitajPhotoPreVote();
        }
    },
    mounted(){
        if(this.getBattle.photosForVote.length>0){
            gsap.fromTo('.votePhotoItem', 0.5, {opacity:0,y:'-50%'}, {opacity:1,y:0,stagger: 0.1,})
        }
    },
    data(){
        return{
            // zmenaVoVotes:false,//----ak uzivatel pridelil nejake hlasy sa prepren na true a ak bude chciet zatvorit
            tempVotes:[]
        }
    },
    computed:{
        getTempVotes(){
            return this.tempVotes;
        },
        getBattleId(){
            return this.$store.getters.getLowerModal.data.battleId;
        },
        getPhotosForVote(){
            return this.$store.getters.getPhotosForVote(this.getBattleId);
        },
        getBattle(){
            return this.$store.getters.getBattle(this.getBattleId);
        },
        MaxVotes(){
            return parseInt(this.getBattle.generalSettings.maxVotes);
        },
        tempVoteLeft(){
            var voteLeft=parseInt(this.getBattle.activeBattle.voteLeft);
            var pocetTempVotes=0;
            this.getTempVotes.forEach((el)=>{if(el.value>0){pocetTempVotes+=el.value}});
            return voteLeft-pocetTempVotes;
        },
    },
    methods:{
        getTempVote(photoId){
            var photoIndex=this.getTempVotes.map((el)=>el.photoId).indexOf(photoId);
            if(photoIndex==-1){
                return 0;
            }else{
                return this.tempVotes[photoIndex].value;
            }
        },
        getPhotoForVote(photoId){
            var photoObj=this.$store.getters.getPhotoForVote({'battleId':this.getBattleId,'photoId':photoId});
            return photoObj;
        },
        nacitajPhotoPreVote(){
            var udaje={'battle':this.getBattle.battleSettings.nameSeo};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=load-vote-photos',udaje)
            .then((response) => {
                var res=response.data;
                if('code' in res && res.code==1){//-----------odlogovany uzivatel
                    this.$store.commit('deleteLoadedData');
                    this.$router.push({path:'/active-contests'});
                }else{
                    if(res.return==false){
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }else{
                        if(res.fotografie.length==0){//----------zistime ci su nejake fotky na vote
                            //----------ziadne fotografie na nacitanie vypise hlasku ze uzivatel musi najpr odselectovat fotky aby mohol votovat
                            this.$store.commit('setUpperModal',{visible:true,whatToShow:'VoteNoPhoto',data:{}});
                        }else{                            
                            let dataNaZapis={
                                data:{
                                    voteLeft:parseInt(res.voteLeft),
                                    totalPhotosInBattle:parseInt(res.totalPhotosInBattle),
                                    alreadySeenPhotos:parseInt(res.alreadySeenPhotos),
                                    photosForVote:res.fotografie
                                },
                                battleId:this.getBattleId
                            }
                            //-------zapiseme vseobecne data z dataNaZapis do $store battlu
                            this.$store.commit('setToPhotosForVote',dataNaZapis);
                        }
                    }
                }
            })
            .catch((e) => {
                this.$store.commit('setErrorModal',{visible:true,data:"#23 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
                // console.log(this.$store.getters.battleObject(this.battleUdaje.id));
            });
        },
        modalZoomInImage(photo){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{photoPath:photo.photoPath,
            photoFileName:photo.photoFileName,
            exif:photo.exif,
            size:photo.size,
            isAdult:photo.isAdult,
            idPhoto:photo.idPhoto,
            battleSeo:this.getBattle.battleSettings.nameSeo
            }}); 
        },
        setTempVote(photoId,idChecksum,newValue){
            var voteIndex=this.getTempVotes.map((el)=>el.photoId).indexOf(photoId);
            console.log(voteIndex);
            if(voteIndex>=0){
                this.tempVotes.splice(voteIndex,1);
            }else{
                this.tempVotes.push({'photoId':photoId,'idChecksum':idChecksum,'value':newValue});
            }
            console.log('this.tempVotes',this.getTempVotes);
        //    this.$store.commit('setVoteToPhotoForVote',{'battleId':this.getBattleId,'photoId':photoId,'value':parseInt(value)}); 
        //     this.zmenaVoVotes=true;//----------uzivatel klikol a teda nastavime hlasku pre odidenim z Vote
        //     console.log('hlasovane',photoId,value);
        },
        closeModal(){
            //---------zistime ci boli zmeny vo vote podla premennej zmenaVoVotes
            if(this.tempVotes.length>0){
                this.$store.commit('setUpperModal',{visible:true,whatToShow:'VoteExitBeforeSubmit',data:{'battleId':this.getBattleId}}); 
            }else{
                this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
            }
        },
        resetOneVote(photoId){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ResetOneVote',data:{'battleId':this.getBattleId,'photoId':photoId}}); 
        },
        submitVotes(){
            this.$store.commit('setModalLoading',true);
            
            var poslaneFoto=[];
                this.getTempVotes.forEach((udaj)=>{
                    poslaneFoto.push({'idPhoto':udaj.photoId, 'idChecksum':udaj.idChecksum, 'voted':udaj.value});
                });
            var udaje={'battle':this.getBattle.battleSettings.nameSeo,'photos':JSON.stringify(poslaneFoto)};

            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=vote-photo',udaje)
            .then((response)=>{
                var res=response.data;
             
                if('code' in res && res.code==1){//-----------odlogovany uzivatel
                    this.$store.commit('deleteLoadedData');
                    this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                    this.$router.push({path:'/active-contests'});
                }else{
                    if(res.return==false){
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }else if(res.return==true){   
                        //-----upravime udaje v battleUdaje
                        window.scrollTo({ top: 0, behavior: 'smooth' });

                        this.$store.commit('setUpperModal',{visible:true,whatToShow:'SelectSubmitOk',data:{}}); 
                        setTimeout(()=>{
                            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                            if(parseInt(res.voteLeft)==0){
                                //---------zobrazime uzivatelovi hlasku je uz minul vsetky votes 
                                this.$store.commit('setUpperModal',{visible:true,whatToShow:'GoodJobAllDone',data:{}});
                            }

                        },1500);

                        
                        let dataNaZapis={
                            data:{
                                voteLeft:parseInt(res.voteLeft),
                                totalPhotosInBattle:parseInt(res.totalPhotosInBattle),
                                alreadySeenPhotos:parseInt(res.alreadySeenPhotos),
                                photosForVote:res.fotografie
                            },
                            battleId:this.getBattleId
                        }
                        //------zmazeme sucasne fotky z votes
                        this.$store.commit('deleteBattleListPhotosForVote',this.getBattleId);

                        //-------zapiseme vseobecne data z dataNaZapis do $store battlu
                        this.$store.commit('setToPhotosForVote',dataNaZapis);

                        this.tempVotes=[];
                    }
                }
            })
            .catch((e)=> {
                this.$store.commit('setErrorModal',{visible:true,data:"#29 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            }); 
        }
    }
}
</script>

<style>

</style>