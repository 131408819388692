<template>
    <img v-if="color" :src="$store.getters.URLPICTURES+'/empty.svg'" class="w-12 h-12 border-b-2 border-green-our m-1 box-content bg-black" title="Free photo slot">
    <img v-else :src="$store.getters.URLPICTURES+'/empty.svg'" class="w-12 h-12 border-b-2 border-gray-300 m-1 bg-black box-content filter-grayscale" title="Free photo slot">
</template>
<script>
export default {
    props:['battleId'],
    computed:{
        color(){
            let vyzden=this.$store.getters.getTypBattle(this.battleId);
            if(vyzden=="greenBox"){ 
                return true;
            }else{
                return false;
            }
        },
    }
}
</script>