<template>
  <div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80 ">
    <div class="w-11/12 max-w-xs relative p-5 border-solid border-b-4 border-green-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
      <div class="font-bold text-green-our text-lg">LOG IN / SIGN UP
        <span class="button-close-x" title="Close Modal" @click="close()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
      </div>
      <form>
        <div v-if="errorText.length>0" class="my-2 text-red-our text-center">{{errorText}}</div>
        <div v-if="getSignUpText.length>0" class="my-2 text-green-our text-center" v-html="getSignUpText"></div>
        <div class="py-2">
          <label for="fname"></label><input type="email" class="bg-black border border-gray-400 border-solid inline-block mx-0 my-1 px-5 py-3 text-white w-full box-border" placeholder="Enter Email" name="fname" autocomplete="username" v-model="loginName" required>
          <label for="fpass"></label><input type="password" class="bg-black border border-gray-400 border-solid inline-block mx-0 my-1 px-5 py-3 text-white w-full box-border" placeholder="Enter Password min. 6 letters" name="fpass" minlength="6" autocomplete="current-password" v-model="loginPass" required>
          <button v-if="getLoginPass>=5" type="submit" class="bg-green-our text-white text-center py-3 px-5 w-full mt-4" @click.prevent="logIn()">LOG IN / SIGN UP</button>
          <button v-else type="submit" class="bg-gray-600 text-white text-center cursor-not-allowed py-3 px-5 w-full mt-4" disabled>LOG IN / SIGN UP</button>
        </div>
          <div class="flex justify-around">
            <button v-if="Vue3GoogleOauth.isInit" class="bg-black block border border-gray-dark border-solid flex items-center justify-start w-1/2 mx-1 my-3 py-2 text-center text-white w-full" @click.prevent="handleClickSignIn()">
              <img :src="$store.getters.URLPICTURES+'/google.svg'" class="block mx-auto w-8" alt="LOG IN WITH GOOGLE">
            </button>
            <button  class="bg-black block border border-gray-dark border-solid flex items-center justify-start w-1/2 mx-1 my-3 py-2 text-center text-white w-full" @click.prevent="logInWithFacebook()">
              <img :src="$store.getters.URLPICTURES+'/facebook.svg'" class="block mx-auto w-8" alt="LOG IN WITH FACEBOOK">
            </button>
          </div>
        <div class="pt-4 text-right">
          <!-- <button type="button" class="bg-blue-our text-sm py-3 px-5 cursor-pointer" @click="prepniNa('SignupPanel')">SIGN UP</button> -->
          <span class="text-white text-sm">Forgot <span class="text-orange-our cursor-pointer text-base" @click.prevent="prepniNa('ForgotenPassword')">password?</span></span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import Axios from 'axios'
import functions from '../../functions'
export default {
  setup() {
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    return {
      Vue3GoogleOauth,
      // handleClickLogin,
      // isSignIn,
    }
  },
  mixins:[functions],
  data(){
    return {
      errorText:"",
      signUpText:"",
      loginName:"",
      loginPass:"",
    }
  },
  computed:{
    getSignUpText(){
      return this.signUpText;
    },
    getLoginPass(){
      return this.loginPass.length;
    }
  },
  methods:{
    //-------https://github.com/guruahn/vue3-google-oauth2 
    async handleClickSignIn(){
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {//----------------prepreslo prihlasenie 
          this.errorText="Login failed";
          return null;
        }else{//-------uzivatel sa prihlasil cez google-------zavolame backend
          this.errorText="";
          let mail = googleUser.getBasicProfile().getEmail();
          let name = googleUser.getBasicProfile().getName();
          let googleId = googleUser.getBasicProfile().getId();
          mail.trim();
          name.trim();
          googleId.trim();
          if(mail.length==0 || name.length==0 || googleId.length==0){
            this.errorText="Login with google was not possible";
          }else{
            let udaje={'name':name,'email':mail,'googleId':googleId};

            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=google-login',udaje)
            .then((response) => {
                if(response.data.return){//------------sme zalogovany alebo signUp 
                  if(this.$route.fullPath.includes('/forgotpassword') || this.$route.fullPath.includes('/signup')){//---ak je to z tohot linku /forgotpassword redirectne sa na homepage
                    location.replace(this.$store.getters.URLDOMAIN);
                  }else{
                    location.replace(this.$store.getters.URLDOMAIN+""+this.$route.path);
                  }
                }else{
                  this.errorText=response.data.returnMsg;
                }
              })
            .catch((e) => {
              this.$store.commit('setErrorModal',{visible:true,data:"#126 Login with Google was not successfull"+e+""});
            })
            .finally(()=>{
              this.$store.commit('setModalLoading',false);
            });
          }
        }


        // let avatar = googleUser.getBasicProfile().getAvatar();
        // console.log("getId", this.user);
        // console.log("getBasicProfile", googleUser.getBasicProfile());
        // console.log("getAuthResponse", googleUser.getAuthResponse());
        // console.log(
        //   "getAuthResponse",
        //   this.$gAuth.instance.currentUser.get().getAuthResponse()
        // );
      } catch (error) {
        this.errorText="Login failed";
        return null;
      }
    },
    close(){
      this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
    },
    prepniNa(naCoMaPrepnut){
      this.close();
      this.$store.commit('setUpperModal',{visible:true,whatToShow:naCoMaPrepnut,data:{}});
    },
    logIn(){//-------------klasicky login--------
      this.errorText="";
      this.loginName.trim();
      this.loginPass.trim();
      if(this.loginName.length==0 || this.loginPass.length==0){
        this.errorText="Please enter Email and Password";
      }else{
        this.$store.commit('setModalLoading',true);
        Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=login',{'fname':this.loginName,'fpass':this.loginPass})
        .then((response) => {
          console.log('App',response);
            if(response.data.return){//------------sme zalogovany alebo signUp
              if(response.data.returnSignUp){//--------ak je to signUp
                this.signUpText="Link to activate your account was sent to your email. <b>Link will be active 4 hours</b>";
              }else{// ak sme zalogovany redirectne nas na my-focus
                if(this.$route.fullPath.includes('/forgotpassword') || this.$route.fullPath.includes('/signup')){//---ak je to z tohot linku /forgotpassword redirectne sa na homepage
                  location.replace(this.$store.getters.URLDOMAIN);
                }else{
                  location.replace(this.$store.getters.URLDOMAIN+""+this.$route.path);
                }
              }
            }else{
              this.errorText=response.data.returnMsg;
            }
          if(response.data.returnDeleteParams){//--------ak sme dostali premenu   returnDeleteParams zmazeme input          
            this.loginName="";
            this.loginPass="";
          }
          })
        .catch((e) => {
          this.$store.commit('setErrorModal',{visible:true,data:"#53 Network error"+e+""});
        })
        .finally(()=>{
          this.$store.commit('setModalLoading',false);
        });
      }
    },

    async logInWithFacebook() {
      try {
        await this.fbLogin();
      } catch (error) {
        this.errorText="Login failed";
      }
    },
    // Facebook login with JavaScript SDK
    async fbLogin() {
      window.FB.login((response)=>{
          if (response.authResponse) {
              window.FB.api('/me', {locale: 'en_US', fields: 'id,first_name,last_name,email,link,locale,picture'},
              (response)=>{
                try{  
                  let udaje={'name':response.first_name+" "+response.last_name,'email':response.email,'facebookId':response.id,'avatar':response.picture.data.url};
                  this.$store.commit('setModalLoading',true);
                  Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=facebook-login',udaje)
                  .then((response) => {
                      if(response.data.return){//------------sme zalogovany redirectne nas na my-focus
                        if(this.$route.fullPath.includes('/forgotpassword') || this.$route.fullPath.includes('/signup')){//---ak je to z tohot linku /forgotpassword redirectne sa na homepage
                          location.replace(this.$store.getters.URLDOMAIN);
                        }else{
                          location.replace(this.$store.getters.URLDOMAIN+""+this.$route.path);
                        }
                      }else{
                        this.errorText=response.data.returnMsg;
                      }
                    })
                  .catch((e) => {
                    this.$store.commit('setErrorModal',{visible:true,data:"#187 Login with Facebook was not successfull"+e+""});
                  })
                  .finally(()=>{
                    this.$store.commit('setModalLoading',false);
                  });
                }catch{
                  this.errorText="Login failed";
                  return null;
                }
              });
          } else {
              this.errorText="Login failed";
              return null;
          }
      }, {scope: 'email'});
    },
  }
}
</script>

