import { createStore } from 'vuex'
import Axios from 'axios'

export default createStore({
  state() {
    return{
      URLajax:"https://www.captainfocus.com/action",
      URLsite:"https://www.captainfocus.com",
      URLpictures:"https://www.captainfocus.com/pictures",
      URLdomain:"https://www.captainfocus.com",
      // URLajax:"http://localhost/captain/actionTest",
      // URLsite:"http://localhost/captain",
      // URLpictures:"http://localhost/captain/pictures",
      // URLdomain:"http://localhost:8080",
      firstEverLoad:{
        exclusiveContests:false,
        upcomingContests:false,
        activeContests:false,
        finishedContests:false,
        dailyGames:false
      },
      dailyGames:{
        guessWinner:{
          settings:{
            active:false,
            idGame:false,
          },
        },
        guessBrand:{
          settings:{
            active:false,
            idGame:false,
          },
          // active:false,
          // finished:false,
          // photoList:[]
        }
      },
      battleList:[],
      battleCount:[],
      achievements:{
        all:[],
        user:[],
        structure:[]
      },
      modalLoading:{
        visible:false,
        message:"Loading ..."
      },
      upperModal:{
        visible:false,
        whatToShow:"",
        data:{}
      },
      lowerModal:{
        visible:false,
        whatToShow:"",
        data:{}
      },
      errorModal:{
        visible:false,
        data:""
      },
      userData:{
        nick:"",
        email:"",
        userUrl:"",
        country:0,
        countryList:[],
        selectedAvatar:0,
        avatarList:[],
        userLogged:false,
        seeAdultPhotos:false,
        actualCredits:0,
        creditList:[],
        creditListTotal:0,
        products:[]
      },
      leaderBoard:{
        photo:{
          list:[],
          user:{},
          maxScore:0,
          resultCount:0
        },
        vote:{
          list:[],
          user:{},
          maxScore:0,
          resultCount:0
        },
        total:{
          list:[],
          user:{},
          maxScore:0,
          resultCount:0
        }
      },
      scoreBoard:[],
      generalRules:[],
      top100Photos:[],
      usersProfile:[],
      faq:[],
      terms:'',
      mainMenu:{
        // status:false,
        // actualMenu:'close',
        actualMenu:'',
        menuList:{
          'home':[
            {text:'Front page',action:'/',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            // {text:'Blog',action:'https://captainfocus.com/Blog',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'FAQ',action:'/faq',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'Contact',action:'/contact',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'Terms and Conditions',action:'/terms-and-conditions',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'}],
          'leaderboard':[
            {text:'BEST OF 10 TOTAL',action:'/best-of-10',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'BEST OF 10 votes',action:'/best-of-10-votes',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'BEST OF 10 photos',action:'/best-of-10-photos',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'}],
          'contests':[
            {text:'Exclusive contests',action:'/exclusive-contests',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer text-orange-our border-orange-our'},
            {text:'Upcoming contests',action:'/upcoming-contests',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'Active contests',action:'/active-contests',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'Finished contests',action:'/finished-contests',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'Daily games',action:'/daily-games',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer text-green-our border-green-our'},
            {text:'Achievements',action:'/achievements',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'}],
          'user':[
            {text:'Log out',action:'/log-out',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'My credit',action:'/my-credit',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'My achievements',action:'/achievements',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'},
            {text:'My account',action:'/my-account',class:'border-b border-dashed p-3 text-center text-xl cursor-pointer'}],
        },
      },
      homePageData:{
        scrollText:"",
        lastWinnersImages:[],
        blog:[],
      },
    }
  },
  actions:{
    logoutUser({commit,getters}){
      var config={
          timeout: 1000*20,
          headers:{
          'Content-type': 'application/json',
          'Accept': 'application/json',
          }
      };

      Axios.get(getters.URLAJAX+'/handler.php?page=logout',{},config)
      .catch((e) => {
        commit('setErrorModal',{visible:true,data:"Error during loading data"+e+""});
      })
      .finally(()=>{ 
        commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
        commit('setUserLogged',false);
        commit('deleteLoadedData');
      });

    },
    addToBattles({commit,getters},battles){
      battles.forEach((battle)=>{
        if(!getters.getBattleExists(battle.battleSettings.id)){
          console.log('addToBattles','nenachadza ',battle.battleSettings.id);
          commit('setBattleList',battle);
        }
      });
    },
    // loginUser({commit}){
    //   commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
    //   commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
    //   commit('setUserLogged',true);
    //   commit('deleteLoadedData');
    // },
    setTimeOnBattles({commit,getters}){
      getters.getBattleList.forEach((battle)=>{
        if(battle.battleSettings.toBattleStart>0){
          // console.log('toBattleStart',battle.toBattleStart);
          commit('setActiveBattlesTimeValue',{id:battle.battleSettings.id,property:'toBattleStart'});
        }else if(battle.battleSettings.toVoteStart>0){
          // console.log('datumZaciatkuVoteUnix',battle.datumZaciatkuVoteUnix);
          commit('setActiveBattlesTimeValue',{id:battle.battleSettings.id,property:'toVoteStart'});
        }else if(battle.battleSettings.toBattleEnd>0){
          // console.log('datumKoncaBattleUnix',battle.datumKoncaBattleUnix);
          commit('setActiveBattlesTimeValue',{id:battle.battleSettings.id,property:'toBattleEnd'});
        }
      });
    }
  },
  mutations: {
    setSeeAdultPhotos(state,data){
      state.userData.seeAdultPhotos=data;
    },
    setBattleList(state,data){
      state.battleList.push(data);
    },
    setBlog(state,data){
      state.homePageData.blog=data;
    },
    setLastWinnersImages(state,data){
      state.homePageData.lastWinnersImages=data;
    },
    setScrollText(state,data){
      state.homePageData.scrollText=data;
    },
    setBattleCount(state,data){
      if('upcomingContest' in data){
        state.battleCount.upcomingContest=parseInt(data.upcomingContest);
      }else{state.battleCount.upcomingContest=0;}
      if('finishedContest' in data){
        state.battleCount.finishedContest=parseInt(data.finishedContest);
      }else{state.battleCount.finishedContest=0;}
      if('exclusiveContest' in data){
        state.battleCount.exclusiveContest=parseInt(data.exclusiveContest);
      }else{state.battleCount.exclusiveContest=0;}
      if('activeContest' in data){
        state.battleCount.activeContest=parseInt(data.activeContest);
      }else{state.battleCount.activeContest=0;}
    }, 
    setAchievementsList(state,data){
      state.achievements.all.push(data);
    },
    setAchievementsUser(state,data){
      state.achievements.user.push(data);
    }, 
    setAchievementsStructure(state,data){
      state.achievements.structure.push(data);
    }, 
    setBattleListpaidServices(state,data){
      var poleId=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      if(poleId>=0){
        state.battleList[poleId]["paidServices"][data.property]=data.value;
      }else{
        state.errorModal.visible=true;
        state.errorModal.data="Please reload the page";
      }
    },
    setBattleListgeneralSettings(state,data){
      var poleId=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      if(poleId>=0){
        state.battleList[poleId]["generalSettings"][data.property]=data.value;
      }else{
        state.errorModal.visible=true;
        state.errorModal.data="Please reload the page";
      }
    },
    setBattleListphotosUploaded(state,data){
      var poleId=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      state.battleList[poleId].photosUploaded.push(data.photo);
    },
    deleteBattleListphotosUploaded(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      var indexPhoto=state.battleList[indexBattle].photosUploaded.map((e)=>e.photoFileName).indexOf(data.photoFileName);
      state.battleList[indexBattle].photosUploaded.splice(indexPhoto, 1);
    },

    deleteBattleListPhotosForSelect(state,battleId){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(battleId);
      state.battleList[indexBattle].photosForSelect.splice(0, state.battleList[indexBattle].photosForSelect.length);
    },
    deleteBattleListPhotosForVote(state,battleId){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(battleId);
      state.battleList[indexBattle].photosForVote.splice(0, state.battleList[indexBattle].photosForVote.length);
    }, 
    setGeneralRules(state,data){
      state.generalRules=data;
    },
    deleteLoadedData(state){//-----------zmazanie dat uzivatela po odhlaseni
        console.log('deleteLoadedData');
        state.battleList.forEach((el)=>{
          el.photosForSelect.splice(0,el.photosForSelect.length);
          el.photosForVote.splice(0,el.photosForVote.length);
          el.paidServices={'photoSlots':0,'extraSelect':0}
          el.photosUploaded.splice(0,el.photosUploaded.length);
          state.achievements.user.splice(0,state.achievements.user.length);

          //-----active battle infovoteLeft
          el.activeBattle.selectLeft=el.generalSettings.maxSelect;
          el.activeBattle.selectLeftBeforeSubmit=el.generalSettings.maxSelect;
          el.activeBattle.voteLeft=el.generalSettings.maxVotes;
          el.activeBattle.alreadySeenPhotos=0;
          el.activeBattle.totalPhotosInBattle=0;
          //-----finished Battle info
          el.finishedBattle.userResult={'participate':false,
          'scorePhoto':0,'creditPhoto':0,'placePhoto':0,'scoreVote':0,'creditVote':0,'placeVote':0,'placeTotal':0,'photosUploaded':[],'achievements':[]}
        });
        //------ see adults photos
        state.userData.seeAdultPhotos=false;
        state.userData.userLogged=false;
        state.userData.userUrl="";
        state.userData.nick="";
        state.userData.email="";
        state.userData.country=0;
        state.userData.countryList.length=0,
        state.userData.selectedAvatar=0;
        state.userData.avatarList.length=0;
        state.userData.actualCredits=0;
        state.userData.creditList.length=0;
        state.userData.creditListTotal=0;

        state.leaderBoard.total.user={};
        state.leaderBoard.vote.user={};
        state.leaderBoard.photo.user={};

        if(state.scoreBoard.length>0){
          state.scoreBoard.forEach((el)=>{
            el.total.user={};
            el.photo.user={};
            el.vote.user={};
          });
        }
    },
    // deleteUsersData(state){
    //     state.battleList.splice(0,state.battleList.length);
    //     state.achievements.all.splice(0,state.achievements.all.length);
    //     state.achievements.user.splice(0,state.achievements.user.length);
    //     state.achievements.structure.splice(0,state.achievements.structure.length);
    // },
    setModalLoading(state,prepinac){
      state.modalLoading.visible=prepinac;
    },
    setErrorModal(state,udaje){
      state.errorModal.visible=udaje.visible;
      state.errorModal.data=udaje.data;
    },
    setActiveBattlesTimeValue(state,data){
      var idPola=state.battleList.map(function(e){return e.battleSettings.id; }).indexOf(data.id);
      if(idPola>=0){
        state.battleList[idPola].battleSettings[data.property]--;
        // console.log(idPola,state.activeBattles[idPola][data.property]);
      }
    },
    setUserLogged(state,data){
      state.userData.userLogged=data;
      if(data===true){//-------nastavime cookies na dve adresy kde uzivatel musi byt prihlaseny
        document.cookie ="userLogged=true;max-age=20min;path=/my-credit;";
        document.cookie ="userLogged=true;max-age=20min;path=/my-acount;";
      }else{
        document.cookie ="userLogged=true;max-age=-20min;path=/my-credit;";
        document.cookie ="userLogged=true;max-age=-20min;path=/my-acount;";
      }
    },
    setUpperModal(state,udaje){
      //-------prevent scroll background on modal
      if(udaje.visible){//------on open
        document.body.style.overflow='hidden'; 
        document.body.style.top = '-'+window.scrollY+'px';
        console.log(window.scrollY);
      }else{//------on close
        document.body.style.overflow='visible'; 
        const scrollY = document.body.style.top;
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
      state.upperModal.visible=udaje.visible;
      state.upperModal.whatToShow=udaje.whatToShow;
      state.upperModal.data=udaje.data;
    },
    setLowerModal(state,udaje){
      console.log('setLowerModal',udaje);
      //-------prevent scroll background on modal
      if(udaje.visible){//------on open
        document.body.style.overflow='hidden'; 
        document.body.style.top = '-'+window.scrollY+'px';
      }else{//------on close
        document.body.style.overflow='visible'; 
        const scrollY = document.body.style.top;
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
      state.lowerModal.visible=udaje.visible;
      state.lowerModal.whatToShow=udaje.whatToShow;
      state.lowerModal.data=udaje.data;
    },
    setFinishedBattles(state,data){
      state.finishedBattles.push(data);
    },
    setToPhotosForSelect(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      state.battleList[indexBattle].activeBattle.voteLeft=data.data.voteLeft;
      state.battleList[indexBattle].activeBattle.selectLeft=data.data.selectLeft;
      state.battleList[indexBattle].activeBattle.selectLeftBeforeSubmit=data.data.selectLeftBeforeSubmit;
      state.battleList[indexBattle].activeBattle.totalPhotosInBattle=data.data.totalPhotosInBattle;
      state.battleList[indexBattle].activeBattle.alreadySeenPhotos=data.data.alreadySeenPhotos;
      if('allSeenPhotos' in data.data){
        state.battleList[indexBattle].activeBattle.allSeenPhotos=data.data.allSeenPhotos;
      }
      data.data.photosForSelect.forEach((el)=>{
        state.battleList[indexBattle].photosForSelect.push(el);
      });
    },
    setSelectToPhotoForSelect(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      var indexPhoto=state.battleList[indexBattle].photosForSelect.map((e)=>e.idPhoto).indexOf(data.photoId);
      if(indexPhoto>=0){
        state.battleList[indexBattle].activeBattle.selectLeft-=data.uprava;
        state.battleList[indexBattle].photosForSelect[indexPhoto].selected+=data.uprava;
      }
      console.log('setSelectToPhotoForSelect',data,state.battleList[indexBattle].activeBattle.selectLeft,state.battleList[indexBattle].photosForSelect[indexPhoto].selected);
    },
    setReportToPhotoForSelect(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      var indexPhoto=state.battleList[indexBattle].photosForSelect.map((e)=>e.idPhoto).indexOf(data.photoId);
      if(indexPhoto>=0){
        state.battleList[indexBattle].photosForSelect[indexPhoto].reported=1;
      }
    },
    setBuyExtraSelect(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      state.battleList[indexBattle].paidServices.extraSelect=parseInt(data.data.extraSelect);
      state.battleList[indexBattle].activeBattle.selectLeft=data.data.selectLeft;
      state.battleList[indexBattle].activeBattle.selectLeftBeforeSubmit=data.data.selectLeftBeforeSubmit;
    },
    setToPhotosForVote(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      state.battleList[indexBattle].photosForVote.splice(0,state.battleList[indexBattle].photosForVote.length);
      state.battleList[indexBattle].activeBattle.voteLeft=data.data.voteLeft;
      state.battleList[indexBattle].activeBattle.totalPhotosInBattle=data.data.totalPhotosInBattle;
      state.battleList[indexBattle].activeBattle.alreadySeenPhotos=data.data.alreadySeenPhotos;

      data.data.photosForVote.forEach((el)=>{
        state.battleList[indexBattle].photosForVote.push(el);
      });
    },
    setVoteToPhotoForVote(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      var indexPhoto=state.battleList[indexBattle].photosForSelect.map((e)=>e.idPhoto).indexOf(data.photoId);
      if(indexPhoto>=0){
        state.battleList[indexBattle].photosForVote[indexPhoto].voted=data.value;
      }
      console.log('setSelectToPhotoForSelect',data,state.battleList[indexBattle].activeBattle.selectLeft,state.battleList[indexBattle].photosForSelect[indexPhoto].selected);
    },
    setResetOneVote(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      state.battleList[indexBattle].activeBattle.voteLeft=parseInt(data.voteLeft);
      var indexPhoto=state.battleList[indexBattle].photosForVote.map((e)=>e.idPhoto).indexOf(data.photoId);
      if(indexPhoto>=0){
        state.battleList[indexBattle].photosForVote[indexPhoto].voted=0
      }
      console.log('setResetOneVote',data,state.battleList[indexBattle].activeBattle.voteLeft,indexPhoto,state.battleList[indexBattle].photosForVote[indexPhoto].voted);
    },
    setResetVotes(state,data){
      var indexBattle=state.battleList.map((e)=>e.battleSettings.id).indexOf(data.battleId);
      state.battleList[indexBattle].activeBattle.voteLeft=parseInt(data.voteLeft);
      state.battleList[indexBattle].photosForVote.forEach((el)=>{
        el.voted=0;
      });
    },
    setMainMenu(state,data){
      if('actualMenu' in data){state.mainMenu.actualMenu=data.actualMenu}
    },
    setFirstEverLoad(state,data){
      if('exclusiveContests' in data){state.firstEverLoad.exclusiveContests=data.exclusiveContests}
      if('upcomingContests' in data){state.firstEverLoad.upcomingContests=data.upcomingContests}
      if('activeContests' in data){state.firstEverLoad.activeContests=data.activeContests}
      if('finishedContests' in data){state.firstEverLoad.finishedContests=data.finishedContests}
      if('dailyGames' in data){state.firstEverLoad.dailyGames=data.dailyGames}
    },
    setUserData(state,data){
      if('nick' in data){state.userData.nick=data.nick}
      if('userUrl' in data){state.userData.userUrl=data.userUrl}
      if('email' in data){state.userData.email=data.email}
      if('country' in data){state.userData.country=data.country}
      if('countryName' in data){state.userData.countryName=data.countryName}
      if('countryList' in data){
        if(state.userData.countryList.length>0){
          state.userData.countryList.length=0
        }
        state.userData.countryList=data.countryList
      }
      if('avatarPhotoPath' in data){state.userData.avatarPhotoPath=data.avatarPhotoPath}
      if('selectedAvatar' in data){state.userData.selectedAvatar=data.selectedAvatar}
      if('avatarList' in data){state.userData.avatarList=data.avatarList}
      if('seeAdultPhotos' in data){state.userData.seeAdultPhotos=data.seeAdultPhotos}
      if('actualCredits' in data){state.userData.actualCredits=data.actualCredits}
      if('creditList' in data){
        data.creditList.forEach(el=>state.userData.creditList.push(el));
      }
      if('creditListTotal' in data){state.userData.creditListTotal=data.creditListTotal}
      if('products' in data){state.userData.products=data.products}
      if('socialLinksUnlocked' in data){state.userData.socialLinksUnlocked=data.socialLinksUnlocked}
      if('facebookLink' in data){state.userData.facebookLink=data.facebookLink}
      if('instagramLink' in data){state.userData.instagramLink=data.instagramLink}
      if('websiteLink' in data){state.userData.websiteLink=data.websiteLink}
      if('socialLinksPrice' in data){state.userData.socialLinksPrice=data.socialLinksPrice}
    },
    deleteUserCreditList(state){
      state.userData.creditList.length=0;
    },
    setUserDataNewAvatar(state,photoPath){
      let index=state.userData.avatarList.find((el)=>{el.id==0});
      if(index === 'undefined') {
        state.userData.avatarList.push({'id':0,'photoPath':photoPath});
      }else{
        state.userData.avatarList[0].photoPath=photoPath;
      }
    },
    setLeaderBoard(state,data){
      if('maxScore' in data){state.leaderBoard[data.type].maxScore=data.maxScore}
      if('resultCount' in data){state.leaderBoard[data.type].resultCount=data.resultCount}
      if('user' in data){state.leaderBoard[data.type].user=data.user}
      if('list' in data){
        data.list.forEach((el)=>{
          state.leaderBoard[data.type].list.push(el);
        });
      }
    },
    setScoreBoard(state,data){
      let blankObj={//------------default objekt
        photo:{
          list:[],
          user:{},
          maxScore:0,
          resultCount:0
        },
        vote:{
          list:[],
          user:{},
          maxScore:0,
          resultCount:0
        },
        total:{
          list:[],
          user:{},
          maxScore:0,
          resultCount:0
        }
      };
      let battleExists=state.scoreBoard.find((el)=>el.battleSeo==data.battleSeo);
      if(battleExists===undefined){//--------ak sa objekt nenasiel vytvori ho, naplni datami a zapise
        blankObj.battleSeo=data.battleSeo;
        blankObj.battleName=data.battleName;
        if('maxScore' in data){blankObj[data.type].maxScore=data.maxScore}
        if('resultCount' in data){blankObj[data.type].resultCount=data.resultCount}
        if('user' in data){blankObj[data.type].user=data.user}
        if('list' in data){
          data.list.forEach((el)=>{
            blankObj[data.type].list.push(el);
          });
        }
        state.scoreBoard.push(blankObj);
      }else{//------ak objekt existuje zapise don udaje
        if('maxScore' in data){battleExists[data.type].maxScore=data.maxScore}
        if('resultCount' in data){battleExists[data.type].resultCount=data.resultCount}
        if('user' in data){battleExists[data.type].user=data.user}
        if('list' in data){
          data.list.forEach((el)=>{
            battleExists[data.type].list.push(el);
          });
        }
      }
    },
    setTop100Photos(state,data){
      let battleExists=state.top100Photos.find(el=>el.battleSeo===data.battleSeo);
      if(battleExists===undefined){
        state.top100Photos.push({battleSeo:data.battleSeo,battleName:data.battleName,paidBattle:data.paidBattle,photoList:data.photoList});
      }
    },
    setTop100PhotosAdult(state,data){
      state.top100Photos.find((el)=>{
        if(el.battleSeo===data.battleSeo){
          el.photoList.find((photo)=>{
            if(photo.id===data.idPhoto){
              photo.isAdult=0;
            }
          });
        }
      });
    },
    setBestUserPhotosAdult(state,data){
      state.usersProfile.find((el)=>{
        if(el.userUrl===data.userUrl){
          el.topPhotos.find((photo)=>{
            if(photo.id===data.idPhoto){
              photo.isAdult=0;
            }
          });
        }
      });
    },
    setUsersProfile(state,data){
      state.usersProfile.push(data);
    },
    setFaq(state,data){
      state.faq=data.faq;
    },
    setTerms(state,data){
      state.terms=data.terms;
    },
    // setChangeDailyGamesSelected(state,data){
    //   if(state.dailyGames.guessWinner.photoList[data.obj]){
    //     state.dailyGames.guessWinner.photoList[data.obj].selected=data.newValue;
    //   }
    // },
    // setChangeDailyGamesisAdult(state,data){
    //   if(state.dailyGames.guessWinner.photoList[data.obj][data.obj1]){
    //     state.dailyGames.guessWinner.photoList[data.obj][data.obj1].isAdult=data.newValue;
    //   }
    // },
    setDailyGames(state,data){
      console.log('setDailyGames',data);
      var kdeArray = data.kde.split(".");
      if(kdeArray.length==1){
        state.dailyGames[kdeArray[0]]=data.co;
      }else if(kdeArray.length==2){
        console.log('dailyGames',kdeArray[0],kdeArray[1],data.co);
        state.dailyGames[kdeArray[0]][kdeArray[1]]=data.co;
      }else if(kdeArray.length==3){
        state.dailyGames[kdeArray[0]][kdeArray[1]][kdeArray[2]]=data.co;
      }else if(kdeArray.length==4){
        state.dailyGames[kdeArray[0]][kdeArray[1]][kdeArray[2]][kdeArray[3]]=data.co;
      }
    }
  },
  getters:{
    getUpperModal(state){
      return state.upperModal;
    },
    getLowerModal(state){
      return state.lowerModal;
    },
    getErrorModal(state){
      return state.errorModal;
    },
    URLAJAX(state){
      return state.URLajax;
    },
    URLSITE(state){
      return state.URLsite;
    },
    URLDOMAIN(state){
      return state.URLdomain;
    },
    URLPICTURES(state){
      return state.URLpictures;
    },
    getModalLoading(state){
      return state.modalLoading;
    },
    getBattleList(state){
      return state.battleList.filter((battle) => battle==battle);
    },
    getUserData(state){
      return state.userData;
    },
    getUserLogged(state){
      return state.userData.userLogged;
    },
    getSeeAdultPhotos(state){
      return state.userData.seeAdultPhotos;
    },
    getGeneralRules(state){
      return state.generalRules;
    },
    getFirstEverLoad(state){
      return state.firstEverLoad;
    },
    getTerms(state){
      return state.terms;
    },
    getBlog(state){
      return state.homePageData.blog;
    },
    getLastWinnersImages(state){
      return state.homePageData.lastWinnersImages;
    },
    getScrollText(state){
      return state.homePageData.scrollText;
    },
    getMainMenu(state){
      return state.mainMenu;
    },
    getBattleCount(state){
      return state.battleCount;
    },
    getBattleExists(state){
      return function(battleId){
        var battleIndex=state.battleList.map((el)=>el.battleSettings.id).indexOf(battleId);
        if(battleIndex==-1){
          return false;
        }else{
          return true;
        }
      }
    },
    getFinishedBattleUserPhoto(state){
      return function(data){
        var battleId=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(data.battleId);
        return state.battleList[battleId].finishedBattle.userResult.photosUploaded.filter((el)=>{return el.id==data.fotkaId})[0];
      }
    },
    getFinishedBattleTopPhoto(state){
      return function(data){
        var battleId=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(data.battleId);
        console.log('battleId',battleId,data.battleId,'fotkaId',data.fotkaId);
        return state.battleList[battleId].finishedBattle.topPhotos.filter((el)=>{return el.id==data.fotkaId})[0];
      }
    },
    getAchievement(state){
      return function(idecko){
        var data=state.achievements.all.find(el=>parseInt(el.id)===parseInt(idecko));
        return data;
      }
    },
    getAchievements(state){
      return state.achievements.all;
    },
    getAchievementsStructure(state){
      return state.achievements.structure;
    },
    getUserAchievementCount(state){
      return function(id){
        var poleId=state.achievements.user.map(el=>parseInt(el.idAchievement)).indexOf(parseInt(id));
        if(poleId==-1){
          return false;
        }else{
          return state.achievements.user[poleId].count;
        }
      }
    },
    getPhotosForSelect(state){
      return function(battleId){
        var index=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(battleId);
        return state.battleList[index].photosForSelect;
      }
    },
    getPhotoForSelect(state){
      return function(data){
        var index=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(data.battleId);
        var indexPhoto=state.battleList[index].photosForSelect.map((el)=>{return el.idPhoto}).indexOf(data.photoId);
        return state.battleList[index].photosForSelect[indexPhoto];
      }
    },
    getPhotosForVote(state){
      return function(battleId){
        var index=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(battleId);
        return state.battleList[index].photosForVote;
      }
    },
    getPhotoForVote(state){
      return function(data){
        var index=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(data.battleId);
        var indexPhoto=state.battleList[index].photosForVote.map((el)=>{return el.idPhoto}).indexOf(data.photoId);
        return state.battleList[index].photosForVote[indexPhoto];
      }
    },
    getFinishedBattles(state){
      return function(data){
        return state.battleList.filter((e)=>{
          //-------podmienka je ze to musi byt finished battle podla skonceneho casu toBattleEnd==0 a este zalezi ci chceme data.paid=true alebo false  - teda plateny alebo free battle
          let return1,return2=false;
          return1=parseInt(e.battleSettings.toBattleEnd)==0?true:false;
          return2=e.battleSettings.paidBattle===data.paid?true:false;
          return return1 && return2;
        });
      }
    },
    getAvailableBattles(state){//--------battles ktore existuju a uzivatel sa do nich moze nahlasit
      return function(data){
        return state.battleList.filter((e)=>{
          //-------podmienka je ze to musi byt aktivny battle podla datumu a este zalezi ci chceme data.paid=true alebo false  - teda plateny alebo free battle
          let return1,return2,return3=false;
          return1=parseInt(e.battleSettings.toBattleEnd)>0?true:false;
          return2=parseInt(e.battleSettings.toBattleStart)===0?true:false;
          return3=e.battleSettings.paidBattle===data.paid?true:false;
          return return1 && return2 && return3;
        })
      }
    },
    getUpcomingBattles(state){//--------battles ktore sa chystaju teda maju toBattleStart vacsie ako 0
      return function(data){
        return state.battleList.filter((e)=>{
          //-------podmienka je ze to musi byt aktivny battle podla datumu a este zalezi ci chceme data.paid=true alebo false  - teda plateny alebo free battle
          let return1,return2=false;
          return1=parseInt(e.battleSettings.toBattleStart)>0?true:false;
          return2=e.battleSettings.paidBattle===data.paid?true:false;
          return return1 && return2;
        })
      }
    },
    getAllPaidBattles(state){//--------battles ktore existuju a su platene
      return function(data){
        return state.battleList.filter((e)=>{
          //-------podmienka je ze to musi byt paid battle
          let return1=false;
          return1=e.battleSettings.paidBattle===data.paid?true:false;
          return return1;
        })
      }
    },
    getTypBattle(state){ 
      return function(id){
          var battleId=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(id);
          if(state.battleList[battleId].battleSettings.toBattleStart>0){
            return "upcomingBox";
          }else if(state.battleList[battleId].battleSettings.toBattleStart==0 && state.battleList[battleId].battleSettings.toVoteStart>0){
            return "greenBox";
          }else if(state.battleList[battleId].battleSettings.toVoteStart==0 && state.battleList[battleId].battleSettings.toBattleEnd>0){
            return "blueBox";
          }else{
            return "finishedBox";
          }
      }
    },
    getBattle(state){
      return function(id){
        let battleId=state.battleList.map((el)=>{return el.battleSettings.id}).indexOf(id);
        return state.battleList.filter((el,idx)=>{return idx==battleId})[0];
      }
    },
    getPhotoNahrane(state){
      return function(data){//{data.battleId,data.photoFileName}
        let battleId=state.activeBattles.map((el)=>{return el.id}).indexOf(data.battleId);
        return state.activeBattles[battleId].photosNahrane.filter((el)=>{return el.photoFileName==data.photoFileName})[0];
      }
    },
    getLeaderBoard(state){
      return function(data){
        return state.leaderBoard[data];
      }
    },
    getScoreBoard(state){
      return function(data){
        let battle=state.scoreBoard.find(el=>el.battleSeo===data.battleSeo);
        if(typeof battle !== 'undefined'){
          return battle;
        }
        return false;
      }
    },
    getTop100Photos(state){
      return function(data){
        let battle=state.top100Photos.find(el=>el.battleSeo===data);
        if(typeof battle == 'undefined'){
          return false;
        }
        return battle;
      }
    },
    getUsersProfile(state){
      return function(data){
        let user=state.usersProfile.find(el=>el.userUrl===data);
        if(typeof user == 'undefined'){
          return false;
        }
        return user;
      }
    },
    getFaq(state){
        if(state.faq.length==0){
          return false;
        }
        return state.faq;
    },
    getDailyGames(state){
      return state.dailyGames;
    }
  },
  modules: {
  },
})
