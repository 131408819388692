<template>
<div class="z-lowerModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
    <div class="w-11/12 max-w-lg relative p-5 border-solid border-b-4 border-green-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <div class="font-bold text-green-our text-lg mb-4">Photos uploading
            <span class="button-close-x" title="Close Modal" @click.prevent="zavriModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        </div>
        <div class="">
            <div v-if="getBattle.generalSettings.photoSlotPrice && showInfoPanel=='extraPhoto'" class="bg-black text-orange-our my-3 flex justify-between items-center" title="Upload EXTRA photo">
                    <p class="p-2">Use <strong>{{getBattle.generalSettings.photoSlotPrice}} credits</strong> to upload EXTRA photo</p><span class="bg-orange-our text-white p-2 cursor-pointer" @click="submitPurchasePhotoSlot()">UNLOCK</span>
            </div>
            <div class="flex flex-wrap justify-between">
                <image-box v-for="(fotka,idx) in nahraneFotoFree" :key="idx" :photoFileName="fotka.photoFileName" :battleId="getBattleId"></image-box>
                <free-box v-for="(n,idx1) in pocetZostavajucichFreeSlotov" :key="idx1" :battleId="getBattleId"></free-box>
                <image-box v-for="(fotka,idx2) in nahraneFotoCredit" :key="idx2" :photoFileName="fotka.photoFileName" :battleId="getBattleId"></image-box>
                <template v-for="(pocet,idx3) in pocetPlatenychSlotov" :key="idx3">
                    <unlocked-box v-if="(pocet<=getBattle.paidServices.photoSlots-nahraneFotoCredit.length)" :battleId="getBattleId"></unlocked-box>
                    <locked-box v-else :typeIcon="'locked'" :battleId="getBattleId" @emitShowInfoPanel="setShowInfoPanel"></locked-box>
                </template>
            </div>
            <upload-process v-for="(file,idx5) in uploadFileList" :key="idx5" :file="file"></upload-process>
            <template v-if="zobrazitTlacidloUpload" >
            <label for="inputFiles" class="relative block py-3 px-5 cursor-pointer bg-green-our text-white mx-auto mt-3 w-36 text-center rounded-sm">
                <input type="file" id="inputFiles" name="inputFiles" class="absolute w-0 h-0 opacity-0 top-0 left-0"  accept="image/jpg" @change="addFile" multiple>upload photos</label>
            </template>
            <template v-else>
                <div class="text-center py-2 text-orange-our">Unlock more slots for more uploads</div>
            </template>
        </div>
    </div>
</div>
</template>

<script>

const dataURItoBlob = (dataURI) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
};

function blobToFile(theBlob, fileName){       
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}


async function myResize(file,index) {
    console.log('response1',file);
    let response = await resizeImage({ 'file': file, 'maxHeight': 1080,'index':index });
    console.log('response2',response);
    let vystup=blobToFile(response, "name.jpg");
    return vystup;
}

const resizeImage = ({ file, maxHeight,index }) => {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
        let { width, height } = image;
        console.log(maxHeight);
        
        if(height>maxHeight){
            let ratio=height/maxHeight;
            width=width/ratio;
            height=height/ratio;
        }

            var ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);

            canvas.width = width;
            canvas.height = height;

        ctx.drawImage(image, 0, 0, width, height);

        var dataUrl = canvas.toDataURL("image/jpeg");

        return dataURItoBlob(dataUrl);
        // return dataUrl;
    };

    return new Promise((ok, no) => {

        reader.onload = (readerEvent) => {
        image.onerror = () =>no(index);
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};

function getExif(img){
    return new Promise(function(resolve){
        var exifVystup={};
        var orientation=1;
        exif.getData(img, ()=>{
            if(exif.getTag(img, "Make")){exifVystup.Make=exif.getTag(img, "Make")}
            if(exif.getTag(img, "Model")){exifVystup.Model=exif.getTag(img, "Model")}
            if(exif.getTag(img, "ExposureTime")){exifVystup.ExposureTime="1/"+(1/Number(exif.getTag(img, "ExposureTime")))}
            if(exif.getTag(img, "ISOSpeedRatings")){exifVystup.ISOSpeedRatings=Number(exif.getTag(img, "ISOSpeedRatings"))}
            if(exif.getTag(img, "FNumber")){exifVystup.ApertureFNumber=exif.getTag(img, "FNumber")}
            if(exif.getTag(img, "Orientation")){orientation=Number(exif.getTag(img, "Orientation"))}

            resolve({'exif':exifVystup,'orientation':orientation});
        });

    });
}


import ImageBox from './UploadImage/ImageBox'
import FreeBox from './UploadImage/FreeBox'
import LockedBox from './UploadImage/LockedBox'
import UnlockedBox from './UploadImage/UnlockedBox'
import UploadProcess from './UploadImage/UploadProcess'
// import DropFileBox from './UploadImage/DropFileBox'
import functions from '../functions'
import Axios from 'axios'
import exif from 'exif-js'
export default {
    mixins:[functions],
    components:{ImageBox,FreeBox,LockedBox,UnlockedBox,UploadProcess},
    mounted(){
        // console.log('povodny',this.getBattle);
    },
    data(){
        return{
            uploadFileList:[],
            pocitadloPoradia:0,
            showInfoPanel:null
        }
    },
    provide(){
        return{
            uploadFileList:this.uploadFileList
        }
    },
    computed:{
        getBattleId(){
            return this.$store.getters.getLowerModal.data.battleId;
        },
        getBattle(){
            return this.$store.getters.getBattle(this.getBattleId);
        },
        nahraneFotoFree(){
            return this.getBattle.photosUploaded.filter((element)=>{
                return element.freePhoto==1;
            });
        },
        nahraneFotoCredit(){
            return this.getBattle.photosUploaded.filter((element)=>{
                return element.freePhoto==0;
            });
        },
        pocetZostavajucichFreeSlotov(){
            return this.akMinusTakVypisNula(parseInt(this.getBattle.battleSettings.maxPhotosFree)-parseInt(this.nahraneFotoFree.length));
        },
        pocetPlatenychSlotov(){
            return this.akMinusTakVypisNula(parseInt(this.getBattle.battleSettings.maxPhotosCredit)-parseInt(this.nahraneFotoCredit.length));
        },
        zobrazitTlacidloUpload(){
            if(this.getBattle.photosUploaded.length<(parseInt(this.getBattle.paidServices.photoSlots)+parseInt(this.getBattle.battleSettings.maxPhotosFree))){
                return true;
            }
            return false;
        }
    },
    methods:{
        setShowInfoPanel(){
            this.showInfoPanel='extraPhoto';
        },
        
        zavriModal(){
            this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        },
        validateFile(file){
            console.log('data odtialto',file);
            var vystup={
                id:this.pocitadloPoradia,
                show:true,
                stav:false,
                text:"",
                url:"",
                process:"Validating",
                uploadProcess:0,
                fileName:file.name
            };
            if(file.size>(40*1024*1024)){
                vystup.text="#64 Max file size 8MB";
                vystup.process="ERROR";
            }else if(file.type!=="image/jpeg"){
                vystup.text="#65 Only JPG images";
                vystup.process="ERROR";
            }else if(this.pocetZostavajucichVsetkychSlotov==0){
                vystup.text="#66 No photo slot left";
                vystup.process="ERROR";
            }else{
                console.log('validator',file);
                vystup.url=URL.createObjectURL(file);
                vystup.stav=true;
            }
            this.uploadFileList.push(vystup);
            this.pocitadloPoradia++;
            return this.uploadFileList.length-1;
        },
        uploadFile(f){
            var uploadovanyFileId=this.validateFile(f);

            var formData = new FormData();
            formData.append("battle",this.getBattle.battleSettings.nameSeo);

            var config={
                timeout: 1000*20,
                headers:{
                'Content-Type':'multipart/form-data',
                // 'Accept': 'application/json',
                },
                onUploadProgress: uploadEvent=>{
                    this.uploadFileList[uploadovanyFileId].uploadProcess=Math.round((uploadEvent.loaded/uploadEvent.total)*100);
                    console.log(this.uploadFileList[uploadovanyFileId].uploadProcess);
                }
            }
            
            //-----------ak presiel cez zakladny validator----
            if(this.uploadFileList[uploadovanyFileId].stav){
                this.$store.commit('setModalLoading',true);
                //--------zistime si EXIF udaje z image aby sme zistili orientaciu
                getExif(f)//----------ziskame exif z fotografie---------------
                .then((vystup)=>{//----------a ak ked z Promise ziskame exif pokracujeme dalej
                    this.uploadFileList[uploadovanyFileId].process="Converting";
                    console.log('predResize',f,uploadovanyFileId,vystup.orientation);
                    myResize(f,uploadovanyFileId)//----------okrem suboru posleme aj index pola kde sa info o subore nachadzaju
                    .then((file)=>{
                        console.log('vystup',vystup);
                        // alert('posiela na server s exif a resize '.JSON.stringify(vystup.exif)," velkost:",file.size);
                        this.uploadFileList[uploadovanyFileId].process="Loading";
                        

                        formData.append("exif",JSON.stringify(vystup.exif));
                        formData.append("orientation",vystup.orientation);
                        formData.append("file",file);
                        var noveThis=this;
                        Axios.post(this.$store.getters.URLAJAX+'/uploadPhoto.php',formData, config)
                        .then((response)=>{
                            var res=response.data;
                            if('code' in res && res.code==1){//-----------odlogovany uzivatel 
                                noveThis.$store.commit('deleteLoadedData');
                                noveThis.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                                noveThis.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                                noveThis.$router.push({path:'/active-contests'});
                            }else{
                                if(!res.return){
                                    noveThis.uploadFileList[uploadovanyFileId].text=res.returnMsg;
                                    noveThis.uploadFileList[uploadovanyFileId].stav=false;
                                    noveThis.uploadFileList[uploadovanyFileId].process="ERROR";
                                }else{
                                    noveThis.$store.commit('setBattleListphotosUploaded',{'battleId':noveThis.getBattleId,'photo':res.photo});
                                    noveThis.uploadFileList[uploadovanyFileId].text=res.returnMsg;
                                    noveThis.uploadFileList[uploadovanyFileId].stav=true;
                                    noveThis.uploadFileList[uploadovanyFileId].process="OK";
                                }
                            }
                        })
                        .catch((e)=> {
                            noveThis.uploadFileList[uploadovanyFileId].text="#82 Something went wrong";
                            noveThis.uploadFileList[uploadovanyFileId].stav=false;
                            noveThis.uploadFileList[uploadovanyFileId].process="ERROR";
                            noveThis.$store.commit('setErrorModal',{visible:true,data:"#83 Network error"+e+""});
                        })
                        .finally(function(){
                            URL.revokeObjectURL(file);
                            URL.revokeObjectURL(f);
                            setTimeout(()=>{
                                noveThis.uploadFileList[uploadovanyFileId].show=false;
                            },8000);
                        });

                    })
                    .catch((index)=>{
                        // alert('posiela na server bez resize'," velkost:",f.size);
                        this.uploadFileList[index].process="Loading";
                        formData.append("file",f);
                        var noveThis=this;
                        Axios.post(this.$store.getters.URLAJAX+'/uploadPhoto.php',formData, config)
                        .then((response)=>{
                            var res=response.data;
                            if('code' in res && res.code==1){//-----------odlogovany uzivatel 
                                noveThis.$store.commit('deleteLoadedData');
                                noveThis.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                                noveThis.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                                noveThis.$router.push({path:'/active-contests'});
                            }else{
                                if(!res.return){
                                    noveThis.uploadFileList[index].text=res.returnMsg;
                                    noveThis.uploadFileList[index].stav=false;
                                    noveThis.uploadFileList[index].process="ERROR";
                                }else{
                                    noveThis.$store.commit('setBattleListphotosUploaded',{'battleId':noveThis.getBattleId,'photo':res.photo});
                                    noveThis.uploadFileList[index].text=res.returnMsg;
                                    noveThis.uploadFileList[index].stav=true;
                                    noveThis.uploadFileList[index].process="OK";
                                }
                            }
                        })
                        .catch((e)=> {
                            noveThis.uploadFileList[index].err="#82 Something went wrong";
                            noveThis.uploadFileList[index].stav=false;
                            noveThis.uploadFileList[index].process="ERROR";
                            noveThis.$store.commit('setErrorModal',{visible:true,data:"#83 Network error"+e+""});
                        })
                        .finally(function(){
                            URL.revokeObjectURL(f);
                            setTimeout(()=>{
                                noveThis.uploadFileList[index].show=false;
                            },8000);
                        });
                    });
                })
                .finally(()=>{
                    this.$store.commit('setModalLoading',false);
                });
            }else{
                setTimeout(()=>{
                        this.uploadFileList[uploadovanyFileId].show=false;
                },118000);
            }

        },
        addFile (event) {
            event.target.files.forEach((f)=>{
                this.uploadFile(f);
            });
        },
        submitPurchasePhotoSlot(){
            console.log("clicked");
            this.purchasePhotoSlot(this.getBattleId);
            this.showInfoPanel='';
        }
    }
}
</script>
