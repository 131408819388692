<template>
    <div class="fixed inset-0 pb-20 flex flex-col justify-end bg-black bg-opacity-90" v-if="getMainMenu.actualMenu!==''" @click="menuRouter('',false)">
        <ul class="block max-w-lg mx-auto p-5 w-full">
            <li v-for="(menu,idx) in getMenuList" :key="idx+1" :class="menu.class" @click.stop="menuRouter('',menu.action)">{{menu.text}}{{zobrazPocitadlo(menu.action)}}</li>
        </ul>
    </div>
</template>
<script>
export default{
  computed:{
    getMainMenu(){
      return this.$store.getters.getMainMenu;
    },
    getMenuList(){
      return this.getMainMenu.menuList[this.getMainMenu.actualMenu];
    },
    getBattleList(){
      return this.$store.getters.getBattleList;
    },
  },
  methods:{
    menuRouter(kde,routerLink){
      this.$store.commit('setMainMenu',{'actualMenu':kde});
      if(routerLink){
        this.$router.push({path:routerLink});
      }
    },
    zobrazPocitadlo(menu){
      if(menu=="/exclusive-contests"){
        let pocidaloPaidBattles=this.getBattleList.filter((el)=>{
          let return1,return2=false;
          return1=(el.battleSettings.toBattleEnd>0 && el.battleSettings.toBattleStart<3600*24*14)===true?true:false;
          return2=el.battleSettings.paidBattle===true?true:false;
          return return1 && return2;
        }).length;
        if(pocidaloPaidBattles>0){
          return ` (${pocidaloPaidBattles})`;
        }
      }
      return '';
    }
  }
}
</script>
