<template>
  <div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
      <div class="w-11/12 max-w-md relative p-5 border-solid border-b-4 border-green-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
          <h3 class="text-center mb-5 text-green-our text-2xl">Good job</h3>
          <p class="mb-5 text-center">Good job vsetko si spravil, a teraz uz len cakat na vysledok</p>
          <button class="bg-blue-our text-lg py-2 px-5 block mx-auto" @click.prevent="closeModal()">OK</button>
        </div>
    </div>
</template>




<script>

//---------upravit tak aby to malo aj funcionalitu ako BuyExtraSelectSlots


export default {
    computed:{
        getBattleId(){
            return this.$store.getters.getUpperModal.data.battleId;
        },
    },
    methods:{
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
            this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
        },
    },
}
</script>

<style>

</style>