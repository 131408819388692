import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
        args = arguments,
        later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    },
        call_now = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (call_now) func.apply(context, args);
  };
}

function preratajBASE_SIZE(BASE_SIZE, photoBoxWidth) {
  var vyratanyPocetColumnov = photoBoxWidth / BASE_SIZE.columnWidth > 8 ? 8 : Math.floor(photoBoxWidth / BASE_SIZE.columnWidth);
  vyratanyPocetColumnov = vyratanyPocetColumnov < 1 ? 1 : vyratanyPocetColumnov;
  BASE_SIZE.columns = vyratanyPocetColumnov;
  BASE_SIZE.columnWidth = Math.floor((photoBoxWidth - Math.max(0, BASE_SIZE.columns - 1) * BASE_SIZE.gapSize) / vyratanyPocetColumnov);
  BASE_SIZE.positionY.splice(vyratanyPocetColumnov, BASE_SIZE.positionY.length - vyratanyPocetColumnov);
  return BASE_SIZE;
}

function metaTagy(tagy) {
  //---------nastavenie meta tagov
  if ('title' in tagy) {
    window.document.title = tagy.title;
  }

  if ('description' in tagy) {
    if (document.querySelector('meta[name="description"]')) {
      document.querySelector('meta[name="description"]').content = tagy.description;
    }

    if (document.querySelector('meta[property="og:description"]')) {
      document.querySelector('meta[property="og:description"]').content = tagy.description;
    }
  }
}

function checkVisible(elm) {
  //-------zisti ci sa objeck nachadza v zornom poli
  var rect = elm.getBoundingClientRect(); // var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  // console.log('rect.bottom',rect.bottom,'rect.top',rect.top,'viewHeight',viewHeight);
  // return !(rect.bottom < 0 || rect.top - viewHeight >= 0); ////chyta hornu cast

  return rect.top <= 0;
}

export { debounce, preratajBASE_SIZE, metaTagy, checkVisible };
import Axios from 'axios';
export default {
  methods: {
    getTimeRemainingWithDays: function getTimeRemainingWithDays(endtime) {
      if (endtime > 3600 * 24 * 7) {
        return {
          timeLeft: true,
          days: "SOON",
          hours: false
        };
      } else if (endtime > 3600 * 24 * 2) {
        return {
          timeLeft: true,
          days: "".concat(Math.floor(endtime / (3600 * 24)), " DAYS"),
          hours: false
        };
      } else if (endtime > 3600 * 24 * 1) {
        return {
          timeLeft: true,
          days: false,
          hours: "".concat(Math.floor(endtime / 3600), " HOUR")
        };
      } else if (endtime <= 3600 * 24 && endtime > 0) {
        var seconds = Math.floor(endtime % 60);
        var minutes = Math.floor(endtime / 60 % 60);
        var hours = Math.floor(endtime / (60 * 60) % 24);
        return {
          timeLeft: true,
          days: false,
          hours: ('0' + hours.toString()).slice(-2, -1).toString() + ('0' + hours.toString()).slice(-1).toString() + "h:" + ('0' + minutes.toString()).slice(-2, -1).toString() + ('0' + minutes.toString()).slice(-1).toString() + "m:" + ('0' + seconds.toString()).slice(-2, -1).toString() + ('0' + seconds.toString()).slice(-1).toString() + "s"
        };
      } else {
        return {
          timeLeft: false,
          days: false,
          hours: false
        };
      }
    },
    errMessage: function errMessage(msgToshow) {
      this.$store.commit('setUpperModal', {
        visible: true,
        whatToShow: 'MessageSystem',
        data: {
          msg: msgToshow
        }
      });
    },
    akMinusTakVypisNula: function akMinusTakVypisNula(hodnota) {
      if (isNaN(hodnota)) {
        return 0;
      } else if (hodnota < 0) {
        return 0;
      }

      return hodnota;
    },
    firstLoadAxios: function firstLoadAxios() {
      var _this = this;

      //----------nacita vsetky aktivne a ohlasene battle
      this.$store.commit('setModalLoading', true);
      Axios.get(this.$store.getters.URLAJAX + '/handler.php?page=first-load', {}).then(function (response) {
        var res = response.data;

        _this.$store.commit('setUserLogged', res.userData.userLogged);

        _this.$store.commit('setUserData', res.userData);

        if (res.userData.userLogged === false) {
          //-------ak uzivatel neni prihlaseny odmazene nahrane data
          _this.$store.commit('deleteLoadedData');
        }

        _this.$store.commit('setGeneralRules', res.generalRules);

        res.battleList.forEach(function (element) {
          //---------zapiseme battles
          _this.$store.commit('setBattleList', element);
        });
        res.achievements.forEach(function (element) {
          //---------zapiseme achievements
          _this.$store.commit('setAchievementsList', element);
        });
        res.userAchievements.forEach(function (element) {
          //---------zapiseme user achievements
          _this.$store.commit('setAchievementsUser', element);
        });
        res.achievementsSructure.forEach(function (element) {
          //---------zapiseme user achievements
          _this.$store.commit('setAchievementsStructure', element);
        });

        if ('blog' in res) {
          //-----------------zapiseme blog HOME PAGE
          _this.$store.commit('setBlog', res.blog);
        }

        if ('battleCount' in res) {
          //-----------------zapiseme pocty contestov
          _this.$store.commit('setBattleCount', res.battleCount);
        }

        if ('dailyGames' in res) {
          //-----------------zapiseme daily gamesi a ci su k dispozicii, pripadne ukoncene
          if ('guessWinner' in res.dailyGames) {
            _this.$store.commit('setDailyGames', {
              kde: 'guessWinner',
              co: res.dailyGames.guessWinner
            });
          }

          if ('guessBrand' in res.dailyGames) {
            _this.$store.commit('setDailyGames', {
              kde: 'guessBrand',
              co: res.dailyGames.guessBrand
            });
          }
        }

        if ('lastWinnersImages' in res) {
          //--------------zapiseme posledne vitazne fotky HOME PAGE
          _this.$store.commit('setLastWinnersImages', res.lastWinnersImages);
        }

        if ('scrollText' in res) {
          //--------------zapiseme scrolovaci text HOME PAGE
          _this.$store.commit('setScrollText', res.scrollText);
        } // }

      }).catch(function (e) {
        _this.$store.commit('setErrorModal', {
          visible: true,
          data: "Error during loading data" + e + ""
        });

        console.log(e);
      }).finally(function () {
        _this.$store.commit('setModalLoading', false);
      });
    },
    firstLoad: function firstLoad() {
      var _this2 = this;

      //----------nacita vsetky aktivne a ohlasene battle z premennej window.firstLoad
      var res = window.firstLoad;
      this.$store.commit('setUserLogged', res.userData.userLogged);
      this.$store.commit('setUserData', res.userData);

      if (res.userData.userLogged === false) {
        //-------ak uzivatel neni prihlaseny odmazene nahrane data
        this.$store.commit('deleteLoadedData');
      }

      this.$store.commit('setGeneralRules', res.generalRules);
      res.battleList.forEach(function (element) {
        //---------zapiseme battles
        _this2.$store.commit('setBattleList', element);
      });
      res.achievements.forEach(function (element) {
        //---------zapiseme achievements
        _this2.$store.commit('setAchievementsList', element);
      });
      res.userAchievements.forEach(function (element) {
        //---------zapiseme user achievements
        _this2.$store.commit('setAchievementsUser', element);
      });
      res.achievementsSructure.forEach(function (element) {
        //---------zapiseme user achievements
        _this2.$store.commit('setAchievementsStructure', element);
      });

      if ('blog' in res) {
        //-----------------zapiseme blog
        this.$store.commit('setBlog', res.blog);
      }

      if ('battleCount' in res) {
        //-----------------zapiseme pocty contestov
        this.$store.commit('setBattleCount', res.battleCount);
      }

      if ('dailyGames' in res) {
        //-----------------zapiseme daily gamesi a ci su k dispozicii, pripadne ukoncene
        if ('guessWinner' in res.dailyGames) {
          this.$store.commit('setDailyGames', {
            kde: 'guessWinner',
            co: res.dailyGames.guessWinner
          });
        }

        if ('guessBrand' in res.dailyGames) {
          this.$store.commit('setDailyGames', {
            kde: 'guessBrand',
            co: res.dailyGames.guessBrand
          });
        }
      }

      if ('lastWinnersImages' in res) {
        //--------------zapiseme posledne vitazne fotky 
        this.$store.commit('setLastWinnersImages', res.lastWinnersImages);
      }

      if ('scrollText' in res) {
        //--------------zapiseme scrolovaci text HOME PAGE
        this.$store.commit('setScrollText', res.scrollText);
      }
    },
    vypisUrlPrePhoto: function vypisUrlPrePhoto(fotkaNazovSuboru, battleObject, velkost) {
      var idUser = this.$store.getters.vypisIdUzivatela(battleObject.id);
      return this.userPhotoUrl(idUser, fotkaNazovSuboru, battleObject, velkost);
    },
    vypisVelkostFotkyPreRozlisenie: function vypisVelkostFotkyPreRozlisenie(rozlisenie) {
      if (rozlisenie) {
        var windowScreenWidth = window.innerWidth - 20;
        var windowScreenHeight = window.innerHeight - 20;

        if (rozlisenie.wThumb * 1.1 >= windowScreenWidth || rozlisenie.hThumb * 1.1 >= windowScreenHeight) {
          return {
            "size": "t",
            "w": rozlisenie.wThumb,
            "h": rozlisenie.hThumb
          };
        } else if (rozlisenie.wMiddle * 1.1 >= windowScreenWidth || rozlisenie.hMiddle * 1.1 >= windowScreenHeight) {
          return {
            "size": "m",
            "w": rozlisenie.wMiddle,
            "h": rozlisenie.hMiddle
          };
        } else {
          return {
            "size": "l",
            "w": rozlisenie.w,
            "h": rozlisenie.h
          };
        }
      } else {
        return {
          "size": "m",
          "w": 1280,
          "h": 720
        };
      }
    },
    purchasePhotoSlot: function purchasePhotoSlot(battleId) {
      var _this3 = this;

      var battle = this.$store.getters.getBattle(battleId);
      var getUserData = this.$store.getters.getUserData;

      if (battle.generalSettings.photoSlotPrice > getUserData.actualCredits) {
        this.$store.commit('setErrorModal', {
          visible: true,
          data: "#48 You have " + getUserData.actualCredits + " credits left"
        });
        return;
      }

      this.$store.commit('setModalLoading', true);
      var udaje = {
        'battle': battle.battleSettings.nameSeo
      };
      Axios.post(this.$store.getters.URLAJAX + '/handler.php?page=upload-slot-purchase', udaje).then(function (response) {
        var res = response.data;

        if ('code' in res && res.code == 1) {
          //-----------odlogovany uzivatel
          _this3.$store.commit('deleteLoadedData');

          _this3.$store.commit('setUpperModal', {
            visible: false,
            whatToShow: '',
            data: {}
          });

          _this3.$store.commit('setLowerModal', {
            visible: false,
            whatToShow: '',
            data: {}
          });

          _this3.$router.push({
            path: '/active-contests'
          });
        } else {
          if (res.return) {
            //--------generalSettings photoSlotPrice;
            var udaje = {
              'battleId': battleId,
              'property': 'photoSlotPrice',
              'value': res.photoSlotPrice
            };

            _this3.$store.commit('setBattleListgeneralSettings', udaje); //--------paidServices photoSlots;


            udaje = {
              'battleId': battleId,
              'property': 'photoSlots',
              'value': parseInt(res.photoSlots)
            };

            _this3.$store.commit('setBattleListpaidServices', udaje); //------zapiseme pocet aktualnych kreditov uzivatela


            if ('actualCredits' in res) {
              _this3.$store.commit('setUserData', {
                'actualCredits': parseInt(res.actualCredits)
              });
            }
          } else {
            _this3.$store.commit('setErrorModal', {
              visible: true,
              data: res.returnMsg
            }); //------zapiseme pocet aktualnych kreditov uzivatela


            if ('actualCredits' in res) {
              _this3.$store.commit('setUserData', {
                'actualCredits': parseInt(res.actualCredits)
              });
            }
          }
        }
      }).catch(function (e) {
        _this3.$store.commit('setErrorModal', {
          visible: true,
          data: "#49 Network error" + e + ""
        });
      }).finally(function () {
        _this3.$store.commit('setModalLoading', false);
      });
    },
    hasJsonStructure: function hasJsonStructure(str) {
      if (typeof str !== 'string') return false;

      try {
        var result = JSON.parse(str);
        var type = Object.prototype.toString.call(result);
        return type === '[object Object]' || type === '[object Array]';
      } catch (err) {
        return false;
      }
    },
    isJson: function isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        //Error
        //JSON is not okay
        return false;
      }

      return true;
    },
    validateEmail: function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateUrl: function validateUrl(str) {
      var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

      if (regexp.test(str)) {
        return true;
      } else {
        return false;
      }
    }
  }
};