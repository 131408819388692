<template>
  <div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
      <div class="w-11/12 max-w-sm relative p-5 border-solid border-b-4 border-green-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <!-- <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span> -->
          <h3 class="mb-5 text-center text-white-our text-2xl">How contests works</h3>
          <img :src="`${getURLsite}/pictures/active_contests.gif`" alt="How contests works" class="w-full h-auto">
          
          <button v-if="countdown>0" class="bg-gray-dark text-center text-lg text-white py-2 w-full my-5" disabled>close <span class="text-sm">({{countdown}})</span></button>
          <button v-else class="bg-green-our text-center text-lg text-white py-2 w-full my-5" @click.prevent="closeModal()">close</button>
        </div>
    </div>
</template>

<script>
export default {
    created(){
        this.startTimer();
    },
    data(){
        return{
            countdown:8,
        }
    },
    methods:{
        startTimer(){
            if (this.countdown>0) {
                this.t = setInterval(() => {
                    this.countdown--;
                }, 1000)
            } else {
                clearInterval(this.t)
            }
        },
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
    },
    computed:{
        getURLsite(){
            return this.$store.getters.URLSITE;
        },
    }
}
</script>

<style>

</style>