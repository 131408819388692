<template>
<div class="fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80 ">
  <div class="w-11/12 max-w-xs relative p-5 border-solid border-b-4 border-red-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
    <div class="font-bold text-red-our text-lg">SET NEW PASSWORD<span class="button-close-x" title="Close Modal" @click="close()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span></div>
    <form>
      <div v-if="errorText.length>0" class="my-2 text-red-our text-center">{{errorText}}</div>
      <div v-if="okText.length>0" class="my-2 text-green-our text-center" v-html="okText"></div>
      <div class="py-2" v-if="!success">
        <label for="upsw"></label><input type="password" class="bg-black border border-gray-400 border-solid inline-block mx-0 my-1 px-5 py-3 text-white w-full box-border" placeholder="Enter new Password" name="upsw" minlength="6" autocomplete="current-password" ref="upsw" @keyup="checkPass()" required>
        <label for="upsw1"></label><input type="password" class="bg-black border border-gray-400 border-solid inline-block mx-0 my-1 px-5 py-3 text-white w-full box-border" placeholder="Enter new Password again" name="upsw1" minlength="6" autocomplete="current-password" ref="upsw1" @keyup="checkPass()" required>
        <button type="submit" class="bg-red-our text-white text-center py-3 px-5 w-full mt-4" @click.prevent="setNewPassword()">SET NEW PASSWORD</button>
      </div>
      <div v-else class="pt-4 flex justify-center items-center">
          <button type="button" class="bg-green-our text-sm py-3 px-5 cursor-pointer" @click.prevent="prepniNa('LoginPanel')">LOG IN</button>
      </div>
    </form>
  </div>
</div>
</template>

<script>
import Axios from 'axios'
export default {
  emits:['closeModal'],
  data(){
    return{
      errorText:"",
      okText:"",
      success:false
    }
  },
  computed:{
    getResetCode(){
        return this.$store.getters.getUpperModal.data.code;
    },
  },
  methods:{
    close(){
      this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
    },
    prepniNa(naCoMaPrepnut){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:naCoMaPrepnut,data:{}});
    },
    checkPass(){
      this.errorText="";
    },
    setNewPassword(){
      let upsw1=this.$refs.upsw1;
      let upsw=this.$refs.upsw;
      let code=this.getResetCode;
      if(code.length<10){
        this.errorText="Reset code not match, please check link from email."
        return;
      }
      if(upsw.value.length<6){
          this.errorText="New password is too short or doesnt match!"
          return;
      }
      if(upsw.value!==upsw1.value){
          this.errorText="New password is too short or doesnt match!"
          return;
      }
      var udaje={'code':code,'newPassword':upsw.value,'newPasswordAgain':upsw1.value};
      this.$store.commit('setModalLoading',true);
      Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=forget-password-reset", udaje)
      .then((response)=>{
          var res=response.data;
          if(res.return===false){
              this.errorText=res.returnMsg;
              this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
          }else if(res.return===true){
              this.success=true;
              this.okText=res.returnMsg;
          }
      })
      .catch((e)=> {
          this.$store.commit('setErrorModal',{visible:true,data:"#131 Network error"+e+""});
      })
      .finally(()=>{
          this.$store.commit('setModalLoading',false);
      });

  },
  }
}
</script>

