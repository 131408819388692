export default {
  data: function data() {
    return {
      howToPlayTabActive: 1
    };
  },
  methods: {
    closeModal: function closeModal() {
      this.$store.commit('setUpperModal', {
        visible: false,
        whatToShow: '',
        data: {}
      });
    },
    howToPlayTabChange: function howToPlayTabChange(id) {
      this.howToPlayTabActive = id;
    },
    howToPlayTabGetClass: function howToPlayTabGetClass(idTab) {
      if (idTab === this.howToPlayTabActive) {
        return {
          "cursor-pointer": false,
          "bg-pink-our": true,
          "bg-gray-500": false
        };
      } else {
        return {
          "cursor-pointer": true,
          "bg-pink-our": false,
          "bg-gray-500": true
        };
      }
    },
    howToPlayTabHidden: function howToPlayTabHidden(idTab) {
      if (idTab === this.howToPlayTabActive) {
        return {
          "hidden": false
        };
      } else {
        return {
          "hidden": true
        };
      }
    }
  }
};