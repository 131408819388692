<template>
  <div class="fixed left-0 top-0 w-full overflow-auto h-full flex justify-center items-top bg-black z-lowerModal">
      <div class="w-full h-full relative overflow-y-auto">
            <span class="button-close-x" title="Close Modal" @click.prevent="closeModalRoute()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
            <h1 class="text-center text-2xl pt-4">Guess what brand</h1>
            <div class="max-w-2xl mx-auto">Use your experience or best guess to pick what brand camera took the following photos and earn <span class="text-orange-our font-bold">1 FREE credit</span> for 3 correct answers.</div>
        <div v-if="getGameResult">
            <div v-if="getDailyGames.guessBrand.result.success" class="bg-orange-our max-w-2xl my-4 mx-auto py-2 text-center text-shadow1px text-xl">Perfect !!!! You have won 1 CREDIT 👍</div>
            <div v-else class="bg-gray-600 max-w-2xl my-4 mx-auto py-2 text-center text-shadow1px text-xl">Better luck tomorrow</div>
            <div class="flex flex-wrap justify-around justify-center max-w-7xl mx-auto">
                <div class="relative m-1 w-72 h-72" v-for="(photo,index) in getDailyGames.guessBrand.photoList" :key="index">
                    <img :src="$store.getters.URLPICTURES+'/_blank2x2.png'" class="w-full h-full bg-center bg-no-repeat bg-cover" :style="getBgImageStyle(photo.photoPath)">
                    <img :src="$store.getters.URLPICTURES+'/zoom-in.svg'" class="absolute top-3 left-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.stop="modalZoomInImage(photo)">
                    <ul class="flex flex-col justify-around absolute right-0 top-0 bottom-0">
                        <template v-for="(brand,index1) in getDailyGames.guessBrand.brands" :key="index1">
                            <li v-if="getResult(index).selected==brand && getResult(index).success" class="m-1 py-1 px-3 bg-green-our bg-opacity-80 border-2 border-solid border-gray-400 select-none"><span class="uppercase">{{brand}}</span></li>
                            <li v-else-if="getResult(index).selected==brand && !getResult(index).success" class="m-1 py-1 px-3 bg-red-our bg-opacity-80 border-2 border-solid border-gray-400 select-none"><span class="uppercase">{{brand}}</span></li>
                            <li v-else-if="getResult(index).correct==brand" class="m-1 py-1 px-3 bg-green-our bg-opacity-80  border-2 border-solid border-gray-400 select-none"><span class="uppercase">{{brand}}</span></li>
                            <li v-else class="m-1 py-1 px-3 bg-black bg-opacity-50 border-2 border-solid border-gray-400 select-none"><span>{{brand}}</span></li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else-if="getDailyGames.guessBrand.settings.active && 'photoList' in getDailyGames.guessBrand" class="">
            <div class="flex flex-wrap justify-around justify-center max-w-7xl mx-auto">
                <div class="relative m-1 w-72 h-72 sm:w-96 sm:h-96 " v-for="(photo,index) in getDailyGames.guessBrand.photoList" :key="index">
                    <img :src="$store.getters.URLPICTURES+'/_blank2x2.png'" class="w-full h-full bg-center bg-no-repeat bg-cover" :style="getBgImageStyle(photo.photoPath)">
                    <img :src="$store.getters.URLPICTURES+'/zoom-in.svg'" class="absolute top-3 left-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.stop="modalZoomInImage(photo)">
                    <ul class="flex flex-col justify-around absolute right-0 top-0 bottom-0">
                        <template v-for="(brand,index1) in getDailyGames.guessBrand.brands" :key="index1">
                        <li v-if="getSelected[index]==brand" class="m-1 py-1 px-3 bg-black bg-opacity-80 border-2 border-solid border-gray-400 select-none" @click="select(index,brand)"><span class="uppercase">{{brand}}</span></li>
                        <li v-else class="m-1 py-1 px-3 bg-black bg-opacity-50 hover:bg-opacity-80 border-2 border-solid border-gray-400 cursor-pointer select-none" @click="select(index,brand)"><span class="uppercase">{{brand}}</span></li>
                        </template>
                    </ul>
                </div>
            </div>
            <div v-if="submitButton" class="bg-blue-our cursor-pointer mx-auto my-4 py-2 rounded-sm text-center text-lg text-white w-64" @click.prevent="checkGame()">SEND</div>
            <div v-else class="bg-gray-600 mx-auto my-4 py-2 rounded-sm text-center text-lg text-white w-64">SEND</div>
        </div>
        <div v-else class="text-center text-red-our py-20">
            Guess what brand is not active right now
        </div>
      </div>
  </div>
</template>
<script>
import Axios from 'axios';
export default {
    created(){
        if('brands' in this.getDailyGames.guessBrand && 'photoList' in this.getDailyGames.guessBrand){
            this.voidFunc();
        }else{
            this.loadGames();
        }
    },
    data(){
        return{
            selected:{
                photo1:false,
                photo2:false,
                photo3:false
            }
        }
    },
    computed:{
        getDailyGames(){
            return this.$store.getters.getDailyGames;
        },
        submitButton(){
            if(this.selected.photo1 && this.selected.photo2 && this.selected.photo3){
                return true;
            }
            return false;
        },
        getSelected(){
            return this.selected;
        },
        getGameResult(){
            if('result' in this.getDailyGames.guessBrand){
                return true;
            }
            return false;
        }
    },
    methods:{
        voidFunc(){
            return true;
        },
        vypisPair(par){
            return "pair"+par;
        },
        vypisFotka(cislo){
            return "photo"+(cislo);
        },
        select(index,brand){
            this.selected[index]=brand;
        },
        getResult(index){
            return {
                success:this.getDailyGames.guessBrand.result[index],
                correct:this.getDailyGames.guessBrand.result[index+'correct'],
                selected:this.getDailyGames.guessBrand.result[index+'selected'],
            }
        },
        getBgImageStyle(url){
            return {'background-image':`url(${url}_m.jpg)`}
        },
        closeModalRoute(){
            this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
        },
        loadGames(){
            // var udaje={'battle':this.getBattleSeo};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=load-daily-games',{})
            .then((response) => {
                var res=response.data;
                if('dailyGames' in res){
                    if('guessBrand' in res.dailyGames){
                        this.$store.commit('setDailyGames',{kde:'guessBrand',co:res.dailyGames.guessBrand});
                    }
                    if('guessWinner' in res.dailyGames){
                        this.$store.commit('setDailyGames',{kde:'guessWinner',co:res.dailyGames.guessWinner});
                    }
                }
                })
            .catch(() => {
                this.$store.commit('setErrorModal',{visible:true,data:"#171 Error during loading daily games"});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        },
        checkGame(){
            let udaje={'idGame':this.getDailyGames.guessBrand.settings.idGame};
                udaje.photo1=this.selected.photo1;
                udaje.photo2=this.selected.photo2;
                udaje.photo3=this.selected.photo3;
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=check-daily-games&game=guessBrand',udaje)
            .then((response) => {
                var res=response.data;
                if(res.return){
                    if('actualCredits' in res){
                        this.$store.commit('setUserData',{'actualCredits':parseInt(res.actualCredits)});
                    }
                    if('creditList' in res){
                        this.$store.commit('deleteUserCreditList');
                        this.$store.commit('setUserData',{'creditList':res.creditList});
                    }
                    if('creditListTotal' in res){
                        this.$store.commit('setUserData',{'creditListTotal':parseInt(res.creditListTotal)});
                    }

                    if('products' in res){
                        this.$store.commit('setUserData',{'products':res.products});
                    }

                    if('result' in res){
                        this.$store.commit('setDailyGames',{kde:'guessBrand.result',co:res.result});
                    }
                }else{
                    this.$store.commit('setErrorModal',{visible:true,data:"#173 Error in result check"});
                }
            })
            .catch(() => {
                this.$store.commit('setErrorModal',{visible:true,data:"#172 Error during loading daily games"});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
                // console.log(this.$store.getters.battleObject(this.battleUdaje.id));
            });
        },
        modalZoomInImage(photo){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{
            photoPath:photo.photoPath,
            photoFileName:photo.photoFileName,
            exif:false,
            size:photo.size,
            isAdult:0,
            idPhoto:photo.id,
            }}); 
        },
    }
}
</script>