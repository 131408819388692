<template>
    <div class="z-upperModal fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 p-3">
        <img :src="$store.getters.URLPICTURES+'/submited.svg'" class="w-24 h-24 block animationGetBigger">
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>