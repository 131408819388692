<template>
    <div class="mt-2 border-b border-dashed border-white">
        <h3 class="font-bold cursor-pointer text-lg">{{faq.name}}</h3>
        <p>{{faq.text}}</p>
    </div>
</template>
<script>
export default {
    props:['faq'],
}
</script>