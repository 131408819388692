<template>
<div class="fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
  <div class="w-11/12 max-w-xs relative p-5 border-solid border-b-4 border-orange-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
    <div class="font-bold text-orange-our text-lg">PAYMENT STATUS<span class="button-close-x" title="Close Modal" @click="close()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span></div>
      <div v-if="errorText.length>0" class="my-2 text-red-our text-center">{{errorText}}</div>
      <div v-if="paymentText.length>0" class="my-2 text-green-our text-center" v-html="paymentText"></div>
  </div>
</div>
</template>

<script>
import Axios from 'axios'
import functions from '../functions'

export default {
  mixins:[functions],
  data(){
    return{
      errorText:"",
      paymentText:"",
    }
  },
  created(){
    this.CheckPaymentStatus();
  },
  computed:{
    getPaymentId(){
        return parseInt(this.$store.getters.getUpperModal.data.id);
    },
  },
  methods:{
    close(){
      this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
    },
    CheckPaymentStatus(){
      this.signUpText="";
      this.errorText="";

      if(isNaN(this.getPaymentId)){
        this.errorText="Payment ID is not valid"
        return;
      }
      this.$store.commit('setModalLoading',true);
      Axios.post(this.$store.getters.URLAJAX+'/paymentStatus.php',{'id':this.getPaymentId})
      .then((response) => {
          if(response.data.return){
              if('actualCredits' in response.data && 'creditList' in response.data && 'creditListTotal' in response.data && 'products' in response.data){
                  this.$store.commit('deleteUserCreditList');
                  this.$store.commit('setUserData',{
                      'actualCredits':parseInt(response.data.actualCredits),
                      'creditList':response.data.creditList,
                      'creditListTotal':parseInt(response.data.creditListTotal),
                      'products':response.data.products,
                  });
              }
              this.paymentText=response.data.returnMsg;
          }else{
              this.errorText=response.data.returnMsg;
          }
        })
      .catch((e) => {
        this.$store.commit('setErrorModal',{visible:true,data:"#150 Network error"+e+""});
      })
      .finally(()=>{
        this.$store.commit('setModalLoading',false);
      });
    }
  }
}
</script>

