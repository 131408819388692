<template>
  <div class="z-upperModal fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 p-3">
      <div class="w-11/12 max-w-md relative p-5 border-solid border-b-4 border-blue-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
            <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
          <h3 class="text-center mb-5 text-blue-our text-2xl">Ziadne fotky na vote</h3>
          <p class="my-3">ZIadne fotky na vote, treba najprv selectovat</p>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
            this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
        },
    }
}
</script>

<style>

</style>