<template>
  <div class="fixed left-0 top-0 w-full overflow-auto h-full flex justify-center items-top bg-black z-lowerModal">
      <div class="w-full h-full relative overflow-y-auto">
            <span class="button-close-x" title="Close Modal" @click.prevent="closeModalRoute()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
            <h1 class="text-center text-2xl pt-4">Guess a winner</h1>
            <div class="max-w-2xl mx-auto">For each pair guess which photo got the higher score. Get <span class="text-orange-our font-bold">1 FREE credit</span> for matching all three.</div>
        <div v-if="getGameResult">
            <div v-if="getDailyGames.guessWinner.result.success" class="bg-orange-our max-w-2xl mt-4 mx-auto py-2 text-center text-shadow1px text-xl">Perfect !!!! You have won 1 CREDIT 👍</div>
            <div v-else class="bg-gray-600 max-w-2xl my-4 mx-auto py-2 text-center text-shadow1px text-xl">Better luck tomorrow</div>
            <template v-for="par in 3" :key="par">
            <div class="max-w-2xl mt-6 mx-auto">
                <h3 class="font-bold py-2 text-center text-lg text-white" :class="resultColorHeader(getDailyGames.guessWinner.result[vypisPair(par)])">From contest "{{getDailyGames.guessWinner.photoPairs[vypisPair(par)].name}}"</h3>
                <div class="flex justify-evenly">
                    <div v-for="fotka in 2" :key="fotka" class="w-1/2" :class="vygenerujPadding(fotka)">
                        <div class="relative">
                            <figure class="relative cursor-pointer">
                                <img :src="$store.getters.URLPICTURES+'/_blank2x2.png'" class="w-full h-auto bg-center bg-no-repeat bg-cover" :style="getBgImageStyle(getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].photoPath)" :class="{'opacity-0':isAdult(getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].isAdult)}">
                            </figure>
                            <img :src="$store.getters.URLPICTURES+'/zoom-in.svg'" class="mobileHidden absolute top-3 right-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.stop="modalZoomInImage(par,getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)])">
                            <template  v-if="getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].selected && getDailyGames.guessWinner.result[vypisPair(par)]">
                                <img :src="$store.getters.URLPICTURES+'/select_photo_ok.svg'" class="-translate-x-1/2 -translate-y-1/2 absolute cursor-pointer h-32 left-1/2 top-1/2 transform w-32" title="You selected this photo">
                            </template>
                            <template v-else-if="getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].selected && !getDailyGames.guessWinner.result[vypisPair(par)]">
                                <img :src="$store.getters.URLPICTURES+'/select_photo_fail.svg'" class="-translate-x-1/2 -translate-y-1/2 absolute cursor-pointer h-32 left-1/2 top-1/2 transform w-32" title="You selected this photo">
                            </template>


                            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" v-if="isAdult(getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].isAdult)">
                                <span class="cursor-pointer bg-gray-600 py-1 px-3 noWrap block mb-1 text-center" title="Show this adult photo" @click="showThisPhoto(par,fotka,0)">SHOW THIS ADULT PHOTO</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </template>            
        </div>
        <div v-else-if="getDailyGames.guessWinner.settings.active && 'photoList' in getDailyGames.guessWinner">
            <template v-for="par in 3" :key="par">
            <div class="max-w-4xl mt-6 mx-auto">
                <h3 class="bg-gray-dark font-bold py-2 text-center text-lg text-white">{{getDailyGames.guessWinner.photoPairs[vypisPair(par)].name}}</h3>
                <div class="flex justify-evenly">
                    <div v-for="fotka in 2" :key="fotka" class="w-1/2" :class="vygenerujPadding(fotka)">
                        <div class="relative">
                            <figure class="relative cursor-pointer" @mousedown.stop="select(par,fotka)">
                                <img :src="$store.getters.URLPICTURES+'/_blank2x2.png'" class="w-full h-auto bg-center bg-no-repeat bg-cover" :style="getBgImageStyle(getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].photoPath)" :class="{'opacity-0':isAdult(getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].isAdult)}">
                            </figure>
                            <img :src="$store.getters.URLPICTURES+'/zoom-in.svg'" class="mobileHidden absolute top-3 right-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.stop="modalZoomInImage(par,getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)])">
                            
                            <img v-if="getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].selected" :src="$store.getters.URLPICTURES+'/select_photo.svg'" class="-translate-x-1/2 -translate-y-1/2 absolute cursor-pointer h-32 left-1/2 top-1/2 transform w-32" title="You selected this photo">

                            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" v-if="isAdult(getDailyGames.guessWinner.photoList[vypisFotka(par,fotka)].isAdult)">
                                <span class="cursor-pointer bg-gray-600 py-1 px-3 noWrap block mb-1 text-center" title="Show this adult photo" @click="showThisPhoto(par,fotka,0)">SHOW THIS ADULT PHOTO</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </template>
            <div v-if="submitButton" class="bg-blue-our cursor-pointer mx-auto my-4 py-2 rounded-sm text-center text-lg text-white w-64" @click.prevent="checkGame()">SEND</div>
            <div v-else class="bg-gray-600 mx-auto my-4 py-2 rounded-sm text-center text-lg text-white w-64">SEND</div>
        </div>
        <div v-else class="text-center text-red-our py-20">
            Guess a winner is not active right now
        </div>
      </div>
  </div>
</template>
<script>
import Axios from 'axios';
export default {
    created(){
        if('photoPairs' in this.getDailyGames.guessWinner && 'photoList' in this.getDailyGames.guessWinner){
            this.voidFunc();
        }else{
            this.loadGames();
        }
    },
    computed:{
        getDailyGames(){
            return this.$store.getters.getDailyGames;
        },
        submitButton(){
            if('photoList' in this.getDailyGames.guessWinner){
                if((this.getDailyGames.guessWinner.photoList.photo1.selected || this.getDailyGames.guessWinner.photoList.photo2.selected) &&
                    (this.getDailyGames.guessWinner.photoList.photo3.selected || this.getDailyGames.guessWinner.photoList.photo4.selected) && 
                    (this.getDailyGames.guessWinner.photoList.photo5.selected || this.getDailyGames.guessWinner.photoList.photo6.selected)){
                    return true;
                }
            }
            return false;
        },
        getGameResult(){
            if('result' in this.getDailyGames.guessWinner){
                return true;
            }
            return false;
        }
    },
    methods:{
        voidFunc(){
            return true;
        },
        vypisPair(par){
            return "pair"+par;
        },
        vypisFotka(par,fotka){
            if(par==1){
                return "photo"+(par+fotka-1);
            }else if(par==2){
                return "photo"+(par+fotka);
            }else{
                return "photo"+(par+1+fotka);
            }
        },
        select(par,fotka){
            //----------setneme fotky v pare na false
            if(par==1){
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo1.selected",co:false});
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo2.selected",co:false});
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo"+(par+fotka-1)+".selected",co:true});
            }else if(par==2){
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo3.selected",co:false});
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo4.selected",co:false});
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo"+(par+fotka)+".selected",co:true});
            }else{
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo5.selected",co:false});
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo6.selected",co:false});
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo"+(par+1+fotka)+".selected",co:true});
            }
        },
        getBgImageStyle(url){
            return {'background-image':`url(${url}_t.jpg)`}
        },
        vygenerujPadding(fotka){
            if(fotka==1){
                return {'pr-1':true}
            }else{
                return {'pl-1':true}
            }
        },
        ifOdd(num){
            let vysledok=num % 2;
            return vysledok?true:false;
        },
        isAdult(isAdult){
            if(parseInt(isAdult)==1){
                return true
            }
            return false
        },
        showThisPhoto(par,fotka,value){
            if(par==1){
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo"+(par+fotka-1)+".isAdult",co:value});
            }else if(par==2){
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo"+(par+fotka)+".isAdult",co:value});
            }else{
                this.$store.commit('setDailyGames',{kde:"guessWinner.photoList.photo"+(par+1+fotka)+".isAdult",co:value});
            }
        },
        resultColorHeader(res){
            if(res){
                return "bg-green-our"
            }else{
                return "bg-red-our"
            }
        },
        closeModalRoute(){
            this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
        },
        loadGames(){
            // var udaje={'battle':this.getBattleSeo};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=load-daily-games',{})
            .then((response) => {
                var res=response.data;
                if('dailyGames' in res){
                    if('guessBrand' in res.dailyGames){
                        this.$store.commit('setDailyGames',{kde:'guessBrand',co:res.dailyGames.guessBrand});
                    }
                    if('guessWinner' in res.dailyGames){
                        this.$store.commit('setDailyGames',{kde:'guessWinner',co:res.dailyGames.guessWinner});
                    }
                }
                })
            .catch(() => {
                this.$store.commit('setErrorModal',{visible:true,data:"#163 Error during loading daily games"});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
                // console.log(this.$store.getters.battleObject(this.battleUdaje.id));
            });
        },
        checkGame(){
            let udaje={'idGame':this.getDailyGames.guessWinner.settings.idGame};
            if(this.getDailyGames.guessWinner.photoList.photo1.selected){
                udaje.pair1=parseInt(this.getDailyGames.guessWinner.photoList.photo1.idPhoto);
            }
            if(this.getDailyGames.guessWinner.photoList.photo2.selected){
                udaje.pair1=parseInt(this.getDailyGames.guessWinner.photoList.photo2.idPhoto);
            }
            if(this.getDailyGames.guessWinner.photoList.photo3.selected){
                udaje.pair2=parseInt(this.getDailyGames.guessWinner.photoList.photo3.idPhoto);
            }
            if(this.getDailyGames.guessWinner.photoList.photo4.selected){
                udaje.pair2=parseInt(this.getDailyGames.guessWinner.photoList.photo4.idPhoto);
            }
            if(this.getDailyGames.guessWinner.photoList.photo5.selected){
                udaje.pair3=parseInt(this.getDailyGames.guessWinner.photoList.photo5.idPhoto);
            }
            if(this.getDailyGames.guessWinner.photoList.photo6.selected){
                udaje.pair3=parseInt(this.getDailyGames.guessWinner.photoList.photo6.idPhoto);
            }

            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=check-daily-games&game=guessWinner',udaje)
            .then((response) => {
                var res=response.data;
                if(res.return){
                    if('actualCredits' in res){
                        this.$store.commit('setUserData',{'actualCredits':parseInt(res.actualCredits)});
                    }
                    if('creditList' in res){
                        this.$store.commit('deleteUserCreditList');
                        this.$store.commit('setUserData',{'creditList':res.creditList});
                    }
                    if('creditListTotal' in res){
                        this.$store.commit('setUserData',{'creditListTotal':parseInt(res.creditListTotal)});
                    }

                    if('products' in res){
                        this.$store.commit('setUserData',{'products':res.products});
                    }

                    if('result' in res){
                        this.$store.commit('setDailyGames',{kde:'guessWinner.result',co:res.result});
                    }
                }else{
                    this.$store.commit('setErrorModal',{visible:true,data:"#170 Error in result check"});
                }
            })
            .catch(() => {
                this.$store.commit('setErrorModal',{visible:true,data:"#163 Error during loading daily games"});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
                // console.log(this.$store.getters.battleObject(this.battleUdaje.id));
            });
        },
        modalZoomInImage(par,photo){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{
            photoPath:photo.photoPath,
            photoFileName:photo.photoFileName,
            exif:false,
            size:photo.size,
            isAdult:photo.isAdult,
            idPhoto:photo.id,
            battleName:this.getDailyGames.guessWinner.photoPairs["pair"+par].name,
            }}); 
        },
    }
}
</script>