<template>
<div class="fixed inset-0 z-upperModal flex justify-center items-baseline bg-black bg-opacity-90" @click.prevent="zavriModal()">
    <div class="bg-gradient-to-b from-black  to-gray-dark w-11/12 max-w-lg relative p-5 mt-24 mx-auto text-justify shadow-glowGray">
        <span class="button-close-x" @click.prevent="zavriModal()" title="Close Modal"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>

        <h3 class="text-center text-white mb-4 text-2xl">General rules</h3>
        
        <ul class="mx-auto mt-5 mb-2 w-full box-border">
            <li class="border-dashed border-b border-gray-400 p-1" v-for="(generalRule,index) in generalRules" :key="index" v-html="generalRule"></li>
            <li class="border-dashed border-b border-orange-our p-1">By entering this battle you accept the <span class="text-orange-our cursor-pointer" @click="temsAndConditions()">terms and conditions</span></li>
        </ul>
    </div>

</div>
</template>

<script>
export default {
    methods:{
        zavriModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
        temsAndConditions(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
            this.$router.push('/terms-and-conditions');
        }
    },
    computed:{
        generalRules(){
            return this.$store.getters.getGeneralRules;
        },
    },
}
</script>