import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
// import App2 from './App2.vue'
// import App3 from './App3.vue'
// import App4 from './App4.vue'
import store from './store'
import './index.css'

import Axios from 'axios'
Axios.defaults.timeout=1000*20;
Axios.defaults.headers['Content-type']="application/json";
Axios.defaults.headers['Accept']="application/json";

const ExclusiveBattles = () => import('./pages/ExclusiveBattles.vue')
const UpcomingBattles = () => import('./pages/UpcomingBattles.vue')
const ActualBattles = () => import('./pages/ActualBattles.vue')
const FinishedBattles = () => import('./pages/FinishedBattles.vue')
const UserProfile = () => import('./pages/UserProfile.vue')
const DailyGames = () => import('./pages/DailyGames.vue')
const Achievements = () => import('./pages/Achievements.vue')


// import ExclusiveBattles from './pages/ExclusiveBattles.vue';
// import UpcomingBattles from './pages/UpcomingBattles.vue';
// import ActualBattles from './pages/ActualBattles.vue';
// import FinishedBattles from './pages/FinishedBattles.vue';
// import UserProfile from './pages/UserProfile.vue';
// import DailyGames from './pages/DailyGames.vue';
// import Achievements from './pages/Achievements.vue';
import HomePage from './pages/HomePage.vue';
import NotFound from './pages/NotFound.vue';
import MyAccount from './pages/MyAccount.vue';
import MyCredit from './pages/MyCredit.vue';
import BestOf10 from './pages/BestOf10.vue';
import BestOf10Photo from './pages/BestOf10Photo.vue';
import BestOf10Vote from './pages/BestOf10Vote.vue';
import Faq from './pages/Faq.vue';
import Contact from './pages/Contact.vue';
import TermsAndConditions from './pages/TermsAndConditions.vue';
import LogOut from './pages/LogOut.vue';

import GAuth from 'vue3-google-oauth2'
const gAuthOptions = { clientId: '518434061232-bgdgdee484e7c9ba2sijmg6tdvdgfha3.apps.googleusercontent.com', scope: 'profile email', prompt: 'select_account', fetch_basic_profile: true }



const router=createRouter({
    history:createWebHistory(),
    routes:[
        {
          path:'/exclusive-contests',component:ExclusiveBattles,
          meta:{requiresAuth:false,
            title: 'Captain Focus list of exclusive photo challenges.',
            metaTags: [
              {name: 'description',content: 'Use your credits to enter and win prizes. These exclusive photo battles are independent  and do not affect regular photography competittions scoreboard.'
              },{property: 'og:description',content: 'Use your credits to enter and win prizes. These exclusive photo battles are independent  and do not affect regular photography competittions scoreboard.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/upcoming-contests',component:UpcomingBattles,
          meta:{requiresAuth:false,
            title: 'Upcoming contests'}
        },
        {
          path:'/active-contests',component:ActualBattles,
          meta:{
              requiresAuth:false,
              title: 'Captain Focus recent active photo contests.',
            metaTags: [
              {name: 'description',content: 'List of recent photo contests you can enter on Captain Focus photography competition webite.'
              },{property: 'og:description',content: 'List of recent photo contests you can enter on Captain Focus photography competition webite.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/finished-contests/:action/:battleSeo',component:FinishedBattles,
          meta:{requiresAuth:false,
            title: 'Captain Focus list of finished photo contests and results.',
            metaTags: [
              {name: 'description', content: 'See the results of past photo competitions on Captain Focus.'
              },{property: 'og:description',content: 'See the results of past photo competitions on Captain Focus.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/finished-contests',component:FinishedBattles,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus list of finished photo contests and results.',
            metaTags: [
              {name: 'description',content: 'See the results of past photo competitions on Captain Focus.'
              },{property: 'og:description',content: 'See the results of past photo competitions on Captain Focus.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/daily-games',component:DailyGames,
          meta:{requiresAuth:false,
            title: 'Daily photography games on Captain Focus - photography competition website.',
            metaTags: [
              {name: 'description',content: 'Guess which of the two photos ended up with higher score or guess what brand camera took the picture.'
              },{property: 'og:description',content: 'Guess which of the two photos ended up with higher score or guess what brand camera took the picture.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/user-profile/:userUrl',component:UserProfile,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus photography competition website user profile.',
            metaTags: [
              {name: 'description',content: 'Create and adjust your user profile on Captain Focus - worldwide photo contest portal.'
              },{property: 'og:description',content: 'Create and adjust your user profile on Captain Focus - worldwide photo contest portal.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
            path:'/my-account',component:MyAccount,
            meta:{
              requiresAuth:true,
              title: 'Captain Focus account information',
              metaTags: [
                {name: 'description',content: 'Create and make changes to your user account on Captain Focus photography contests website.'
                },{property: 'og:description',content: 'Create and make changes to your user account on Captain Focus photography contests website.'
                },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
                },{property: 'og:url',content:'http://www.zoznamliekov.eu'
                },{property: 'twitter:card',content:'summary'
                },{property: 'og:site_name',content:'Captain Focus'
                }
              ]
            }
        },
        {
            path:'/my-credit',component:MyCredit,
            meta:{
              requiresAuth:true,
              title: 'Captain Focus credit information',
              metaTags: [
                {name: 'description',content: 'Buy more credit and see the history of your credit purchases and credit usage on our photo contest website.'
                },{property: 'og:description',content: 'Buy more credit and see the history of your credit purchases and credit usage on our photo contest website.'
                },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
                },{property: 'og:url',content:'http://www.zoznamliekov.eu'
                },{property: 'twitter:card',content:'summary'
                },{property: 'og:site_name',content:'Captain Focus'
                }
              ]
            }
        },
        {
          path:'/achievements',component:Achievements,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus photo-contest website achievements and awards.',
            metaTags: [
              {name: 'description',content: 'What awards and achievements you can get for taking part in photography competitions on Captain Focus photo contest web-site.'
              },{property: 'og:description',content: 'What awards and achievements you can get for taking part in photography competitions on Captain Focus photo contest web-site.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/best-of-10',component:BestOf10,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus photography competition website scoreboard based on your 10 best contests overall.',
            metaTags: [
              {name: 'description',content: 'Best of the best in combined score for photography and voting calculated for their 10 most successful photo contests.'
              },{property: 'og:description',content: 'Best of the best in combined score for photography and voting calculated for their 10 most successful photo contests.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/best-of-10-votes',component:BestOf10Vote,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus photography competition website scoreboard based on your 10 best contests in voting.',
            metaTags: [
              {name: 'description',content: 'Who is best voter and has the best eye for good photography on our photo competitions website.'
              },{property: 'og:description',content: 'Who is best voter and has the best eye for good photography on our photo competitions website.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/best-of-10-photos',component:BestOf10Photo,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus photography competition website scoreboard based on your 10 best contests in photography.',
            metaTags: [
              {name: 'description',content: 'Who is the best photographer in long run. Scores from 10 best photo contests are put together to creatye the leaderboard in category photography.'
              },{property: 'og:description',content: 'Who is the best photographer in long run. Scores from 10 best photo contests are put together to creatye the leaderboard in category photography.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/faq',component:Faq,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus photo contest website navigation, uploading photos, selecting and voting explained.',
            metaTags: [
              {name: 'description',content: 'Get your answers on how to participate in this great game of photography and more.'
              },{property: 'og:description',content: 'Get your answers on how to participate in this great game of photography and more.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/contact',component:Contact,
          meta:{
            requiresAuth:false,
            title: 'Contact Captain Focus photography web team.',
            metaTags: [
              {name: 'description',content: 'Do you have ideas and/or suggestions about how to make our photo contest portal even better? Let us know please!'
              },{property: 'og:description',content: 'Do you have ideas and/or suggestions about how to make our photo contest portal even better? Let us know please!'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/terms-and-conditions',component:TermsAndConditions,
          meta:{
            requiresAuth:false,
            title: 'Terms and conditions of use of Captain Focus photography contests website.',
            metaTags: [
              {name: 'description',content: 'Everything you need to know before you start using Captain Focus worlwide photo contest domain.'
              },{property: 'og:description',content: 'Everything you need to know before you start using Captain Focus worlwide photo contest domain.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/log-out',component:LogOut,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus - Worldwide photography competition website. Submit your photos, vote for other photos and have fun.',
            metaTags: [
              {name: 'description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/forgotpassword/:code',component:HomePage,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus - Worldwide photography competition website. Submit your photos, vote for other photos and have fun.',
            metaTags: [
              {name: 'description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
        },
        {
          path:'/signup/:code',component:HomePage,
          alias:'/signup',
          meta:{
            requiresAuth:false,
            title: 'Captain Focus - Worldwide photography competition website. Submit your photos, vote for other photos and have fun.',
            metaTags: [
              {name: 'description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
            }
        },
        {
            // path:'/',component:HomePage,redirect: '/active-contests',meta:{requiresAuth:false}
            path:'/',
            component:HomePage,
            meta:{
              requiresAuth:false,
              title: 'Captain Focus - Worldwide photography competition website. Submit your photos, vote for other photos and have fun.',
              metaTags: [
                {name: 'description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
                },{property: 'og:description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
                },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
                },{property: 'og:url',content:'http://www.zoznamliekov.eu'
                },{property: 'twitter:card',content:'summary'
                },{property: 'og:site_name',content:'Captain Focus'
                }
              ]
            }
        },
        {
          // path:'/',component:HomePage,redirect: '/active-contests',meta:{requiresAuth:false}
          path:'/:notFound(.*)',
          component:NotFound,
          meta:{
            requiresAuth:false,
            title: 'Captain Focus - Worldwide photography competition website. Submit your photos, vote for other photos and have fun.',
            metaTags: [
              {name: 'description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:description',content: 'Various weekly photo contests to choose from. Compare your photography skills with others and get rewarded. Climb your way up the leaderbord for being good photographer and good voter.'
              },{property: 'og:image',content:'http://www.zoznamliekov.eu/pictures/captainFocusImage.jpg'
              },{property: 'og:url',content:'http://www.zoznamliekov.eu'
              },{property: 'twitter:card',content:'summary'
              },{property: 'og:site_name',content:'Captain Focus'
              }
            ]
          }
      },
    ],
});


//---------nastavenie TITLE----------META-----------------------------------------
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }
  
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();
  
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
  
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
  
      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');
  
      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  
    next();
  });


router.beforeEach((to, from, next) => {
    //--------------------PRESMEROVANIE AK UZIVATEL BUDE PRISTUPOVAT K CASTI KTORA JE LEN PRE ZALOGOVANYCH------------------
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // let cookies=document.cookie.split(';').map(cookie=>cookie.split('=')).reduce((accumulator,[key,value])=>({...accumulator,[key.trim()]:decodeURIComponent(value)}),{});
        // console.log('router.beforeEach',cookies,store.getters.getUserLogged);
        //  && !cookies.userLogged
      if (!store.getters.getUserLogged) {//ak bude uzivatel pristupovat k path kde moze ist len ako zalogovany, ak nebude zalogovany alebo cookies nebude existovat ho presmeruje na /
        next({ path: '/' })
      } else {
        next() // go to wherever I'm going
      }
    } else {
      next() // does not require auth, make sure to always call next()!
    }
  })

createApp(App).use(store).use(router).use(GAuth, gAuthOptions).mount('#app');

// Load the JavaScript SDK asynchronously
(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "//connect.facebook.net/en_US/sdk.js#version=v3.2&appId=187988006684098&cookie=true&xfbml=true";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

// createApp(App).use(store).use(router).mount('#app');



