<template>
  <div class="z-upperModal fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 p-3" @click.prevent="zavriModal()">
    <div class="bg-black bg-gradient-to-b border border-green-our border-solid cursor-pointer flex from-gray-dark items-start justify-start max-w-xs p-2 rounded-sm shadow-glowGreen to-black w-full" :class="borderColor">
        <img :src="achievObj.icon" :alt="achievObj.name" class="w-20 h-20">
        <div class="p-1">
            <h6 class="font-bold text-lg" :class="headerColor">{{achievObj.name}}</h6>
            {{achievObj.description}}
            <p class="text-orange-our text-lg block font-bold text-right" v-if="achievObj.reward!==''">+ {{achievObj.reward}}</p>
        </div>
    </div>
  </div>
</template>

<script>
import functions from '../functions.vue'
export default {
    mixins:[functions],
    computed:{
        achievObj(){
            var data=this.$store.getters.getUpperModal;
            return data.data.achievObj;
        },
        borderColor(){
           if(this.achievObj.class=="orange"){
                return {'shadow-glowOrange':true, 'border-orange-our':true};
           }else{
               return {'shadow-glowGreen':true, 'border-green-our':true};
           }
        },
        headerColor(){
           if(this.achievObj.class=="orange"){
                return {'text-orange-our':true};
           }else{
               return {'text-green-our':true};
           }
        },
    },
    methods:{
        zavriModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
    },
}
</script>
