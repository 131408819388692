<template>
  <div class="fixed left-0 top-0 w-full overflow-auto h-full flex justify-center items-center bg-black z-lowerModal">
        <div class="w-full h-full relative overflow-y-auto">
            <div class="flex justify-center fixed z-lowerModalPlus w-full h-12 items-center bg-black bg-opacity-80">
                <span>Best 100 pictures <br><span class="text-green-our">{{getTop100Photos.battleName}}</span></span>
                <span class="button-close-x1" title="Close Modal" @click="closeModalRoute()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
            </div>
            
            <div class="mx-3 my-12">
                <div class="relative" :style="getObjPreFotky">
                    <template v-for="(photo,idx) in getTop100Photos.photoList" :key="photo.id">
                            <div class="absolute ease-in-out transition-transform duration-300" :style="getPozicieFoto[idx]">
                                <div class="relative w-full h-full">
                                    <img :src="photo.photoPath+'_t.jpg'" :title="photo.title" :class="{'opacity-0':isAdult(photo.isAdult)}" class="w-full h-auto shadow1">
                                    <div class="absolute inset-0"></div>
                                    <img :src="$store.getters.URLPICTURES+'/zoom-in.svg'" class="mobileHidden absolute top-3 right-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.stop="modalZoomInImage(photo)">
                                    <div class="-translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2 transform" v-if="isAdult(photo.isAdult)">
                                        <span class="bg-gray-600 block cursor-pointer mb-1 noWrap px-3 py-1 text-center text-sm" title="Show this adult photo" @click="showThisPhoto(photo.id)">SHOW THIS ADULT PHOTO</span>
                                        <span class="bg-orange-our block cursor-pointer mb-1 mt-4 noWrap px-3 py-1 text-center text-sm" title="Show all adults photos" @click="showAllPhotos()">SHOW ALL ADULT PHOTOS</span>
                                    </div>
                                </div>
                            </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Axios from 'axios';
import functions,{debounce,preratajBASE_SIZE,metaTagy} from '../functions.vue';
// import gsap from 'gsap'
export default {
    // onclick="generateMenuReportPhoto(0)"
    mixins:[functions],
    beforeMount(){
        if(!this.getTop100Photos){
            this.nacitajPhotoTop100();
        }else{
            this.vyratajPozicieFoto();
            //---------nastavenie meta tagov
            let meta;
            if(this.getTop100Photos.paidBattle){
                meta={
                    title:`Captain Focus exclusive photo contest TOP 100 - ${this.getTop100Photos.battleName}`
                }
            }else{
                meta={
                    title:`Captain Focus photo contest TOP 100  - ${this.getTop100Photos.battleName}`,
                    description:`Best one hundred competitors in our excluisive photo contest - ${this.getTop100Photos.battleName}`
                }
            }
            metaTagy(meta);
        }
    },
    mounted(){
        // gsap.fromTo('.top100PhotoItem', 0.5, {opacity:0,y:'-50%'}, {opacity:1,y:0,stagger: 0.02,})
        window.addEventListener('resize', debounce(this.vyratajPozicieFoto, 500))
    },
    beforeUnmount: function () {
        window.removeEventListener('resize', this.vyratajPozicieFoto)
    },
    data(){
        return{
            pozicieFoto:[],
            objPreFotky:{width:0,height:0}
        }
    },
    computed:{
        getObjPreFotky(){
            return `width:${this.objPreFotky.width}px;height:${this.objPreFotky.height}px;`;
        },
        getPozicieFoto(){
            return this.pozicieFoto;
        },
        getBattleSeo(){
            return this.$store.getters.getLowerModal.data.battleSeo;
        },
        getTop100Photos(){
            return this.$store.getters.getTop100Photos(this.getBattleSeo);
        },
        getSeeAdultPhotos(){
            return this.$store.getters.getSeeAdultPhotos;
        }
    },
    methods:{
        vyratajPozicieFoto(){
            
            let photoBoxWidth = (Math.abs(window.orientation) == 90 || Math.abs(window.orientation) == -90) ? window.innerHeight : window.innerWidth;
            photoBoxWidth=photoBoxWidth<400?(photoBoxWidth-20):(photoBoxWidth-40);
            // let photoBoxWidth=window.innerWidth<400?(window.innerWidth-20):(window.innerWidth-40);

            let BASE_SIZE={gapSize:10,columns:8,columnWidth:350,positionY:[0,0,0,0,0,0,0,0]};
            //--------vyratame pocet columnov
            BASE_SIZE=preratajBASE_SIZE(BASE_SIZE,photoBoxWidth)
            this.getTop100Photos.photoList.forEach((el,idx)=>{
                let poziciaX,poziciaY=0;
                let poradie=BASE_SIZE.positionY.indexOf(Math.min(...BASE_SIZE.positionY));
                poziciaX=(poradie*BASE_SIZE.columnWidth)+(poradie*BASE_SIZE.gapSize);
                poziciaY=BASE_SIZE.positionY[poradie];
                BASE_SIZE.positionY[poradie]+=Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))+BASE_SIZE.gapSize;
                this.pozicieFoto[idx]=`transform:translate(${poziciaX}px, ${poziciaY}px);width:${BASE_SIZE.columnWidth}px;height:${Math.ceil(((el.size.hThumb/el.size.wThumb)*BASE_SIZE.columnWidth))-BASE_SIZE.gapSize}px;`;
            });
            this.objPreFotky.width=photoBoxWidth;
            this.objPreFotky.height=Math.max(...BASE_SIZE.positionY.map((el)=>parseInt(el)));
        },
        isAdult(isAdult){
            if(!this.getSeeAdultPhotos && parseInt(isAdult)==1){
                return true
            }
            return false
        },
        showThisPhoto(idx){
            this.$store.commit('setTop100PhotosAdult',{idPhoto:idx,battleSeo:this.getBattleSeo});
        },
        showAllPhotos(){
            this.$store.commit('setUserData',{'seeAdultPhotos':true});
        },
        nacitajPhotoTop100(){
            var udaje={'battle':this.getBattleSeo};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=top-100-photos',udaje)
            .then((response) => {
                var res=response.data;

                    //--------zapiseme premenne do $store
                    if(res.return==true){
                        this.$store.commit('setTop100Photos',{battleSeo:this.getBattleSeo,battleName:res.battleName,paidBattle:res.paidBattle,photoList:res.photos});
                        
                        let meta;
                        if(res.paidBattle){//---------nastavenie meta tagov
                            meta={
                                title:`Captain Focus exclusive photo contest TOP 100 - ${res.battleName}`
                            }
                        }else{
                            meta={
                                title:`Captain Focus photo contest TOP 100  - ${res.battleName}`,
                                description:`Best one hundred competitors in our excluisive photo contest - ${res.battleName}`
                            }
                            
                        }
                        metaTagy(meta);

                        this.vyratajPozicieFoto();
                    }else{
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                        if(res.redirect){
                            setTimeout(()=>{
                                this.$store.commit('setErrorModal',{visible:false});
                                this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                                this.$router.push({path:'/finished-contests'});
                            }, 1500);
                        }
                    }

                })
            .catch(() => {
                this.$store.commit('setErrorModal',{visible:true,data:"#5 Error during loading photos"});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
                // console.log(this.$store.getters.battleObject(this.battleUdaje.id));
            });
        },
        closeModalRoute(){
            this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
            this.$router.push({path:'/finished-contests'});
        },
        modalZoomInImage(photo){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{
            photoPath:photo.photoPath,
            photoFileName:photo.photoFileName,
            exif:photo.exif,
            size:photo.size,
            isAdult:photo.isAdult,
            idPhoto:photo.id,
            user:photo.user,
            place:photo.place,
            achievements:photo.achievements,
            battleSeo:this.getBattleSeo
            }}); 
        },
    }
}
</script>