<template>
<div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
    <div class="w-11/12 max-w-md relative p-5 border-solid border-b-4 border-green-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <h3 class="text-center mb-5 text-green-our text-2xl">Expand your select slots</h3>
        <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        <div class="mb-5">Po potvrdeni selectu tychto ({{getBattle.activeBattle.selectLeft}}) fotografii vam nezostava uz ziaden select. Je este <strong>{{unseenPhotos}}</strong> nepozretych fotiek a mozete si zakupit dalsie sloty ({{getBattle.generalSettings.extraSelectPurchase}})</div>
        <div v-if="showSuccessfullPurchaseHodnota" class="text-center mb-5 text-green-our">
            Nakup bol uspesny mate dalsich {{getBattle.generalSettings.extraSelectPurchase}} select left
        </div>
        <div v-else>
            <button v-if="showUnlock" class="bg-orange-our text-lg py-2 px-5 block mx-auto" @click="unlock()">UNLOCK</button>
            <button v-else-if="!showSuccessfullPurchaseHodnota" class="bg-orange-our text-lg py-2 px-5 block mx-auto"  @click="purchaseSelectSlot()">YES USE {{getBattle.generalSettings.extraSelectPurchasePrice}} CREDITS NOW</button>
        </div>
    </div>

</div>
</template>

<script>
import functions from '../functions.vue'
import Axios from 'axios';
export default {
    mixins:[functions],
    // beforeMount(){
    //     this.$store.getters.upperModal.data.battleId;
    // },
    data(){
        return {
            showUnlock:true,
            showSuccessfullPurchase:false
        }
    },
    computed:{
        getBattleId(){
            return this.$store.getters.getUpperModal.data.battleId;
        },
        getBattle(){
            return this.$store.getters.getBattle(this.getBattleId);
        },
        unseenPhotos(){
            var pocet=this.getBattle.activeBattle.totalPhotosInBattle-this.getBattle.activeBattle.alreadySeenPhotos;
            if(pocet>200){//----------nad 500 zobrazenie %
                return 'MORE THAN 200'
            }else if(pocet>10){
                return 'MORE THAN '+Math.abs(Math.floor(pocet/10)*10)+'';
            }else{
                return pocet;
            } 
        },
        showSuccessfullPurchaseHodnota(){
            return this.showSuccessfullPurchase;
        }
    },
    methods:{
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
        unlock(){
            this.showUnlock=false
        },
        purchaseSelectSlot(){

            var getUserData=this.$store.getters.getUserData;
            if(this.getBattle.generalSettings.extraSelectPurchasePrice>getUserData.actualCredits){
                this.$store.commit('setErrorModal',{visible:true,data:"You have "+getUserData.actualCredits+" credits left"});
                return;
            }

            var udaje={'battle':this.getBattle.battleSettings.nameSeo};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=select-slot-purchase", udaje)
            .then((response)=>{
                var res=response.data;
                if('code' in res && res.code==1){//-----------odlogovany uzivatel
                    this.$store.commit('deleteLoadedData');
                    this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                    this.$router.push({path:'/active-contests'});
                }else{
                    if(res.return===false){
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }else if(res.return===true){
                        this.$store.commit('setBuyExtraSelect',{'battleId':this.getBattleId,'data':{'selectLeft':res.selectLeft,'selectLeftBeforeSubmit':res.selectLeftBeforeSubmit,'extraSelect':res.extraSelect}});
                        this.showSuccessfullPurchase=true;
                    }
                }
                //--------zapiseme aktualne kredity
                if('actualCredits' in res){
                    this.$store.commit('setUserData',{'actualCredits':parseInt(res.actualCredits)});
                }
            })
            .catch((e)=>{
                this.$store.commit('setErrorModal',{visible:true,data:"#17 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        }
    },
}
</script>

<style>

</style>