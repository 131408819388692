<template>
    <div class="fixed inset-0 z-modalLoading flex justify-center items-center bg-black bg-opacity-90" @click="close()">
        <div class="w-11/12 max-w-xs relative">
            <div class="p-5 bg-red-our"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-8 h-8 mx-auto block"></div>
            <div class="p-3 text-lg bg-white text-center text-gray-dark">{{msg}}<button @click="close()" class="block bg-red-our text-white text-center py-2 px-5 border-none mx-auto my-4 shadow-md cursor-pointer">close</button></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            casovac:''
        }
    },
    mounted(){
        this.casovac=setTimeout(()=>{
            this.$store.commit('setErrorModal',{visible:false,data:""});
        },8000);
    },
    computed:{
        msg(){
            var errorModalData=this.$store.getters.getErrorModal;
            return errorModalData.data;
        }
    },
    methods:{
        close(){
            clearTimeout(this.casovac);
            this.$store.commit('setErrorModal',{visible:false,data:""});
        }
    }
}
</script>
