<template>
  <div class="fixed left-0 top-0 w-full overflow-auto h-full flex justify-center items-center bg-black z-lowerModal bg-opacity-80">
        <div class="w-full h-full relative overflow-y-auto">
            <span class="button-close-x1" title="Close Modal" @click="closeModalRoute()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
            <h1 class="text-center text-2xl py-4">Score board for photos</h1>
            <h2 class="bg-black border font-bold max-w-2xl mx-auto py-2 text-2xl text-center to-black" :class="computedClassBorder">Contest {{getScoreBoard.battleName}}</h2>
                <template v-if="zobrazScoreUzivatela && getScoreBoard[getScoreType]">
                    <score-board-list :ScoreBoard="getScoreBoard[getScoreType].user" :maxScore="getScoreBoard[getScoreType].maxScore" :background="ScoreBoardBgUser()" :category="getScoreType"></score-board-list>
                </template>
                <template v-if="getScoreBoard">
                    <score-board-list v-for="(ScoreBoard,index) in getScoreBoard[getScoreType].list" :key="index+1"  :maxScore="getScoreBoard[getScoreType].maxScore" :ScoreBoard="ScoreBoard" :category="getScoreType" :background="ScoreBoardBg(ScoreBoard.id)"></score-board-list>
                    <!-- <div class="bg-green-our block my-2 mx-auto px-4 py-2 rounded-sm text-center w-40 cursor-pointer" v-if="getScoreBoard[getScoreType].list.length<getScoreBoard[getScoreType].resultCount" @click="loadScoreBoard()">LOAD MORE</div> -->
                    <img class="block mt-2 mx-auto w-32 cursor-pointer" :src="`${$store.getters.URLSITE}/pictures/loadMoreButton.svg`" alt="Load more"  v-if="getScoreBoard[getScoreType].list.length<getScoreBoard[getScoreType].resultCount" @click="loadScoreBoard()">
                </template>
                <template v-else>
                    <div class="text-center text-xl">No results found</div>
                </template>
        </div>
  </div>
</template>

<script>
import Axios from 'axios'
import ScoreBoardList from '../ScoreBoard/List'
import functions,{metaTagy} from '../functions.vue';
export default {
    mixins:[functions],
    components:{ScoreBoardList},
    created(){
        if(!this.getScoreBoard[this.getScoreType]){
            this.loadScoreBoard();
        }else if(this.getScoreBoard[this.getScoreType].list.length==0){
            this.loadScoreBoard();
        }else{
            //----meta znacky
            let meta;
            if(this.getScoreType=="photo"){
                meta={
                    title:`Captain Focus photo contest ${this.getScoreBoard.battleName} - results for photography.`,
                    description:`Results in photo contest ${this.getScoreBoard.battleName} -  in category photographer. Two best shots are evaluated.`
                } 
            }else if(this.getScoreType=="vote"){
                meta={
                    title:`Captain Focus photo contest ${this.getScoreBoard.battleName} -  results for voting.`,
                    description:`Best voters in recent photo competition ${this.getScoreBoard.battleName} on Captain Focus.`
                }
            }else{
                meta={
                    title:`Captain Focus photo contest ${this.getScoreBoard.battleName} -  results for photography and voting combined.`,
                    description:`Who reached the best score for being a good photographer and voting for the best photos in ${this.getScoreBoard.battleName} photo contest`
                }
            }
            metaTagy(meta);
        }
    },
    computed:{
        computedClassBorder(){
            if(this.getScoreType=="photo"){
                return 'border-green-our';
            }else if(this.getScoreType=="vote"){
                return 'border-blue-our';
            }else{
                return 'border-orange-our';
            }
        },
        getBattleSeo(){
            return this.$store.getters.getLowerModal.data.battleSeo;
        },
        getScoreBoard(){
           return this.$store.getters.getScoreBoard({battleSeo:this.getBattleSeo});
        },
        getUserLogged(){
            return this.$store.getters.getUserLogged;
        },
        getScoreType(){
            if(this.$route.params.action=="results-score-photo"){
                return 'photo';
            }else if(this.$route.params.action=="results-score-vote"){
                return 'vote';
            }else{
                return 'total';
            }
        },

        zobrazScoreUzivatela(){
            if(this.getUserLogged){
                if(this.getScoreBoard[this.getScoreType]){
                    if('nick' in this.getScoreBoard[this.getScoreType].user){
                        return true;
                    }
                }
            }
            return false;
        },
    },
    methods:{
        ScoreBoardBgUser(){
            if(this.getScoreType=="photo"){
                return 'via-green-900 mb-8';
            }else if(this.getScoreType=="vote"){
                return 'via-blue-900 mb-8';
            }else{
                return 'via-yellow-900 mb-8';
            }
        },
        ScoreBoardBg(idUser){
            if(this.getUserLogged){
                if(this.getScoreBoard[this.getScoreType]){
                    if('id' in this.getScoreBoard[this.getScoreType].user){
                        if(idUser===this.getScoreBoard[this.getScoreType].user.id){
                            if(this.getScoreType=="photo"){
                                return 'via-green-900';
                            }else if(this.getScoreType=="vote"){
                                return 'via-blue-900';
                            }else{
                                return 'via-yellow-900';
                            }
                        }
                    }
                }
            }
            return 'via-black';
        },
        flagLink(flag){
            return this.$store.getters.URLSITE+'/photos/flags/'+flag+'_s.jpg'
        },
        getWidth(max,actual){
            return {'width':((parseInt(actual)/parseInt(max))*100)+'%'}
        },
        loadScoreBoard(){
            this.$store.commit('setModalLoading',true);
            let limit=0;
            if(this.getScoreBoard){
                if('list' in this.getScoreBoard[this.getScoreType]){
                    limit=this.getScoreBoard[this.getScoreType].list.length;
                }
            }
            var udaje={'limit':limit,'type':this.getScoreType,'battleSeo':this.getBattleSeo};
            Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=load-scoreboard", udaje)
            .then((response)=>{
                var res=response.data;
                if(res.return===false){
                    this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    setTimeout(()=>{
                        this.$store.commit('setErrorModal',{visible:false});
                        this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                        this.$router.push({path:'/finished-contests'});
                    }, 1500);
                }else if(res.return===true){
                    //----------- upravime data v uzivatelovi voteLeft
                    this.$store.commit('setScoreBoard',{
                        'type':this.getScoreType,
                        'list':res.list,
                        'user':res.user,
                        'maxScore':parseInt(res.maxScore),
                        'resultCount':parseInt(res.resultCount),
                        'battleSeo':this.getBattleSeo,
                        'battleName':res.battleName,
                        'achievements':res.achievements,
                    });
                    
                    //----meta znacky
                    let meta;
                    if(this.getScoreType=="photo"){
                        meta={
                            title:`Captain Focus photo contest ${res.battleName} - results for photography.`,
                            description:`Results in photo contest ${res.battleName} -  in category photographer. Two best shots are evaluated.`
                        } 
                    }else if(this.getScoreType=="vote"){
                        meta={
                            title:`Captain Focus photo contest ${res.battleName} -  results for voting.`,
                            description:`Best voters in recent photo competition ${res.battleName} on Captain Focus.`
                        }
                    }else{
                        meta={
                            title:`Captain Focus photo contest ${res.battleName} -  results for photography and voting combined.`,
                            description:`Who reached the best score for being a good photographer and voting for the best photos in ${res.battleName} photo contest`
                        }
                    }
                    metaTagy(meta);

                }
            })
            .catch((e)=> {
                this.$store.commit('setErrorModal',{visible:true,data:"#88 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        },
        closeModalRoute(){
            this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
            this.$router.push({path:'/finished-contests'});
        },
    }
}
</script>