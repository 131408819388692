import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_zoom_in_image = _resolveComponent("zoom-in-image");

  var _component_report_photo = _resolveComponent("report-photo");

  var _component_select_no_select_left = _resolveComponent("select-no-select-left");

  var _component_select_exit_before_submit = _resolveComponent("select-exit-before-submit");

  var _component_select_lets_start = _resolveComponent("select-lets-start");

  var _component_select_all_seen_photos = _resolveComponent("select-all-seen-photos");

  var _component_select_submit_ok = _resolveComponent("select-submit-ok");

  var _component_buy_extra_select_slots = _resolveComponent("buy-extra-select-slots");

  var _component_good_job_time_for_vote = _resolveComponent("good-job-time-for-vote");

  var _component_good_job_all_done = _resolveComponent("good-job-all-done");

  var _component_how_to_play = _resolveComponent("how-to-play");

  var _component_reset_votes = _resolveComponent("reset-votes");

  var _component_reset_one_vote = _resolveComponent("reset-one-vote");

  var _component_vote_exit_before_submit = _resolveComponent("vote-exit-before-submit");

  var _component_vote_no_photo = _resolveComponent("vote-no-photo");

  var _component_zoom_in_achieve = _resolveComponent("zoom-in-achieve");

  var _component_login_panel = _resolveComponent("login-panel");

  var _component_forgoten_password = _resolveComponent("forgoten-password");

  var _component_general_rules = _resolveComponent("general-rules");

  var _component_battle_rules = _resolveComponent("battle-rules");

  var _component_buy_credit = _resolveComponent("buy-credit");

  var _component_forgoten_password_enter_new = _resolveComponent("forgoten-password-enter-new");

  var _component_signup_panel_confirm = _resolveComponent("signup-panel-confirm");

  var _component_payment_status = _resolveComponent("payment-status");

  var _component_first_ever_load_exclusive_contests = _resolveComponent("first-ever-load-exclusive-contests");

  var _component_first_ever_load_upcoming_contests = _resolveComponent("first-ever-load-upcoming-contests");

  var _component_first_ever_load_active_contests = _resolveComponent("first-ever-load-active-contests");

  var _component_first_ever_load_finished_contests = _resolveComponent("first-ever-load-finished-contests");

  var _component_first_ever_load_daily_games = _resolveComponent("first-ever-load-daily-games");

  return _openBlock(), _createBlock(_Fragment, null, [$options.getUpperModal.whatToShow == 'ZoomInImage' ? (_openBlock(), _createBlock(_component_zoom_in_image, {
    key: 0
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'ReportPhoto' ? (_openBlock(), _createBlock(_component_report_photo, {
    key: 1
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'SelectNoSelectLeft' ? (_openBlock(), _createBlock(_component_select_no_select_left, {
    key: 2
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'SelectExitBeforeSubmit' ? (_openBlock(), _createBlock(_component_select_exit_before_submit, {
    key: 3
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'SelectLetsStart' ? (_openBlock(), _createBlock(_component_select_lets_start, {
    key: 4
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'SelectAllSeenPhotos' ? (_openBlock(), _createBlock(_component_select_all_seen_photos, {
    key: 5
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'SelectSubmitOk' ? (_openBlock(), _createBlock(_component_select_submit_ok, {
    key: 6
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'BuyExtraSelectSlots' ? (_openBlock(), _createBlock(_component_buy_extra_select_slots, {
    key: 7
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'GoodJobTimeForVote' ? (_openBlock(), _createBlock(_component_good_job_time_for_vote, {
    key: 8
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'GoodJobAllDone' ? (_openBlock(), _createBlock(_component_good_job_all_done, {
    key: 9
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'HowToPlay' ? (_openBlock(), _createBlock(_component_how_to_play, {
    key: 10
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'ResetVotes' ? (_openBlock(), _createBlock(_component_reset_votes, {
    key: 11
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'ResetOneVote' ? (_openBlock(), _createBlock(_component_reset_one_vote, {
    key: 12
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'VoteExitBeforeSubmit' ? (_openBlock(), _createBlock(_component_vote_exit_before_submit, {
    key: 13
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'VoteNoPhoto' ? (_openBlock(), _createBlock(_component_vote_no_photo, {
    key: 14
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'ZoomInAchieve' ? (_openBlock(), _createBlock(_component_zoom_in_achieve, {
    key: 15
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'LoginPanel' ? (_openBlock(), _createBlock(_component_login_panel, {
    key: 16
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'ForgotenPassword' ? (_openBlock(), _createBlock(_component_forgoten_password, {
    key: 17
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'GeneralRules' ? (_openBlock(), _createBlock(_component_general_rules, {
    key: 18
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'BattleRules' ? (_openBlock(), _createBlock(_component_battle_rules, {
    key: 19
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'BuyCredit' ? (_openBlock(), _createBlock(_component_buy_credit, {
    key: 20
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'ForgotenPasswordEnterNew' ? (_openBlock(), _createBlock(_component_forgoten_password_enter_new, {
    key: 21
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'SignupPanelConfirm' ? (_openBlock(), _createBlock(_component_signup_panel_confirm, {
    key: 22
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'PaymentStatus' ? (_openBlock(), _createBlock(_component_payment_status, {
    key: 23
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'FirstEverLoadExclusiveContests' ? (_openBlock(), _createBlock(_component_first_ever_load_exclusive_contests, {
    key: 24
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'FirstEverLoadUpcomingContests' ? (_openBlock(), _createBlock(_component_first_ever_load_upcoming_contests, {
    key: 25
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'FirstEverLoadActiveContests' ? (_openBlock(), _createBlock(_component_first_ever_load_active_contests, {
    key: 26
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'FirstEverLoadFinishedContests' ? (_openBlock(), _createBlock(_component_first_ever_load_finished_contests, {
    key: 27
  })) : _createCommentVNode("", true), $options.getUpperModal.whatToShow == 'FirstEverLoadDailyGames' ? (_openBlock(), _createBlock(_component_first_ever_load_daily_games, {
    key: 28
  })) : _createCommentVNode("", true)], 64);
}