<template>
  <div class="fixed left-0 top-0 w-full overflow-auto h-full flex justify-center items-center bg-black z-lowerModal">
        <div class="w-full h-full relative overflow-y-auto">

            <div class="flex justify-center fixed z-lowerModalPlus w-full h-12 items-center bg-black bg-opacity-80">
                <span class="text-white py-2 px-4 block max-w-xs self-start border-b-4 border-l-4 border-r-4 border-white rounded-bl-lg rounded-br-lg bgGradientBlueGreen text-center bg-opacity-80 border-solid">SELECT LEFT <strong>{{TempPhotosSelectLeft}}</strong> OF <strong>{{MaxPhotosSelect}}</strong></span>
                <span class="button-close-x1" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
            </div>
            

            <div class="bgGradientBlueGreen text-center border-4 border-r-0 border-solid cursor-pointer fixed lg:px-8 lg:py-5 lg:text-2xl md:px-4 md:py-2 px-2 py-1 right-0 rounded-bl-xl rounded-tl-xl top-1/2 z-lowerModalPlus" @click.prevent="submitSelectedPhotos()" title="Submit selected photos"><div class="pb-1">NEXT</div><div class="border-t-2 border-white border-solid">SUBMIT</div></div>
            <div class="columns-8-350px py-12 px-2 w-full mx-auto">
                <template v-for="photo in getPhotosForSelect" :key="photo.idPhoto">
                        <div class="relative mb-4">
                            <figure class="relative" @mousedown.stop="select(photo.idPhoto)" :title="photo.title">
                            <img :src="photo.photoPath+'_t.jpg'" :class="{'opacity-0':isAdult(photo.isAdult)}" class="cursor-pointer w-full h-auto shadow1">
                            <div class="absolute inset-0"></div>
                            </figure>
                            <img :src="$store.getters.URLPICTURES+'/zoom-in.svg'" class="mobileHidden absolute top-3 right-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.stop="modalZoomInImage(photo)">
                            <img :src="iconIsReported(photo.reported)" class="absolute left-3 top-3 w-10 h-10 block opacity-50 hover:opacity-100 cursor-pointer" @click.prevent="modalReportPhoto(photo.idPhoto,photo.reported,photo.idChecksum)" >
                            <img v-if="photo.selected==1 || isTempSelected(photo.idPhoto)" :src="$store.getters.URLPICTURES+'/select_photo.svg'" class="-translate-x-1/2 -translate-y-1/2 absolute cursor-pointer h-32 left-1/2 top-1/2 transform w-32" title="You selected this photo" @click.stop="select(photo.idPhoto)">
                            <div class="absolute bottom-0 left-1/2 transform translate-x-1/2" v-if="isAdult(photo.isAdult)">
                                <span class="cursor-pointer bg-gray-600 py-1 px-3 noWrap block mb-1 text-center" title="Show this adult photo" @click="showThisPhoto(photo.idPhoto)">SHOW THIS ADULT PHOTO</span>
                                <span class="cursor-pointer bg-orange-our py-1 px-3 noWrap block mb-1 text-center" title="Show all adults photos" @click="showAllPhotos()">SHOW ALL ADULTS PHOTOS</span>
                            </div>
                        </div>
                </template>
                
            </div>
        </div>
    </div>
</template>
<script>

import Axios from 'axios';
import functions from '../functions.vue';
export default {
    mixins:[functions],
    beforeMount(){
        if(this.getPhotosForSelect.length==0){
            this.nacitajPhotosForSelect();
        }
    },
    data(){
        return{
            tempSelect:[]
        }
    },
    computed:{
        getBattleId(){
            return this.$store.getters.getLowerModal.data.battleId;
        },
        getPhotosForSelect(){
            return this.$store.getters.getPhotosForSelect(this.getBattleId);
        },
        getBattle(){
            return this.$store.getters.getBattle(this.getBattleId);
        },
        TempPhotosSelectLeft(){
            var selectLeft=parseInt(this.getBattle.activeBattle.selectLeft);
            return selectLeft-this.tempSelect.length;
        },
        MaxPhotosSelect(){
            var maxPocetSelectovUzivatela=parseInt(this.getBattle.generalSettings.maxSelect)+(parseInt(this.getBattle.generalSettings.extraSelectPurchase)*parseInt(this.getBattle.paidServices.extraSelect));
            return parseInt(maxPocetSelectovUzivatela);
        },
        getSeeAdultPhotos(){
            return this.$store.getters.getSeeAdultPhotos;
        },
    },
    methods:{
        isTempSelected(photoId){
            var tempIndex=this.tempSelect.map((el)=>('photoId' in el)?el.photoId:false).indexOf(photoId);
            if(tempIndex==-1){
                return false;
            }
            return true;
        },
        isAdult(isAdult){
            if(!this.getSeeAdultPhotos){
                if(parseInt(isAdult)==1){
                    return true
                }
            }
            return false
        },
        select(photoId){
            //--------zistime ci je uz fotka selectnuta v selected
            var photo=this.$store.getters.getPhotoForSelect({'battleId':this.getBattleId,'photoId':photoId});
            //-------zistime ci je fotka selectnuta v Temp
            var tempIndex=this.tempSelect.map((el)=>('photoId' in el)?el.photoId:false).indexOf(photoId);
            // var tempSelected=this.getTempSelectPhoto(photoId);

            //------ak neni selectnuta v selecte ani v Temp selecte 
            if(photo.selected==0 && tempIndex===-1 && this.TempPhotosSelectLeft>0){
                this.tempSelect.push({'photoId':photoId,'idChecksum':photo.idChecksum});
            }else if(photo.selected==0 && tempIndex===-1 && this.TempPhotosSelectLeft==0){
                this.$store.commit('setUpperModal',{visible:true,whatToShow:'BuyExtraSelectSlots',data:{'battleId':this.getBattleId}});
            }else if(photo.selected==0 && tempIndex>=0){//-----ak je v tempe ale neni este zapisane v orig selecte
                this.tempSelect.splice(tempIndex,1);
            }

        },
        iconIsReported(stav){
            if(stav){
                return this.$store.getters.URLPICTURES+'/reported.svg';
            }
            return this.$store.getters.URLPICTURES+'/report.svg';
        },
        nacitajPhotosForSelect(){
            //-----------zistime ci uz uzivatel videl vsetky fotky ak ano posleme premennu 
            let allSeenPhotos=false;
            if('allSeenPhotos' in this.getBattle.activeBattle){
                if(this.getBattle.activeBattle.allSeenPhotos==true){
                    allSeenPhotos=true;
                }
            }

            var udaje={'battle':this.getBattle.battleSettings.nameSeo,'allSeenPhotos':allSeenPhotos};
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=select-photo',udaje)
            .then((response) => {
                var res=response.data;

                if('code' in res && res.code==1){//-----------odlogovany uzivatel 
                    this.$store.commit('deleteLoadedData');
                    this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                    this.$router.push({path:'/active-contests'});
                }{//--------OK zapiseme udaje co prisli zo servera
                    //--------zapiseme premenne do $store
                    if(res.return==true){
                        
                        //-----zmazeme fotografie ktore boli nahrate v $store
                        this.$store.commit('deleteBattleListPhotosForSelect',this.getBattleId);

                            if('allSeenPhotos' in res){
                                //----------vypise hlasku ze uzivatel uz vide vsetky fotky a ze mu bude ukazovat tie ktore uz videl
                                this.$store.commit('setUpperModal',{visible:true,whatToShow:'SelectAllSeenPhotos',data:{}});
                            }else{
                                //--------vypise hlasku o tom ako sa selectuje a pusti ho dalej selectovat
                                this.$store.commit('setUpperModal',{visible:true,whatToShow:'SelectLetsStart',data:{}});
                            }

                        let dataNaZapis={
                            data:{
                                voteLeft:parseInt(res.voteLeft),
                                selectLeft:parseInt(res.selectLeft),
                                selectLeftBeforeSubmit:parseInt(res.selectLeft),
                                totalPhotosInBattle:parseInt(res.totalPhotosInBattle),
                                alreadySeenPhotos:parseInt(res.alreadySeenPhotos),
                                photosForSelect:res.fotografie
                            },
                            battleId:this.getBattleId
                        }
                        //---------zistime ci mal uzivatel uz pozrete vsetky fotografie
                        if('allSeenPhotos' in res){
                            dataNaZapis.data.allSeenPhotos=true;
                        }

                        //-------zapiseme vseobecne data z dataNaZapis do $store battlu
                        this.$store.commit('setToPhotosForSelect',dataNaZapis);
                    }else{
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }
                }
            })
            .catch((e) => {
                this.$store.commit('setErrorModal',{visible:true,data:"#5 Error during loading photos"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
                // console.log(this.$store.getters.battleObject(this.battleUdaje.id));
            });
        },
        closeModal(){
            //---------zistime ci boli zmeny v temp selecte ak ano upozornime uzivatela
            if(this.tempSelect.length>0){//---------ak boli zmeny je tam dalsie okno
                this.$store.commit('setUpperModal',{visible:true,whatToShow:'SelectExitBeforeSubmit',data:{'battleId':this.getBattleId}}); 
                // this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
            }else{
                this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
            }
        },
        modalZoomInImage(photo){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{photoPath:photo.photoPath,
            photoFileName:photo.photoFileName,
            exif:photo.exif,
            size:photo.size,
            isAdult:photo.isAdult,
            idPhoto:photo.idPhoto,
            battleSeo:this.getBattle.battleSettings.nameSeo
            }}); 
        },
        modalReportPhoto(photoId,reported,idChecksum){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ReportPhoto',data:{'photoId':photoId,
            'battleId':this.getBattleId,
            'reported':reported,'idChecksum':idChecksum}}); 
        },
        submitSelectedPhotos(){
            this.$store.commit('setModalLoading',true);
            
            var pocetSelectnutych=0;
            var poslaneFoto=[];
                this.getPhotosForSelect.forEach((el)=>{

                    poslaneFoto.push({id:el.idPhoto, idChecksum:el.idChecksum, select:this.isTempSelected(el.idPhoto)});
                    if(this.isTempSelected(el.idPhoto)){
                        pocetSelectnutych++;
                    }
                });
            
            //------nakolko sme ideme zapisat nove fotky na select odmazeme fotky z vote ak sa aspom 1 nova fotka selectla
            if(pocetSelectnutych>0){
                this.$store.commit('deleteBattleListPhotosForVote',this.getBattleId);
            }

            //-----------zistime ci uz uzivatel videl vsetky fotky ak ano posleme premennu 
            let allSeenPhotos=false;
            if('allSeenPhotos' in this.getBattle.activeBattle){
                if(this.getBattle.activeBattle.allSeenPhotos==true){
                    allSeenPhotos=true;
                }
            }

            var udaje={'battle':this.getBattle.battleSettings.nameSeo,'allSeenPhotos':allSeenPhotos,'photos':JSON.stringify(poslaneFoto)};

            Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=select-photo',udaje)
            .then((response)=>{
                var res=response.data;
                if('code' in res && res.code==1){//-----------odlogovany uzivatel 
                    this.$store.commit('deleteLoadedData');
                    this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                    this.$router.push({path:'/active-contests'});
                }{//--------OK zapiseme udaje co prisli zo servera
                    if(res.return==false){
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }else if(res.return==true){   

                        //-------zmazeme povodne fotky
                        this.$store.commit('deleteBattleListPhotosForSelect',this.getBattleId);
                        
                        let dataNaZapis={
                            data:{
                                voteLeft:parseInt(res.voteLeft),
                                selectLeft:parseInt(res.selectLeft),
                                selectLeftBeforeSubmit:parseInt(res.selectLeft),
                                totalPhotosInBattle:parseInt(res.totalPhotosInBattle),
                                alreadySeenPhotos:parseInt(res.alreadySeenPhotos),
                                photosForSelect:res.fotografie
                            },
                            battleId:this.getBattleId
                        }
                        //---------zistime ci mal uzivatel uz pozrete vsetky fotografie
                        if('allSeenPhotos' in res){
                            dataNaZapis.data.allSeenPhotos=true;
                        }

                        //-------zapiseme vseobecne data z dataNaZapis do $store battlu
                        this.$store.commit('setToPhotosForSelect',dataNaZapis);

                        //---------odmazeme tempSelecty
                        this.tempSelect.splice(0,this.tempSelect.length);

                        window.scrollTo({ top: 0, behavior: 'smooth' });

                        // if(res.fotografie.length>0){ 
                        this.$store.commit('setUpperModal',{visible:true,whatToShow:'SelectSubmitOk',data:{}}); 
                        setTimeout(()=>{
                            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}}); 

                            if('allSeenPhotos' in res){
                                //----------vypise hlasku ze uzivatel uz vide vsetky fotky a ze mu bude ukazovat tie ktore uz videl
                                this.$store.commit('setUpperModal',{visible:true,whatToShow:'SelectAllSeenPhotos',data:{}});
                            }else{
                                if(res.selectLeft==0){
                                    //---------zobrazime uzivatelovi hlasku je minul vsetko GOOD JOB
                                    this.$store.commit('setUpperModal',{visible:true,whatToShow:'GoodJobTimeForVote',data:{'battleId':this.getBattleId}});
                                }
                            }

                        },1000);
                        
                    }

                }
            })
            .catch((e)=>{
                this.$store.commit('setErrorModal',{visible:true,data:"#7 Network error during loading data"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            }); 
        
         }

    }

}
</script>