import "core-js/modules/es.object.to-string.js";
import Axios from 'axios';
import functions from '../components/functions';
export default {
  mixins: [functions],
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');

    if (this.getUserData.creditList.length == 0) {
      this.loadUserCreditList();
    }
  },
  data: function data() {
    return {
      promotionCode: "",
      promoCodeInformation: {
        ok: "",
        err: ""
      }
    };
  },
  computed: {
    getUserData: function getUserData() {
      return this.$store.getters.getUserData;
    },
    getPromoCodeInformation: function getPromoCodeInformation() {
      return this.promoCodeInformation;
    },
    promotionCodeButton: function promotionCodeButton() {
      if (this.promotionCode.length >= 5) {
        return {
          'bg-orange-our': true,
          'cursor-pointer': true
        };
      } else {
        return {
          'bg-gray-600': true
        };
      }
    }
  },
  methods: {
    openProductPurchase: function openProductPurchase(itemObject) {
      this.$store.commit('setUpperModal', {
        visible: true,
        whatToShow: 'BuyCredit',
        data: {
          product: itemObject
        }
      });
    },
    loadUserCreditList: function loadUserCreditList() {
      var _this = this;

      var udaje = {
        'limit': this.getUserData.creditList.length
      };
      this.$store.commit('setModalLoading', true);
      Axios.post(this.$store.getters.URLAJAX + "/handler.php?page=my-credit", udaje).then(function (response) {
        var res = response.data;

        if ('code' in res && res.code == 1) {
          //-----------odlogovany uzivatel
          _this.$store.commit('deleteLoadedData');

          _this.$router.push({
            path: '/active-contests'
          });
        } else {
          if (res.return === true) {
            //----------- upravime data v uzivatelovi voteLeft
            _this.$store.commit('setUserData', {
              'actualCredits': parseInt(res.actualCredits),
              'creditList': res.creditList,
              'creditListTotal': parseInt(res.creditListTotal),
              'countryList': res.countryList,
              'country': parseInt(res.country),
              'countryName': res.countryName,
              'products': res.products
            });
          }
        }
      }).catch(function (e) {
        _this.$store.commit('setErrorModal', {
          visible: true,
          data: "#117 Network error" + e + ""
        });
      }).finally(function () {
        _this.$store.commit('setModalLoading', false);
      });
    },
    sendPromotionCode: function sendPromotionCode() {
      var _this2 = this;

      this.promoCodeInformation.ok = "";
      this.promoCodeInformation.err = "";

      if (this.promotionCode.length == 8) {
        var udaje = {
          'promotionCode': this.promotionCode
        };
        this.$store.commit('setModalLoading', true);
        Axios.post(this.$store.getters.URLAJAX + "/handler.php?page=promotion-code", udaje).then(function (response) {
          var res = response.data;

          if ('code' in res && res.code == 1) {
            //-----------odlogovany uzivatel
            _this2.$store.commit('deleteLoadedData');

            _this2.$router.push({
              path: '/active-contests'
            });
          } else {
            if (res.return === true) {
              if ('success' in res) {
                if (res.success) {
                  //----------- upravime data v uzivatelovi
                  _this2.promoCodeInformation.ok = "Credits received";

                  _this2.$store.commit('deleteUserCreditList');

                  _this2.$store.commit('setUserData', {
                    'actualCredits': parseInt(res.actualCredits),
                    'creditList': res.creditList,
                    'creditListTotal': parseInt(res.creditListTotal),
                    'products': res.products
                  });

                  _this2.promotionCode = "";
                } else {
                  _this2.promoCodeInformation.err = res.returnMsg;
                }
              }
            } else {
              _this2.$store.commit('setErrorModal', {
                visible: true,
                data: res.returnMsg
              });
            }
          }
        }).catch(function (e) {
          _this2.$store.commit('setErrorModal', {
            visible: true,
            data: "#117 Network error" + e + ""
          });
        }).finally(function () {
          _this2.$store.commit('setModalLoading', false);
        });
      } else {
        this.$store.commit('setModalLoading', true);
        setTimeout(function () {
          _this2.promoCodeInformation.err = "Promo code invalid or expired";

          _this2.$store.commit('setModalLoading', false);
        }, 1500);
      }
    }
  }
};