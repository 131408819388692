<template>
    <img :src="$store.getters.URLPICTURES+'/lock.svg'" class="w-12 h-12 border-b-2 border-orange-our m-1 animate-pulse box-content bg-black cursor-pointer" alt="Extra photo slot locked" @click="setShowInfoPanel()">
</template>
<script>
export default {
    methods:{
        setShowInfoPanel(){
            this.$emit('emitShowInfoPanel',true);
        }
    }
}
</script>