<template>
    <h1 class="bg-gray-700 bg-opacity-50 border-b border-gray-500 py-2 text-2xl text-center text-white">Faq</h1>
    <div class="container mx-auto">
    <template v-if="getFaq">
        <div class="max-w-lg w-full mx-auto flex justify-between">
            <input type="text" placeholder="filter faq" ref="input" v-model="filterText" class="flex-grow bg-gray-dark block  text-center p-2 border border-gray-600 border-solid">
            <span v-if="filterText!=''" class="block ml-1 bg-gray-dark cursor-pointer flex h-10 items-center justify-center right-1 w-10 border rounded-full border-gray-600 border-solid" title="Clear filter" @click="filterText='';$refs.input.focus();"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        </div>
        <section class="flex justify-center flex-wrap md:grid md:grid-cols-2 md:gap-2 xl:grid-cols-3 xl:gap-4 items-baseline">
            <template v-for="(category,index) in getFaq" :key="index+1">
                <div v-if="filterPole.indexOf(index.toString())>=0 || filterPole.length==0" class="w-11/12 px-2 bg-gradient-to-b from-gray-dark to-black bg-opacity-90 shadow-md border-b-4 border-solid border-green-our mt-4 pb-4">
                    <h2 class="text-green-our mt-4 text-lg font-bold">{{category.category}}</h2>
                    <template v-for="(faq,index1) in category.list" :key="index1+1">
                        <faq-list v-if="filterPole.indexOf(index+'-'+index1)>=0 || filterPole.length==0" :faq="faq"></faq-list>
                    </template>
                </div>
            </template>
        </section>
    </template>
    <template v-else>
        <section>
            <p class="text-center text-xl text-red-our py-10">No FAQ found</p>
        </section>
    </template>
    </div>
</template>

<script>
import Axios from 'axios'
import FaqList from '../components/PagesComponents/FaqList'
export default {
    components:{FaqList},
    created(){
        //-------pozadie stranky-------
        document.getElementsByTagName('body')[0].classList.add('bgImageBlue');

        if(this.getFaq===false){
            if('firstLoad' in window){
                if('faq' in window.firstLoad){
                    this.loadFaqWindow();
                    window.firstLoad={};
                }else{
                   this.loadFaq(); 
                }
            }else{
                this.loadFaq();
            }
        }
        if('search' in this.$route.query){
            this.filterText=this.$route.query.search;
        }
    },
    data(){
        return{
            filterText:'',
            filterPole:[],
            filterPocet:0,
        }
    },
    computed:{
        getFaq(){
           return this.$store.getters.getFaq;
        },
    },
    methods:{
        loadFaq(){
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+"/handler.php?page=faq", {})
            .then((response)=>{
                var res=response.data;
                if(res.return===true){
                    this.$store.commit('setFaq',{'faq':res.faq});
                    this.zobrazFaq(this.filterText);
                }
            })
            .catch((e)=> {
                this.$store.commit('setErrorModal',{visible:true,data:"#88 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        },
        loadFaqWindow(){
            var res=window.firstLoad;
            if(res.return===true){
                this.$store.commit('setFaq',{'faq':res.faq});
                this.zobrazFaq(this.filterText);
            }
        },
        zobrazFaq(hodnota){
            this.filterPocet=0;
            this.filterPole.splice(0,this.filterPole.length);
            if(this.getFaq){
                if(this.getFaq.length>0 && hodnota!==''){
                    this.getFaq.forEach((el,i)=>{
                        if(el.category.toLowerCase().includes(hodnota.toLowerCase())){
                            this.filterPole.push(i.toString());
                            el.list.forEach((el1,i1)=>{
                                this.filterPole.push(i+'-'+i1);
                            });
                        }else{
                            el.list.forEach((el1,i1)=>{
                                if(el1.name.toLowerCase().includes(hodnota.toLowerCase()) || el1.text.toLowerCase().includes(hodnota.toLowerCase())){
                                this.filterPole.push(i+'-'+i1);
                                this.filterPole.push(i.toString());
                                }
                            }); 
                        }
                    });
                }
            }
        }
    },
    watch:{
        filterText(hodnota){
            this.zobrazFaq(hodnota);
        },
    }
}

</script>