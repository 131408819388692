import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import _defineProperty from "E:/jsvue1/captain/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _mixins$created$data$;

import Axios from 'axios';
import functions from '../components/functions';
export default (_mixins$created$data$ = {
  mixins: [functions],
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');
  },
  data: function data() {
    return {
      borderName: 'bg-white',
      borderEmail: 'bg-white',
      borderContent: 'bg-white',
      submit: {
        name: false,
        email: false,
        content: false,
        securityCheck: false
      },
      security: {
        first: 0,
        seccond: 0
      },
      formSent: false
    };
  }
}, _defineProperty(_mixins$created$data$, "created", function created() {
  this.security.first = Math.floor(Math.random() * 5);
  this.security.seccond = Math.floor(Math.random() * 4);
}), _defineProperty(_mixins$created$data$, "computed", {
  getBorderName: function getBorderName() {
    return this.borderName;
  },
  getBorderEmail: function getBorderEmail() {
    return this.borderEmail;
  },
  getBorderContent: function getBorderContent() {
    return this.borderContent;
  }
}), _defineProperty(_mixins$created$data$, "methods", {
  kontrolaName: function kontrolaName() {
    var name = this.$refs.name;

    if (name.value.length < 2) {
      this.borderName = 'bg-red-200';
      this.submit.name = false;
    } else if (name.value.length == 0) {
      this.borderName = 'bg-white';
      this.submit.name = false;
    } else {
      this.borderName = 'bg-white';
      this.submit.name = true;
    }
  },
  kontrolaEmail: function kontrolaEmail() {
    var email = this.$refs.email;

    if (email.value.length == 0) {
      this.borderEmail = 'bg-white';
      this.submit.email = false;
    } else if (!this.validateEmail(email.value)) {
      this.borderEmail = 'bg-red-200';
      this.submit.email = false;
    } else {
      this.borderEmail = 'bg-white';
      this.submit.email = true;
    }
  },
  kontrolaContent: function kontrolaContent() {
    var content = this.$refs.content;

    if (content.value.length < 5) {
      this.borderContent = 'bg-red-200';
      this.submit.content = false;
    }

    if (content.value.length == 0) {
      this.borderContent = 'bg-white';
      this.submit.content = false;
    } else {
      this.borderContent = 'bg-white';
      this.submit.content = true;
    }
  },
  kontrolaSecurity: function kontrolaSecurity() {
    var securityCheck = this.$refs.securityCheck;

    if (parseInt(securityCheck.value) === parseInt(this.security.first) + parseInt(this.security.seccond)) {
      this.submit.securityCheck = true;
    } else {
      this.submit.securityCheck = false;
    }
  },
  posliSpravu: function posliSpravu() {
    var _this = this;

    var email = this.$refs.email;
    var content = this.$refs.content;
    var name = this.$refs.name;
    var udaje = {
      'name': name.value,
      'email': email.value,
      'content': content.value
    };
    this.$store.commit('setModalLoading', true);
    Axios.post(this.$store.getters.URLAJAX + "/handler.php?page=send-mail", udaje).then(function (response) {
      var res = response.data;

      if (res.return === false) {
        _this.$store.commit('setErrorModal', {
          visible: true,
          data: res.returnMsg
        });
      } else if (res.return === true) {
        _this.formSent = true;
        setTimeout(function () {
          _this.formSent = false;
          _this.security.first = Math.floor(Math.random() * 5);
          _this.security.seccond = Math.floor(Math.random() * 4);
          email.value = "";
          content.value = "";
          name.value = "";
        }, 3000);
      }
    }).catch(function (e) {
      _this.$store.commit('setErrorModal', {
        visible: true,
        data: "#90 Network error" + e + ""
      });
    }).finally(function () {
      _this.$store.commit('setModalLoading', false);
    });
  }
}), _mixins$created$data$);