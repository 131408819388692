import "core-js/modules/es.object.to-string.js";
import Axios from 'axios';
import LeaderBoardList from '../components/PagesComponents/LeaderBoardList'; // import gsap from 'gsap'

export default {
  components: {
    LeaderBoardList: LeaderBoardList
  },
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');

    if (this.getLeaderBoard.list.length == 0) {
      if ('firstLoad' in window) {
        if ('leaderBoard' in window.firstLoad) {
          this.loadLeaderBoardTotalScoreWindow();
          window.firstLoad = {};
        } else {
          this.loadLeaderBoardTotalScore();
        }
      } else {
        this.loadLeaderBoardTotalScore();
      }
    }
  },
  computed: {
    getLeaderBoard: function getLeaderBoard() {
      return this.$store.getters.getLeaderBoard('total');
    },
    getUserLogged: function getUserLogged() {
      return this.$store.getters.getUserLogged;
    },
    zobrazScoreUzivatela: function zobrazScoreUzivatela() {
      if (this.getUserLogged && 'nick' in this.getLeaderBoard.user) {
        return true;
      }

      return false;
    }
  },
  methods: {
    LeaderBoardBg: function LeaderBoardBg(idUser) {
      if (this.getUserLogged && 'id' in this.getLeaderBoard.user) {
        if (idUser === this.getLeaderBoard.user.id) {
          return 'via-yellow-900';
        }
      }

      return 'via-black';
    },
    flagLink: function flagLink(flag) {
      return this.$store.getters.URLSITE + '/photos/flags/' + flag + '_s.jpg';
    },
    getWidth: function getWidth(max, actual) {
      return {
        'width': parseInt(actual) / parseInt(max) * 100 + '%'
      };
    },
    loadLeaderBoardTotalScore: function loadLeaderBoardTotalScore() {
      var _this = this;

      this.$store.commit('setModalLoading', true);
      var udaje = {
        'limit': this.getLeaderBoard.list.length
      };
      Axios.post(this.$store.getters.URLAJAX + "/handler.php?page=load-leaderboard-total-score", udaje).then(function (response) {
        var res = response.data;

        if (res.return === false) {
          _this.$store.commit('setErrorModal', {
            visible: true,
            data: res.returnMsg
          });
        } else if (res.return === true) {
          //----------- upravime data v uzivatelovi voteLeft
          _this.$store.commit('setLeaderBoard', {
            'type': 'total',
            'list': res.list,
            'user': res.user,
            'maxScore': parseInt(res.maxScore),
            'resultCount': parseInt(res.resultCount)
          });
        }
      }).catch(function (e) {
        _this.$store.commit('setErrorModal', {
          visible: true,
          data: "#88 Network error" + e + ""
        });
      }).finally(function () {
        _this.$store.commit('setModalLoading', false);
      });
    },
    loadLeaderBoardTotalScoreWindow: function loadLeaderBoardTotalScoreWindow() {
      var res = window.firstLoad.leaderBoard;
      this.$store.commit('setLeaderBoard', {
        'type': 'total',
        'list': res.list,
        'user': res.user,
        'maxScore': parseInt(res.maxScore),
        'resultCount': parseInt(res.resultCount)
      });
    }
  }
};