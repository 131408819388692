<template>
<div class="z-upperModal fixed inset-0 overflow-auto flex justify-center items-center bg-black bg-opacity-80">
    <div class="w-11/12 max-w-md relative p-5 border-solid border-b-4 border-orange-our bg-gray-dark bg-gradient-to-b from-gray-dark  to-black shadow-glowGray">
        <h3 class="text-center mb-5 text-orange-our text-2xl">Credit purchase</h3>
        <span class="button-close-x" title="Close Modal" @click="closeModal()"><img :src="$store.getters.URLPICTURES+'/close.svg'" class="w-1/2 h-1/2 block"></span>
        <div class="flex items-center justify-start mb-5"><img :src="getProductData.photoPath" :alt="getProductData.desc" class="block h-16"><h3 class="pl-5 text-2xl text-green-our">{{getProductData.desc}}</h3></div>
        
        <template  v-if="!purchaseSucessfull">
        <div class="flex justify-start mt-2">
            <label for="firstName" class="w-1/2">First name:</label>
            <input type="text" name="firstName" ref="firstName" :keyup="checkInputValue('firstName')" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1" maxlength="256" value="" required>
        </div>
        <div class="flex justify-start mt-2">
            <label for="lastName" class="w-1/2">Last name:</label>
            <input type="text" name="lastName" ref="lastName" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1" maxlength="256" value="" required>
        </div>
        <div class="flex justify-start mt-2">
            <label for="email" class="w-1/2">Email:</label>
            <input type="text" name="email" ref="email" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1" maxlength="128" value="" required>
        </div>
        <div class="flex justify-start mt-2">
            <label for="phone" class="w-1/2">Phone number:</label>
            <input type="tel" name="phone" ref="phone" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1" maxlength="128" value="" placeholder="+1 234 567 89" required>
        </div>
        <div class="flex justify-start mt-2">
            <label for="city" class="w-1/2">City:</label>
            <input type="text" name="city" ref="city" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1" maxlength="128" value="" required>
        </div>
        <div class="flex justify-start mt-2">
            <label for="street" class="w-1/2">Street:</label>
            <input type="text" name="street" ref="street" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1" maxlength="128" value="" required>
        </div>
        <div class="flex justify-start mt-2">
            <label for="postalCode" class="w-1/2">Postal code:</label>
            <input type="text" name="postalCode" ref="postalCode" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1" maxlength="16" value="" required>
        </div>
        <div class="flex justify-start mt-2">
            <label for="countryCode" class="w-1/2">Country:</label>
            <select name="countryCode" ref="countryCode" class="w-1/2 text-white bg-gray-dark border border-solid border-gray-600 p-1">
            <template v-for="country in getUserData.countryList" :key="country.id">
                <option :value="country.code" :selected="country.id==getUserData.country">{{country.name}}</option>
            </template>
            </select>
        </div>
        <button type="submit" name="purchaseCredit" value="change" class="bg-orange-our text-lg py-2 px-5 block mx-auto mt-2" @click.prevent="submitPurchase()">Purchase {{getProductData.desc}}</button>
        </template>
        <template v-else>
            <div class="text-center mb-5 text-green-our">
                Nakup bol uspesny, mate {{getUserData.actualCredits}} kreditov
            </div>
        </template>
        <template v-if="purchaseError.length>0">
            <div class="text-center mb-5 text-red-our">{{purchaseError}}</div>
        </template>
        <!-- <div v-if="showSuccessfullPurchaseHodnota" class="text-center mb-5 text-green-our">
            Nakup bol uspesny mate dalsich {{getBattle.generalSettings.extraSelectPurchase}} select left
        </div>
        <div v-else>
            <button v-if="showUnlock" class="bg-orange-our text-lg py-2 px-5 block mx-auto" @click="unlock()">UNLOCK</button>
            <button v-else-if="!showSuccessfullPurchaseHodnota" class="bg-orange-our text-lg py-2 px-5 block mx-auto"  @click="purchaseSelectSlot()">YES USE {{getBattle.generalSettings.extraSelectPurchasePrice}} CREDITS NOW</button>
        </div> -->
    </div>

</div>
</template>

<script>
import functions from '../functions.vue'
import Axios from 'axios';
export default {
    mixins:[functions],
    data(){
        return {
            purchaseSucessfull:false,
            purchaseError:""
        }
    },
    computed:{
        getProductData(){
            return this.$store.getters.getUpperModal.data.product;
        },
        getUserData(){
            return this.$store.getters.getUserData;
        }
    },
    methods:{
        checkInputValue(input){
            console.log(input);
            console.log(this.$refs);
            // if(input in this.$refs){
            //     console.log(this.$refs[input]);
            //     if(this.$refs[input].value==""){
            //         this.$refs[input].classList.add("bg-red-50");
            //     }else{
            //         this.$refs[input].classList.remove("bg-red-50");
            //     }
            // }
        },
        closeModal(){
            this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
        },
        getCheckoutPurchaseId(checkoutResult){
            let splitPole=checkoutResult.split("=");
            return splitPole[splitPole.length-1];
        },
        submitPurchase(){
            var config={
                timeout: 1000*20,
                headers:{
                'Content-type': 'application/json',
                'Accept': 'application/json',
                }
            };
            console.log(this.$refs.countryCode);
            var udaje={'productId':parseInt(this.getProductData.id),
                'firstName':this.$refs.firstName.value,
                'lastName':this.$refs.lastName.value,
                'email':this.$refs.email.value,
                'phone':this.$refs.phone.value,
                'city':this.$refs.city.value,
                'street':this.$refs.street.value,
                'postalCode':this.$refs.postalCode.value,
                'countryCode':this.$refs.countryCode.value,
            };
            this.$store.commit('setModalLoading',true);
            Axios.post(this.$store.getters.URLAJAX+"/paymentCreate.php", udaje,config)
            .then((response)=>{
                var res=response.data;
                if('code' in res && res.code==1){//-----------odlogovany uzivatel
                    this.$store.commit('deleteLoadedData');
                    this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                    this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
                    this.$router.push({path:'/active-contests'});
                }else{
                    if(res.return===false){
                        this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
                    }else if(res.return===true){
                        let noveThis=this;
                        _gopay.checkout({gatewayUrl: res.responseUrl, inline: true}, (checkoutResult) => {
                            // console.log('nakup');
                            // console.log('res',res);
                            checkoutResult.id=this.getCheckoutPurchaseId(checkoutResult.url);
                            // console.log('checkoutResult',checkoutResult);
                            Axios.post(this.$store.getters.URLAJAX+'/paymentStatus.php',{'id':checkoutResult.id},config)
                            .then((response) => {
                                if(response.data.return){
                                    if('actualCredits' in response.data && 'creditList' in response.data && 'creditListTotal' in response.data){
                                        noveThis.$store.commit('deleteUserCreditList');
                                        noveThis.$store.commit('setUserData',{
                                            'actualCredits':parseInt(response.data.actualCredits),
                                            'creditList':response.data.creditList,
                                            'creditListTotal':parseInt(response.data.creditListTotal)
                                        });
                                    }
                                    noveThis.purchaseSucessfull=true;
                                }else{
                                    noveThis.purchaseSucessfull=false;
                                    noveThis.purchaseError=response.data.returnMsg;
                                }
                            })
                            .catch((e) => {
                                noveThis.$store.commit('setErrorModal',{visible:true,data:"#147 Network error"+e+""});
                            })
                            .finally(()=>{
                                noveThis.$store.commit('setModalLoading',false);
                            });


                            // console.log(checkoutResult);
                        });
                        // this.$store.commit('setBuyExtraSelect',{'battleId':this.getBattleId,'data':{'selectLeft':res.selectLeft,'selectLeftBeforeSubmit':res.selectLeftBeforeSubmit,'extraSelect':res.extraSelect}});
                        // this.showSuccessfullPurchase=true;
                    }
                }
                //--------zapiseme aktualne kredity
                // if('actualCredits' in res){
                //     this.$store.commit('setUserData',{'actualCredits':parseInt(res.actualCredits)});
                // }
            })
            .catch((e)=>{
                this.$store.commit('setErrorModal',{visible:true,data:"#143 Network error"+e+""});
            })
            .finally(()=>{
                this.$store.commit('setModalLoading',false);
            });
        }
    },
}
</script>

<style>

</style>