<template>
    <div class="fixed bottom-0 left-0 right-0">
        <div class="bg-black bg-opacity-80 flex justify-around border-t border-solid border-gray-800">  
          <div class="font-xs uppercase py-2 self-center cursor-pointer select-none p-2 w-1/4 text-center" @click="menuRouter('home')"><img :src="$store.getters.URLSITE+'/pictures/menu/home.svg'" alt="" class="w-10 block mx-auto"><span style="font-size:11px;">HOME</span></div>
          <div class="font-xs uppercase py-2 self-center cursor-pointer select-none p-2 w-1/4 text-center" @click="menuRouter('leaderboard')"><img :src="$store.getters.URLSITE+'/pictures/menu/leaderboards.svg'" alt="" class="w-10 block mx-auto"><span style="font-size:11px;">LEADERBOARDS</span></div>
          <div class="font-xs uppercase py-2 self-center cursor-pointer select-none p-2 w-1/4 text-center" @click="menuRouter('contests')"><img :src="$store.getters.URLSITE+'/pictures/menu/contests.svg'" alt="" class="w-10 block mx-auto"><span style="font-size:11px;">CONTESTS</span></div>
          <template v-if="getUserLogged">
            <div class="font-xs uppercase py-2 self-center cursor-pointer select-none p-2 w-1/4 text-center" @click="menuRouter('user')"><img :src="getUserData.avatarPhotoPath" alt="" class="w-10 block mx-auto rounded-full"><span style="font-size:11px;">{{userNick}}</span></div>
          </template>
          <template v-else>
            <div class="font-xs uppercase py-2 self-center cursor-pointer select-none p-2 w-1/4 text-center" @click="openLogin()"><img :src="$store.getters.URLSITE+'/pictures/menu/user.svg'" alt="" class="w-10 block mx-auto rounded-full"><span style="font-size:11px;">USER</span></div>
          </template>
        </div>
    </div>
</template>

<script>
export default{
  computed:{
    getMainMenu(){
      return this.$store.getters.getMainMenu;
    },
    getUserLogged(){
      return this.$store.getters.getUserLogged;
    },
    userNick(){
      if(this.getUserData.nick.length>15){
        return this.getUserData.nick.substring(0, 12)+"...";
      }
      return this.getUserData.nick;
    },
    // getMenuList(){
    //   return this.menuList[this.getMainMenu.actualMenu];
    // },
    getUserData(){
      return this.$store.getters.getUserData;
    }
  },
  methods:{
    openLogin(){
      this.$store.commit('setUpperModal',{visible:true,whatToShow:'LoginPanel',data:{}});
    },
    menuRouter(kde){
      if(this.getMainMenu.actualMenu==kde){
        kde='';
      }
      this.$store.commit('setMainMenu',{'actualMenu':kde});
    },
  }
}
</script>
