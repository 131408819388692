<template>
    <div class="rounded-sm flex justify-start items-stretch py-1 pr-1 max-w-2xl my-1 mx-auto shadow-xl bg-gradient-to-tr from-gray-dark to-gray-dark leaderBoard" :class="background">
        <div class="text-xl text-center w-14 self-center">#{{LeaderBoard.position}}<img v-if="LeaderBoard.countryCode!==''" :src="flagLink(LeaderBoard.countryCode)" :alt="LeaderBoard.countryName" class="block h-5 w-auto mx-auto"></div>
        <div class="w-14 cursor-pointer self-center" @click="linkToUser(LeaderBoard.userUrl)"><img :src="LeaderBoard.photoPath" class="w-14 h-14 object-cover rounded-full"></div>
        <div class="px-2 font-bold nameAchieve cursor-pointer self-center" @click="linkToUser(LeaderBoard.userUrl)">
            <div class="name overflow-ellipsis overflow-hidden">{{LeaderBoard.nick}}</div>
        </div>
        <div class="flex flex-col graph1 justify-center text-xs w-12 mr-1">
            <div class="border border-solid border-orange-our pl-1 text-orange-our">TOTAL</div>
            <div class="border border-green-our border-green-our border-solid pl-1 my-0.5 text-green-our">PHOTO</div>
            <div class="border border-blue-our border-blue-our border-solid pl-1" style="color: #4a8ff5;">VOTE</div>
        </div>
        <div class="flex flex-col flex-grow graph2 justify-between text-sm">
			<div class="border-black border-solid text-right leading-none total" ref="total">{{LeaderBoard.scoreTotal}}</div>
			<div class="border-black border-solid text-right leading-none photo my-1.5" ref="photo">{{LeaderBoard.scorePhoto}}</div>
			<div class="border-black border-solid text-right leading-none vote" ref="vote">{{LeaderBoard.scoreVote}}</div>
		</div>
        <div class="score f24 text-center ml-auto w-16 font-bold" :class="fontScore">{{LeaderBoard.scoreTotal}}<span class="block text-xs font-normal">POINTS</span></div>
    </div>

</template>
<script>
import gsap from 'gsap'
export default {
    props:['LeaderBoard','background','category'],
    mounted(){
        gsap.fromTo(this.$refs.total, 1, {width:"0%"}, {width:this.getWidthNumber(this.getLeaderBoard.maxScore,this.LeaderBoard.scoreTotal)})
        gsap.fromTo(this.$refs.photo, 1, {width:"0%"}, {width:this.getWidthNumber(this.getLeaderBoard.maxScore,this.LeaderBoard.scorePhoto)})
        gsap.fromTo(this.$refs.vote, 1, {width:"0%"}, {width:this.getWidthNumber(this.getLeaderBoard.maxScore,this.LeaderBoard.scoreVote)})
    },
    computed:{
        getLeaderBoard(){
           return this.$store.getters.getLeaderBoard(this.category);
        },
        fontScore(){
            if(this.category=="total"){
                return {'text-orange-our':true};
            }else if(this.category=="vote"){
                return {'text-blue-our':true};
            }else if(this.category=="photo"){
                return {'text-green-our':true};
            }
            return {'text-white':true};
        }
    },
    methods:{
        flagLink(flag){
            return this.$store.getters.URLSITE+'/photos/flags/'+flag+'_s.jpg'
        },
        linkToUser(userUrl){
                this.$router.push({path:'/user-profile/'+userUrl});
                this.$store.commit('setUpperModal',{visible:false,whatToShow:'',data:{}});
                this.$store.commit('setLowerModal',{visible:false,whatToShow:'',data:{}});
        },
        getWidthNumber(max,actual){
            return ((parseInt(actual)/parseInt(max))*100)+'%';
        },
    }
}
</script>