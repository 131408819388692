// import ActiveInBattles from './components/ActiveInBattles.vue';
// import AvailableBattles from './components/AvailableBattles.vue';
// import FinishedBattles from './components/FinishedBattles.vue';
// import AchievementsAll from './components/AchievementsAll.vue';
import ModalLoading from './components/ModalLoading.vue';
import UpperModal from './components/UpperModal.vue';
import LowerModal from './components/LowerModal.vue';
import MainMenu from './pages/mainMenu.vue';
import MainMenuBottom from './pages/mainMenuBottom.vue';
import ErrorModal from './components/ErrorModal.vue';
import functions from './components/functions';
export default {
  created: function created() {
    var _this = this;

    this.mainBattlestimerInterval = setInterval(function () {
      return _this.$store.dispatch('setTimeOnBattles');
    }, 1000);

    if (this.getBattleList.length == 0) {
      if (window.firstLoad) {
        this.firstLoad();
      } else {
        this.firstLoadAxios();
      }
    }
  },
  mixins: [functions],
  components: {
    ModalLoading: ModalLoading,
    UpperModal: UpperModal,
    LowerModal: LowerModal,
    MainMenu: MainMenu,
    ErrorModal: ErrorModal,
    MainMenuBottom: MainMenuBottom
  },
  data: function data() {
    return {
      mainBattlestimerInterval: ''
    };
  },
  computed: {
    // getFirstEverLoad(){
    //   return this.$store.getters.getFirstEverLoad;
    // },
    getPrepinacPanelov: function getPrepinacPanelov() {
      return this.$store.getters.getPrepinacPanelov;
    },
    getBattleList: function getBattleList() {
      return this.$store.getters.getBattleList;
    }
  },
  methods: {
    // testDelete(){
    //   this.$store.commit('deleteLoadedData');
    // },
    // list(){
    //   console.log(this.$store);
    // },
    // router(){
    //   console.log(this.$route);
    // },
    openLogin: function openLogin() {
      this.$store.commit('setUpperModal', {
        visible: true,
        whatToShow: 'LoginPanel',
        data: {}
      });
    }
  }
};