<template>
<select-photo v-if="getLowerModal.whatToShow=='SelectPhoto'" :battleId="getLowerModal.battleId"></select-photo>
<vote-photo v-if="getLowerModal.whatToShow=='VotePhoto'" :battleId="getLowerModal.battleId"></vote-photo>
<top-100-photos v-if="getLowerModal.whatToShow=='Top100Photos'" :battleSeo="getLowerModal.battleSeo"></top-100-photos>
<user-best-photos v-if="getLowerModal.whatToShow=='UserBestPhotos'" :userUrl="getLowerModal.data.userUrl"></user-best-photos>
<score-board v-if="getLowerModal.whatToShow=='ScoreBoard'" :battleSeo="getLowerModal.data.battleSeo"></score-board>
 <upload-image v-if="getLowerModal.whatToShow=='UploadImage'"></upload-image>
 <game-guess-winner v-if="getLowerModal.whatToShow=='GameGuessWinner'"></game-guess-winner>
 <game-guess-brand v-if="getLowerModal.whatToShow=='GameGuessBrand'"></game-guess-brand>
</template>

<script>
    import SelectPhoto from './LowerModal/SelectPhoto'
    import Top100Photos from './LowerModal/Top100Photos.vue';
    import UserBestPhotos from './LowerModal/UserBestPhotos.vue';
    import ScoreBoard from './LowerModal/ScoreBoard.vue';
    import VotePhoto from './LowerModal/VotePhoto'
    import UploadImage from './LowerModal/UploadImage'
    import GameGuessWinner from './LowerModal/GameGuessWinner.vue'
    import GameGuessBrand from './LowerModal/GameGuessBrand.vue'
export default {
    created(){
        console.log('getLowerModal',this.getLowerModal);
    },
    components:{SelectPhoto,VotePhoto,Top100Photos,UploadImage, ScoreBoard,UserBestPhotos,GameGuessWinner,GameGuessBrand},
    computed:{
        getLowerModal(){
            return this.$store.getters.getLowerModal;
        },
    }
}
</script>
