import ZoomInImage from './UpperModal/ZoomInImage';
import HowToPlay from './UpperModal/HowToPlay'; // import SelectZoomInImage from './UpperModal/SelectZoomInImage'

import ReportPhoto from './UpperModal/ReportPhoto';
import SelectNoSelectLeft from './UpperModal/SelectNoSelectLeft';
import SelectExitBeforeSubmit from './UpperModal/SelectExitBeforeSubmit.vue';
import SelectLetsStart from './UpperModal/SelectLetsStart.vue';
import SelectAllSeenPhotos from './UpperModal/SelectAllSeenPhotos.vue';
import SelectSubmitOk from './UpperModal/SelectSubmitOk.vue';
import BuyExtraSelectSlots from './UpperModal/BuyExtraSelectSlots.vue'; // import BuyRewatchPhotosAgain from './UpperModal/BuyRewatchPhotosAgain.vue'

import GoodJobTimeForVote from './UpperModal/GoodJobTimeForVote.vue'; // import VoteZoomInImage from './UpperModal/VoteZoomInImage'

import ResetVotes from './UpperModal/ResetVotes';
import ResetOneVote from './UpperModal/ResetOneVote';
import VoteExitBeforeSubmit from './UpperModal/VoteExitBeforeSubmit';
import VoteNoPhoto from './UpperModal/VoteNoPhoto';
import ZoomInAchieve from './UpperModal/ZoomInAchieve'; // import ZoomInImageFinishedBattle from './UpperModal/ZoomInImageFinishedBattle'

import LoginPanel from './UpperModal/LoginScreen/LoginPanel.vue'; // import SignupPanel from './UpperModal/LoginScreen/SignupPanel.vue'

import SignupPanelConfirm from './UpperModal/LoginScreen/SignupPanelConfirm.vue';
import ForgotenPassword from './UpperModal/LoginScreen/ForgotenPassword.vue';
import ForgotenPasswordEnterNew from './UpperModal/LoginScreen/ForgotenPasswordEnterNew.vue';
import GeneralRules from './UpperModal/GeneralRules.vue';
import BattleRules from './UpperModal/BattleRules.vue';
import GoodJobAllDone from './UpperModal/GoodJobAllDone.vue';
import BuyCredit from './UpperModal/BuyCredit.vue';
import PaymentStatus from './UpperModal/PaymentStatus.vue';
import FirstEverLoadExclusiveContests from './UpperModal/FirstEverLoad/ExclusiveContests.vue';
import FirstEverLoadUpcomingContests from './UpperModal/FirstEverLoad/UpcomingContests.vue';
import FirstEverLoadActiveContests from './UpperModal/FirstEverLoad/ActiveContests.vue';
import FirstEverLoadFinishedContests from './UpperModal/FirstEverLoad/FinishedContests.vue';
import FirstEverLoadDailyGames from './UpperModal/FirstEverLoad/DailyGames.vue';
export default {
  components: {
    ZoomInImage: ZoomInImage,
    ReportPhoto: ReportPhoto,
    SelectNoSelectLeft: SelectNoSelectLeft,
    SelectExitBeforeSubmit: SelectExitBeforeSubmit,
    SelectLetsStart: SelectLetsStart,
    SelectSubmitOk: SelectSubmitOk,
    BuyExtraSelectSlots: BuyExtraSelectSlots,
    GoodJobTimeForVote: GoodJobTimeForVote,
    ResetVotes: ResetVotes,
    VoteExitBeforeSubmit: VoteExitBeforeSubmit,
    VoteNoPhoto: VoteNoPhoto,
    ZoomInAchieve: ZoomInAchieve,
    LoginPanel: LoginPanel,
    ForgotenPassword: ForgotenPassword,
    GeneralRules: GeneralRules,
    BattleRules: BattleRules,
    ResetOneVote: ResetOneVote,
    GoodJobAllDone: GoodJobAllDone,
    SelectAllSeenPhotos: SelectAllSeenPhotos,
    ForgotenPasswordEnterNew: ForgotenPasswordEnterNew,
    SignupPanelConfirm: SignupPanelConfirm,
    BuyCredit: BuyCredit,
    PaymentStatus: PaymentStatus,
    FirstEverLoadExclusiveContests: FirstEverLoadExclusiveContests,
    FirstEverLoadUpcomingContests: FirstEverLoadUpcomingContests,
    FirstEverLoadActiveContests: FirstEverLoadActiveContests,
    FirstEverLoadFinishedContests: FirstEverLoadFinishedContests,
    FirstEverLoadDailyGames: FirstEverLoadDailyGames,
    HowToPlay: HowToPlay
  },
  computed: {
    getUpperModal: function getUpperModal() {
      return this.$store.getters.getUpperModal;
    }
  }
};