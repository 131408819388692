<template>
        <div v-if="getModalLoading" class="fixed inset-0 z-modalLoading flex justify-center items-center bg-black bg-opacity-90">
                <div class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 border-top-color-red animate-spin border-opacity-20 border-solid"></div>
        </div>
</template>

<script>
export default {
        computed:{
        getModalLoading(){
                return this.$store.getters.getModalLoading.visible;
                }
        }
}
</script>

