<template>
    <div v-if="file.show" class="relative pt-1 my-1 bg-gray-dark">
        <div class="flex mb-1 items-center justify-between">
            <div><span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-sm text-white" :class="stav.stavClass">{{stav.stavText}}</span> 
            <span v-if="file.process=='ERROR'" class="text-xs text-red-our">{{file.text}}</span>
            <span v-else class="text-xs">{{fileName}}</span>
            </div>

            <div class="text-right"><span class="text-xs font-semibold inline-block text-blueGray-600">{{stav.percentage}}%</span></div>
        </div>
        <div class="overflow-hidden h-1 mb-1 text-xs flex rounded-sm bg-gray-600" :style="{'width':(stav.percentage==0?1:stav.percentage)+'%'}"></div>
    </div>
</template>
<script>
export default {
    mounted(){
        console.log('uploadFileList',this.file);
        // var thisFile=this.file;
        // setTimeout(()=>{
        //     this.uploadFileList.splice(thisFile.id,1);
        // },3000);
    },
    props:['file'],
    inject:['uploadFileList'],
    computed:{
        fileName(){
            if(this.file.fileName.length>40){
                return `${this.file.fileName.substr(0, 10)}...${this.file.fileName.substr(-10, 10)}`;
            }
            return this.file.fileName;
        },
        // stav(){

        // }
        // backgroundImg(){
        //     if(this.file.stav){
        //         return {'background-image':`url(${this.file.url})`}
        //     }else{
        //         return {'background-image':'url(noImage.svg)'}
        //     }
        // },
        stav(){
            if(this.file.stav){
                if(this.file.process=='Validating'){
                    return {'stavClass':{'bg-orange-our':true},'stavText':this.file.process,'percentage':0}
                }else if(this.file.process=='Converting'){
                    return {'stavClass':{'bg-orange-our':true},'stavText':this.file.process,'percentage':0}
                }else if(this.file.process=='Loading'){
                    return {'stavClass':{'bg-orange-our':true},'stavText':this.file.process,'percentage':this.file.uploadProcess}
                }else if(this.file.process=='ERROR'){
                    return {'stavClass':{'bg-red-our':true},'stavText':this.file.process,'percentage':0}
                }else if(this.file.process=='OK'){
                    return {'stavClass':{'bg-green-our':true},'stavText':this.file.process,'percentage':100}
                }else{
                    return {'stavClass':{'bg-orange-our':true},'stavText':this.file.process,'percentage':0}
                }
            }else{
                return {'stavClass':{'bg-red-our':true},'stavText':this.file.process,'percentage':this.file.uploadProcess}
            }
        }
    }
}

                // show:true,
                // stav:false,
                // text:"",
                // url:"",
                // process:"Converting",
                // uploadProcess:0


</script>